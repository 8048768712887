import React from 'react'
import PropTypes from 'prop-types'

class Link extends React.Component {

  onClick(e) {
    e.preventDefault()

    if (this.props.onClick) {
      this.props.onClick(e)
    } else if (this.props.href[0] === '/') {
      window.app.history.push(this.props.href)
    } else if (/javascript:void/.test(this.props.href)) {
      // noop
    } else {
      window.location.pathname = this.props.href
    }
  }

  render(){
    return (
      <a
        {...this.props}
        onClick={e => this.onClick(e)}
      >{this.props.children}</a>
    )
  }
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Link