import React from 'react';
import { Form, Field } from 'react-final-form'
import {
  Button,
  Fab,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import ValidationService from '../../services/ValidationService';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import * as _ from 'lodash'
import ContractorType from './ContractorType';
import appStore from '../../AppStore';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import STATES from '../../States.const';
import FieldsetRadioGroup from '../RadioGroup/FieldsetRadioGroup';
import moment from 'moment'

const statesArray = _(STATES) //wrap object so that you can chain lodash methods
  .mapValues((name, abbrev) => _.merge({}, {name}, {abbrev})) //attach id to object
  .values() //get the values of the result
  .value() //unwrap array of objects

let formObj

function ContractorProfile (props) {

  const [isSubcontractorsAssetsBoxVisible, setIsSubcontractorsAssetsBoxVisible] = React.useState(false);
  const renderSubcontractorTypeTitles = () => {
    let idsToCheck = [
      (formObj.getFieldState('bidOnContractType') && formObj.getFieldState('bidOnContractType').value) || '']
    let subs = idsToCheck.map((id) => {
      let subcontractor = _.find(appStore.getState().ungroupedSubcontractors, {id})
      return _.get(subcontractor, 'title', null)
    })
      .filter((r) => r) // remove nulls
      .join(', ')
      .trim(' ')

    let length = 40
    return <>{_.truncate(subs, {length})
      ? <span title={subs}>
          {_.truncate(subs, {length})}{' '}
        {idsToCheck.length > 1
          ? <a href="#more" onClick={(e) => {
            e.preventDefault()
            setIsSubcontractorsAssetsBoxVisible(true)
          }}>
            View All
          </a>
          : null
        }
        </span>
      : null} </>
  }

  const validate = (values) => {
    let errors = {}
    if (values.hasGlInsurance === 'yes') {
      if (!moment(values.glExp).isValid())
        errors.glExp = 'Required'
      else if (moment(values.glExp).diff(moment.now(), 'days') < 0) {
        errors.glExp = 'Expired'
      }
    }
    return errors
  }

  return (
    <div className="ContractorProfile content container-fluid">
      <Row>
        <Col lg={7} md={7} sm={12} className={`m-auto`}>
          <Card>
            <CardHeader className={`border-bottom mb-3`}>
              <CardTitle tag="h2">{props.formTitle}</CardTitle>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={props.onSubmit.bind(this)}
                initialValues={props.data}
                validate={validate}
                render={({form, handleSubmit}) => {
                  formObj = form
                  return (
                    <form id="professional-profile-form" onSubmit={handleSubmit} className={`container`}>
                      <Row>
                        <div className="col-sm-8">
                          <Field
                            name="companyName"
                            validate={ValidationService.instance().required}
                          >
                            {({input, meta}) => (
                              <>
                                <TextField
                                  {...input}
                                  label={_.startCase(input.name)}
                                  variant={'outlined'}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth={true}
                                  error={meta.error && meta.touched}
                                  helperText={meta.error && meta.touched && meta.error}
                                  onChange={(e) => (props.onChange({companyName: e.target.value}) && input.onChange(e))}
                                />
                              </>
                            )}
                          </Field>
                        </div>

                        <div className="col-sm-4">
                          <Field
                            name="position"
                            validate={ValidationService.instance().required}
                          >
                            {({input, meta}) => (
                              <>
                                <TextField
                                  {...input}
                                  label={_.startCase(input.name)}
                                  variant={'outlined'}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth={true}
                                  error={meta.error && meta.touched}
                                  helperText={meta.error && meta.touched && meta.error}
                                  onChange={(e) => (props.onChange({position: e.target.value}) && input.onChange(e))}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </Row>



                      {['gc', 'subcontractor', 'architect'].includes(props.data.userType)
                        ? <>
                          {props.data.userType === 'subcontractor' ?
                          <Row>
                            <Col>
                              <Field
                                name="bidOnContractType"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <fieldset className={classNames('custom-fieldset fab-add-btn-container',
                                      {'border-danger': meta.error && meta.touched})}>
                                      <legend>Contractor Type</legend>
                                      <Fab color={'secondary'} className={`fab-add-btn`}
                                           onClick={() => setIsSubcontractorsAssetsBoxVisible(true)}
                                      ><span className="fa fa-plus"></span></Fab>
                                      {isSubcontractorsAssetsBoxVisible
                                        ? <ContractorType
                                          onClose={() => {
                                            setIsSubcontractorsAssetsBoxVisible(false)
                                          }}
                                          value={[
                                            (formObj.getFieldState('bidOnContractType') &&
                                              formObj.getFieldState('bidOnContractType').value) || '']}
                                          onChange={(selectedContractorTypes) => (props.onChange(
                                            {bidOnContractType: _.get(selectedContractorTypes, '0', '')})
                                            &&
                                            formObj.change('bidOnContractType',
                                              _.get(selectedContractorTypes, '0', '')))}
                                        />
                                        : null}
                                      {renderSubcontractorTypeTitles()}
                                    </fieldset>
                                    {meta.error && meta.touched
                                      ? <FormHelperText error={true}
                                                        className={`m-0 p-2`}>{meta.error}</FormHelperText>
                                      : null}
                                  </>
                                )}
                              </Field>
                            </Col>
                          </Row> : null}

                          <Row>
                            <Col className={`mt-3`}>
                              <Field
                                name="serviceDistance"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <FormControl variant={`outlined`} fullWidth={true}>
                                    <InputLabel htmlFor={`outlined-service-distance`}>
                                      How far are you willing to travel for work?
                                    </InputLabel>
                                    <Select
                                      {...input}
                                      label={_.startCase(input.name)}
                                      variant={'outlined'}
                                      error={meta.error && meta.touched}
                                      input={<OutlinedInput labelWidth={300} id={`outlined-service-distance`}/>}
                                      onChange={(e) => (props.onChange({serviceDistance: e.target.value}) &&
                                        input.onChange(e))}
                                    >
                                      <MenuItem value="">--Select a distance--</MenuItem>
                                      <MenuItem value={10}>10 miles</MenuItem>
                                      <MenuItem value={30}>30 miles</MenuItem>
                                      <MenuItem value={50}>50 miles</MenuItem>
                                      <MenuItem value={75}>75 miles</MenuItem>
                                      <MenuItem value={100}>100 miles</MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              </Field>
                            </Col>
                          </Row>

                          <Row>
                            <div className="col-sm-7">
                              <Field
                                name="companyLicenseNumber"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      label="Company License Number (if you don't have one, then type: I don't have one)"
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                      onChange={(e) => (props.onChange({companyLicenseNumber: e.target.value}) &&
                                        input.onChange(e))}
                                    />
                                  </>
                                )}
                              </Field>
                            </div>

                            <div className="col-sm-2">
                              <Field
                                name="companyLicenseState"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <FormControl variant={`outlined`} fullWidth={true}>
                                      <InputLabel htmlFor={`outlined-service-distance`}>
                                        State
                                      </InputLabel>
                                      <Select
                                        {...input}
                                        label={_.startCase(input.name)}
                                        variant={'outlined'}
                                        error={meta.error && meta.touched}
                                        input={<OutlinedInput labelWidth={300} id={`outlined-service-distance`}/>}
                                        onChange={(e) => (props.onChange({companyLicenseState: e.target.value}) &&
                                          input.onChange(e))}
                                      >
                                        <MenuItem value={false}>--Select a state--</MenuItem>
                                        {statesArray.map(({abbrev, name}, k) => (
                                          <MenuItem key={k} value={abbrev}>{name}</MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </>
                                )}
                              </Field>
                            </div>
                          </Row>

                          <Row>
                            <div className="col-sm-3">
                              <FieldsetRadioGroup
                                title={'Do you have General Liability:'}
                                field={'hasGlInsurance'}
                                options={[
                                  {value: 'yes', label: 'Yes'},
                                  {value: 'no', label: 'No'},
                                ]}
                                value={props.data.hasGlInsurance || ''}
                                onChange={(fieldData) => props.onChange(fieldData)}
                                validate={ValidationService.instance().required}
                              />
                            </div>

                            {props.data.hasGlInsurance === 'yes'
                              ? <div className="col-sm-3">
                                <div className={`pt-2`}>
                                  <Field
                                    name="glExp"
                                  >
                                    {({input, meta}) => (
                                      <>
                                        <TextField
                                          {...input}
                                          label={`GL Lic. Exp. Date`}
                                          variant={'outlined'}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            type: 'date',
                                          }}
                                          fullWidth={true}
                                          error={meta.error && meta.touched}
                                          helperText={meta.error && meta.touched && meta.error}
                                          onChange={(e) => (props.onChange({glExp: e.target.value}) && input.onChange(e))}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              : null}
                          </Row>

                          <Row>
                            <div className="col-sm-12 col-md-6">
                              <FieldsetRadioGroup
                                title={'Do you have Workers Comp Insurance:'}
                                field={'hasWorkersComp'}
                                options={[
                                  {value: 'yes', label: 'Yes'},
                                  {value: 'no', label: 'No'},
                                  {value: 'exempt', label: 'Exempt'},
                                ]}
                                value={props.data.hasWorkersComp || ''}
                                onChange={(fieldData) => props.onChange(fieldData)}
                                validate={ValidationService.instance().required}
                              />
                            </div>
                          </Row>

                          <Row>
                            <div className="col-sm-6">
                              <Field
                                name="totalFullTimeEmployees"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      type="number"
                                      label={_.startCase(input.name)}
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                      onChange={(e) => (props.onChange({totalFullTimeEmployees: e.target.value}) &&
                                        input.onChange(e))}
                                    />
                                  </>
                                )}
                              </Field>
                            </div>

                            <div className="col-sm-6">
                              <Field
                                name="totalConcurrentMonthlyJobs"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      type="number"
                                      label={_.startCase(input.name)}
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                      onChange={(e) => (props.onChange({totalConcurrentMonthlyJobs: e.target.value}) &&
                                        input.onChange(e))}
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                          </Row>
                        </>
                        : null}

                      <button type="submit" hidden></button>
                    </form>
                  )
                }}
              />
            </CardBody>

            <CardFooter className={'border-top text-right'}>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => document.getElementById('professional-profile-form')
                  .dispatchEvent(new Event('submit', {cancelable: true}))}
              >
                {props.submitLabel}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

ContractorProfile.defaultProps = {
  formTitle  : 'Subcontractor Profile',
  submitLabel: 'Submit',
}

ContractorProfile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  data    : PropTypes.object.isRequired,
};

export default ContractorProfile;