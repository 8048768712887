import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { PeopleService } from '../../services/PeopleService';
import { AdminService } from '../../services/AdminService';
import Link from '../../components/Link';

class AdminUserProjectsContainer extends Component {
  constructor (props) {
    super(props);
    this.peopleService = new PeopleService()
    this.adminService = new AdminService()
    this.state = {
      projects: null
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.data.id && this.state.projects === null)
      this.getData()
  }

  async getData() {
    const projectsResp = await this.adminService.getProjectsByUser(this.props.data.id, {filter: null})
    this.setState({projects: projectsResp.data})
  }

  render () {
    return (
      <div className="AdminUserProjectsContainer content container-fluid">
        <Row>
          <Col lg={7} md={7} sm={12} className={`m-auto`}>
            <Card>
              <CardHeader className={`border-bottom mb-3`}>
                <CardTitle tag="h2">Projects</CardTitle>
              </CardHeader>
              <CardBody className="pt-0">
                <table className="table">
                  <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Project Address</th>
                  </tr>
                  </thead>
                  <tbody>
                  {Array.isArray(this.state.projects) && this.state.projects.length ? this.state.projects.map((project, k) => {
                    return (
                      <tr key={k}>
                        <td><Link href={`/admin/projects/${project.ownerId}/${project.id}`}>{project.name}</Link></td>
                        <td>{project.address}</td>
                      </tr>
                    )
                  }): <tr><td colSpan="3">N/A</td></tr>}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

AdminUserProjectsContainer.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AdminUserProjectsContainer;