import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Row } from 'reactstrap';
import { Button, Fab, TextField } from '@material-ui/core';
import FieldsetRadioGroup from '../RadioGroup/FieldsetRadioGroup';
import { Form, Field } from 'react-final-form'
import ValidationService from '../../services/ValidationService';
import Assets from './Assets';
import appStore from '../../AppStore';
import * as _ from 'lodash'
import moment from 'moment'
import NumberFormatCustom from '../NumberFormatCustom';
import LocationSearchInput from '../LocationSearchInput';
import { StorageService } from '../../services/StorageService';
import ProjectStats from './ProjectStats';
import FileSystem from '../FileSystem/FileSystem';
import { PeopleService } from '../../services/PeopleService';
import { FILE } from '../FileSystem/utils/constants';
import environment from '../../environments/environment';
import styled from 'styled-components';
import { ProjectsService } from '../../services/ProjectsService';
import { NotificationService } from '../../services/NotificationService';
import ProjectMatches from './ProjectMatches';

const StyledFieldSet = styled.fieldset`
  min-height: 220px;
`

const IsDIYField = styled(FieldsetRadioGroup)`
 && {
  margin-top: -10px;
  
  legend {
    margin-bottom: 0;
  } 
 }
`

let _form

class ProjectForm extends Component {

  constructor (props) {
    super(props)
    this.state = {
      isUploading: false,
      isSubcontractorsAssetsBoxVisible: false,
      selectedSubcontractors          : [],
      acceptedFiles                   : [],
      changedFields: {}, // these are the fields that will be submitted
    }

    this.storageService = new StorageService()
    this.peopleService = new PeopleService()
    this.projectsService = new ProjectsService()
    this.notificationService = new NotificationService()
    this.projectFormRef = null
  }

  componentWillUnmount () {
    this.projectFormRef = null
  }

  triggerFormSubmit () {
    document.getElementById('project-form').dispatchEvent(new Event('submit', {cancelable: true}))
  }

  renderAssetTypeTitles (assetType) {
    let assetKey, ungroupedKey, assetBoxToggleKey
    switch (assetType) {
      case 'subcontractors':
        assetKey = 'bidsFromContractors'
        ungroupedKey = 'ungroupedSubcontractors'
        assetBoxToggleKey = 'isSubcontractorsAssetsBoxVisible'
        break;
      case 'materials':
        assetKey = 'materialsNeeded'
        ungroupedKey = 'ungroupedMaterials'
        assetBoxToggleKey = 'isMaterialsAssetsBoxVisible'
        break;
      case 'services':
        assetKey = 'servicesNeeded'
        ungroupedKey = 'ungroupedServices'
        assetBoxToggleKey = 'isServicesAssetsBoxVisible'
        break;
      default:
      break;
    }

    let subs = _.get(this.props.data, assetKey, []).map((id) => {
      return _.get(_.find(appStore.getState()[ungroupedKey], {id}), 'title', null)
    })
      .filter((r) => r) // remove nulls
      .join(', ')
      .trim(' ')

    let length = 40
    return <>{_.truncate(subs, {length})
      ? <span title={subs}>
          {_.truncate(subs, {length})}{' '}
        <a href="#more" onClick={(e) => {
          e.preventDefault()
          this.setState({[assetBoxToggleKey]: true})
        }}>
            View All
          </a></span>
      : null} </>
  }

  isValidMomentFormat (date, format) {
    let isValid = moment(moment(date).format(format), format, true).isValid()

    // make sure the year has 4 is between 1900 and 2050
    if (isValid) {
      let year = parseInt(moment(date).format('YYYY'))
      if (!(year >= 1900 && year <= 2050))
        isValid = false
    }

    return isValid
  }

  getValuesToSubmit(formValues){
    // otherwise the whole object will be replaced in the DB
    let valuesToSubmit =  _.pick(formValues, _.keys(this.state.changedFields))

    if (formValues.id)
      valuesToSubmit.id = formValues.id

    if (formValues.entries)
      valuesToSubmit.entries = JSON.parse(JSON.stringify(formValues.entries))

    return valuesToSubmit
  }

  async handleSubmit (formValues) {
    if (this.props.onSubmit) {
      let valuesToSubmit = this.getValuesToSubmit(formValues)

      await this.props.onSubmit(valuesToSubmit, this.state.acceptedFiles)
      this.setState({changedFields: {}})
    }

    if (this.projectFormRef)
      this.setState({acceptedFiles: []})
  }

  async handleAddEntry(entry) {
    if (this.props.onAddEntry) {
      let formValues = _form.getState().values
      let valuesToSubmit = this.getValuesToSubmit(formValues)
      this.setState({isUploading: true})
      let wasEntryAdded = await this.props.onAddEntry(valuesToSubmit, this.state.acceptedFiles, entry)
      this.setState({isUploading: false, acceptedFiles: []})
      return wasEntryAdded
    }
  }

  handleDeleteEntry (entry) {
    if (this.props.onDeleteEntry) {
      this.props.onDeleteEntry(entry)
    }
  }

  handleOnChange (data) {
    this.setState({
      changedFields: {
        ...this.state.changedFields,
        ...data
      }
    })
    this.props.onChange && this.props.onChange(data)
  }

  async handleSendArchitectInvite(){
    if (!this.props.data.architect) {
      return this.notificationService.error('Please provide an architect email')
    }

    let resp = await this.projectsService.sendInviteToArchitect(this.peopleService.me('userId'), this.props.data.id, this.props.data.architect)
    if (resp.status === 200)
      this.notificationService.success('Invitation sent!')
    else
      this.notificationService.error('Error sending invitation!')
  }

  isAllowedToModifyFiles() {
    return !this.props.data.id
      || this.peopleService.me().id === this.props.data.ownerId
      || this.peopleService.me().userType === 'admin'
  }

  render () {
    return (
      <div ref={(ref) => this.projectFormRef = ref}>
        <div className="ProjectForm content container-fluid">
          <Row>
            <Col lg={9} md={10} sm={12} className={`m-auto`}>
              <Card>
                <CardHeader className={`border-bottom mb-3`}>
                  <CardTitle tag="h2">
                    {this.props.formTitle} {this.props.data.isLimitedInfoDisplayed
                    ? '(Limited info displayed)'
                    : null}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    initialValues={this.props.data}
                    onSubmit={this.handleSubmit.bind(this)}
                    render={({handleSubmit, form, submitting, pristine, values, invalid, active, reset}) => {
                      _form = form
                      return (
                        <form onSubmit={handleSubmit} id={`project-form`}>
                          {this.props.data.id && this.peopleService.me().userType === 'admin'
                          ? <Row>
                              <Col className="ml-3 mr-3 mb-4">
                                <Row className="border p-1">
                                  <Col className="">
                                    <div className="font-weight-bold">Owner</div>
                                    <div>{this.props.data.owner.firstName} {this.props.data.owner.lastName}</div>
                                  </Col>

                                  <Col>
                                    <div className="font-weight-bold">Email</div>
                                    <div>{this.props.data.owner.email}</div>
                                  </Col>

                                  <Col>
                                    <div className="font-weight-bold">Phone</div>
                                    <div>{this.props.data.owner.phone}</div>
                                  </Col>

                                  <Col className={`pt-1`}>
                                    <Button
                                      variant={'outlined'}
                                      color={'primary'}
                                      onClick={() => window.app.history.push(`/user/profile/${this.props.data.ownerId}`)}
                                      className={`pull-right`}
                                    >
                                      Details
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          : null}

                          <Row className={'pb-2'}>
                            <Col className="">
                              <FormGroup>
                                <Field
                                  name={`name`}
                                  validate={ValidationService.instance().required}
                                >
                                  {({input, meta}) => (
                                    <TextField
                                      {...input}
                                      label={'Project Name'}
                                      variant={'outlined'}
                                      fullWidth={true}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched ? meta.error : null}
                                    />
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className={'pb-2'}>
                            <Col className="" sm={12} md={8}>
                              <FormGroup>
                                <Field
                                  name={`address`}
                                  validate={ValidationService.instance().required}
                                >
                                  {({input, meta}) => (
                                    <LocationSearchInput
                                      {...input}
                                      label={'Address'}
                                      variant={'outlined'}
                                      fullWidth={true}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched ? meta.error : null}
                                      onGeolocationEnd={({latLng, address}) => this.handleOnChange({
                                        location      : latLng,
                                        mailingAddress: address,
                                      })}
                                    />
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col className="" sm={12} md={4}>
                              <FormGroup>
                                <Field
                                  name={`suite`}
                                >
                                  {({input, meta}) => (
                                    <TextField
                                      {...input}
                                      label={'Suite or Parcel #'}
                                      variant={'outlined'}
                                      fullWidth={true}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched ? meta.error : null}
                                    />
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>

                          {this.props.data.id ?
                          <Row>
                            <Col>
                              <Field
                                name="architect"
                                validate={ValidationService.instance().emptyOrEmail}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      label={`Architect's email (optional)`}
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                      disabled={!this.props.onChange}
                                    />
                                  </>
                                )}
                              </Field>
                            </Col>
                            <Col className={`pt-2`}>
                              {this.props.data.id && this.props.onChange
                                ? <Button variant={'contained'} color={'primary'} onClick={this.handleSendArchitectInvite.bind(this)}>
                                  Send Invite
                                </Button>
                                : null}
                            </Col>
                          </Row>
                          : null}

                          <Row className={'pb-2'}>
                            <Col sm={12} md={4}>
                              <FieldsetRadioGroup
                                field={'type'}
                                options={[
                                  {value: 'residential', label: 'Residential'},
                                  {value: 'commercial', label: 'Commercial'},
                                ]}
                                value={this.props.data.type || ''}
                                onChange={this.handleOnChange.bind(this)}
                              />
                            </Col>
                            <Col sm={12} md={8}>
                              <FieldsetRadioGroup
                                field={'stage'}
                                options={[
                                  {value: 'New Construction', label: 'New Const.'},
                                  {value: 'Addition', label: 'Addition'},
                                  {value: 'Remodel', label: 'Remodel'},
                                  {value: 'Addition and Remodel', label: 'Addition & Remodel'},
                                ]}
                                value={this.props.data.stage || ''}
                                onChange={this.handleOnChange.bind(this)}
                              />
                            </Col>
                          </Row>

                          <Row className={'pb-2'}>
                            <Col className="" sm={4}>
                              <FormGroup>
                                <Field
                                  name="size"
                                  type="text"
                                  validate={ValidationService.instance().required}
                                >
                                  {({input, meta}) => (
                                    <>
                                      <TextField
                                        {...input}
                                        label={'Air conditioned sq.ft.'}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched && meta.error}
                                      />
                                    </>
                                  )}
                                </Field>

                              </FormGroup>
                            </Col>
                            <Col className="" sm={4}>
                              <FormGroup>
                                <Field
                                  name="budget"
                                  validate={ValidationService.instance().required}
                                >
                                  {({input, meta}) => (
                                    <TextField
                                      {...input}
                                      label={'Construction Budget ($)'}
                                      variant={'outlined'}
                                      fullWidth={true}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        readOnly      : !this.props.onChange,
                                      }}
                                      id="formatted-numberformat-input"
                                      onChange={(e) => {
                                        this.handleOnChange({[input.name]: e.target.value})
                                      }}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                    />
                                  )}
                                </Field>

                              </FormGroup>
                            </Col>
                            <Col className="" sm={4}>
                              <FormGroup>
                                <Field
                                  name="bidDue"
                                  type={'date'}
                                  validate={ValidationService.instance().required}
                                >
                                  {({input, meta}) => (
                                    <TextField
                                      {...input}
                                      label={`Bid Due Date`}
                                      value={input.value && this.isValidMomentFormat(input.value, 'YYYY-MM-DD')
                                        ? moment.utc(input.value).format('Y-MM-DD')
                                        : input.value}
                                      variant={'outlined'}
                                      fullWidth={true}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={!this.props.onChange}
                                      onBlur={(e) => {
                                        this.handleOnChange({[input.name]: e.target.value})
                                      }}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                    />
                                  )}
                                </Field>

                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className={`pb-4`}>
                            <Col sm={values.isDIY === 'yes' ? 3 : 3}>
                              <IsDIYField
                                field={'isDIY'}
                                title="Is this a DIY project?"
                                options={[
                                  {value: 'yes', label: 'Yes'},
                                  {value: 'no', label: 'No'},
                                ]}
                                value={this.props.data.isDIY || 'no'}
                                onChange={this.handleOnChange.bind(this)}
                              />
                            </Col>
                            {(!this.props.data.isDIY || this.props.data.isDIY === 'no') &&
                            <Col className="" sm={3}>
                              <fieldset className={`custom-fieldset fab-add-btn-container`}>
                                <legend>What subcontractors do you need?</legend>
                                <Fab color={'secondary'} className={`fab-add-btn`}
                                     onClick={() => this.setState({isSubcontractorsAssetsBoxVisible: true})}
                                ><span className="fa fa-plus"></span></Fab>
                                {this.state.isSubcontractorsAssetsBoxVisible
                                  ? <Assets
                                    className={`arrow-box-left open-right`}
                                    tab={0}
                                    showCloseBtn={true}
                                    onClose={() => this.setState({isSubcontractorsAssetsBoxVisible: false})}
                                    selectedAssets={this.props.data.bidsFromContractors || []}
                                    onAddToProject={this.props.onChange ? (selectedAssets) => {
                                      this.handleOnChange({bidsFromContractors: selectedAssets})
                                    } : null}
                                  />
                                  : null}

                                {this.renderAssetTypeTitles('subcontractors')}
                              </fieldset>
                            </Col>}

                            <Col className="" sm={3}>
                              <fieldset className={`custom-fieldset fab-add-btn-container`}>
                                <legend>What materials do you need?</legend>
                                <Fab color={'secondary'} className={`fab-add-btn`}
                                     onClick={() => this.setState({isMaterialsAssetsBoxVisible: true})}
                                ><span className="fa fa-plus"></span></Fab>
                                {this.state.isMaterialsAssetsBoxVisible
                                  ? <Assets
                                    className={`arrow-box-left open-right`}
                                    tab={1}
                                    showCloseBtn={true}
                                    onClose={() => this.setState({isMaterialsAssetsBoxVisible: false})}
                                    selectedAssets={this.props.data.materialsNeeded || []}
                                    onAddToProject={this.props.onChange ? (selectedAssets) => {
                                      this.handleOnChange({materialsNeeded: selectedAssets})
                                    } : null}
                                  />
                                  : null}

                                {this.renderAssetTypeTitles('materials')}
                              </fieldset>
                            </Col>

                            <Col className="" sm={3}>
                              <fieldset className={`custom-fieldset fab-add-btn-container`}>
                                <legend>What services do you need?</legend>
                                <Fab color={'secondary'} className={`fab-add-btn`}
                                     onClick={() => this.setState({isServicesAssetsBoxVisible: true})}
                                ><span className="fa fa-plus"></span></Fab>
                                {this.state.isServicesAssetsBoxVisible
                                  ? <Assets
                                    className={`arrow-box-right open-left`}
                                    tab={2}
                                    showCloseBtn={true}
                                    onClose={() => this.setState({isServicesAssetsBoxVisible: false})}
                                    selectedAssets={this.props.data.servicesNeeded || []}
                                    onAddToProject={this.props.onChange ? (selectedAssets) => {
                                      this.handleOnChange({servicesNeeded: selectedAssets})
                                    } : null}
                                  />
                                  : null}

                                {this.renderAssetTypeTitles('services')}
                              </fieldset>
                            </Col>

                          </Row>

                          <Row className={'pb-3'}>
                            <Col sm={12}>
                              <FormGroup>
                                <Field
                                  name="description"
                                  validate={ValidationService.instance().required}
                                >
                                  {({input, meta}) => (
                                    <TextField
                                      label={'Describe the project'}
                                      variant={'outlined'}
                                      value={this.props.data.description || ''}
                                      fullWidth={true}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                    />
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className={'pb-2'}>
                            {this.state.isUploading ? <div className={`page-status`}>Uploading...</div> : null}
                            <Col sm={12}>
                              <StyledFieldSet className="custom-fieldset">
                                <h3 className="mb-0 pb-3 border-bottom">Drawings and Specifications </h3>
                                {this.props.showDocs === true
                                ? <div>
                                  {this.props.data.entries
                                    ? <FileSystem
                                      entries={this.props.data.entries}
                                      creatorName={`${StorageService.instance().get('user.data.firstName')} ${StorageService.instance().get('user.data.lastName')}`}
                                      onAddEntry={this.isAllowedToModifyFiles() ? (entry) => {
                                        this.setState({
                                          acceptedFiles: [
                                            ...this.state.acceptedFiles,
                                            entry // can be files or folders, but ContainerService will ignore folders. But all will be saved to project object
                                          ]
                                        })

                                        return this.handleAddEntry(entry)
                                      } : false}
                                      onDeleteEntry={this.isAllowedToModifyFiles() ? (entry) => {
                                        this.handleDeleteEntry(entry)
                                      } : false}
                                      onIconClick={(entry) => {
                                        if (entry.type === FILE) {
                                          window.open(`${environment.apiUrl}containers/${this.props.data.ownerId}-${this.props.data.id}/download/${encodeURIComponent(entry.name)}`, '_blank');
                                        }
                                      }}
                                    />
                                    : 'No files available'
                                  }
                                </div>
                                : 'Files can be uploaded after creating the project'}
                              </StyledFieldSet>
                            </Col>
                          </Row>

                          {this.props.onSubmit
                            ? <button type="submit" className="d-none"></button>
                            : null}
                        </form>
                      )
                    }}
                  />

                  {this.props.showStats
                    ? <Row className={`pt-4`}>
                      <Col md={12} className={`mt-1`}>
                        <ProjectStats project={this.props.data}/>
                      </Col>
                    </Row>
                    : null}

                  {this.props.showStats
                    ? <Row className={`pt-4`}>
                      <Col md={12} className={`mt-1`}>
                        <ProjectMatches project={this.props.data}/>
                      </Col>
                    </Row>
                    : null}
                </CardBody>

                {this.props.onSubmit
                  ? <CardFooter className={`border-top`}>
                    <Button variant={'contained'} color={'secondary'} onClick={() => this.triggerFormSubmit()}
                            className={`pull-right`}>
                      {this.props.submitLabel}
                    </Button>
                  </CardFooter>
                  : null}
              </Card>
            </Col>
          </Row>
        </div>

        {this.props.onDelete
          ? <div className="pb-5 text-center">
            <Button
              variant={'text'}
              className={`text-danger`}
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this project'))
                  this.props.onDelete()
              }}>Delete Project</Button>
          </div>
          : null}

      </div>
    );
  }
}

ProjectForm.defaultProps = {
  onDelete    : null,
  onDeleteEntry: null,
  onChange    : null,
  onSubmit    : null,
  showStats   : false,
  showDocs   : true,
}

ProjectForm.propTypes = {
  formTitle   : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitLabel : PropTypes.string.isRequired,
  data        : PropTypes.object.isRequired,
  onSubmit    : PropTypes.func,
  onChange    : PropTypes.func,
  onDelete    : PropTypes.func,
  onAddEntry: PropTypes.func,
  onDeleteEntry: PropTypes.func,
  showStats   : PropTypes.bool,
  showDocs   : PropTypes.bool,
};

export default ProjectForm;