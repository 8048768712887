import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PeopleService } from '../../services/PeopleService';
import UserHeader from '../../components/UserHeader/UserHeader';
import SideBanner from '../../components/SideBanner/SideBanner';
import classNames from 'classnames';
import AdminDashboard from '../../views/AdminDashboard';
import AdminUsers from '../../views/Admin/AdminUsers';
import AdminProjectsContainer from '../../views/Admin/AdminProjectsContainer';
import AdminUserContainer from '../../views/Admin/AdminUserContainer';
import AdminEditProjectContainer from '../../views/Admin/AdminEditProjectContainer';
import AdminBidsContainer from '../../views/Admin/AdminBidsContainer';
import AdminMatchesContainer from '../../views/Admin/AdminMatchesContainer';
import AdminMaterialsContainer from '../../views/Admin/AdminMaterialsContainer';
import AdminServicesContainer from '../../views/Admin/AdminServicesContainer';

class AdminLayout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
    }

    this.peopleService = new PeopleService()

    window.notificationDOMRef = React.createRef();
  }

  async componentDidMount () {
    let loggedInUser = await this.peopleService.me()
    if (loggedInUser && loggedInUser.userType === 'admin') { // preliminary check without calling the API
      // ...now call the API to double check userType in case someone tampered with localStorage
      let resp = await this.peopleService.getLoggedInUser()
      if (!resp.data.userType === 'admin')
        window.app.history.push('/logout')
    } else {
      window.app.history.push('/logout')
    }
  }

  render () {
    return (
      <>
        <div className="AdminLayout wrapper">
          <div className={classNames('main-panel container-fluid p-1', {})}>
            <SideBanner/>
            <UserHeader title={'Admin Dashboard'} className={`container-fluid`}/>
            <div className="main-panel border-0">
                <Switch>
                  <Route path={`/admin/dashboard`} component={AdminDashboard}></Route>
                  <Route path={`/admin/projects/:userId/:id`} component={AdminEditProjectContainer}></Route>
                  <Route path={`/admin/projects`} component={AdminProjectsContainer}></Route>
                  <Route path={`/admin/user/:id`} component={AdminUserContainer}></Route>
                  <Route path={`/admin/bids`} component={AdminBidsContainer}></Route>
                  <Route path={`/admin/matches`} component={AdminMatchesContainer}></Route>
                  <Route path={`/admin/materials`} component={AdminMaterialsContainer}></Route>
                  <Route path={`/admin/services`} component={AdminServicesContainer}></Route>
                  <Route path={`/admin/:userType`} component={AdminUsers}></Route>
                </Switch>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdminLayout;
