import React, { Component } from 'react';
import { PeopleService } from '../../services/PeopleService';
import UserProfile from './UserProfile';

class ViewUserProfileContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // user data
    }

    this.peopleService = new PeopleService()
  }

  componentDidMount () {
    if (this.peopleService.me().userType === 'admin' && /^\/user\/profile/i.test(this.props.location.pathname))
      return this.props.history.push(`/admin/user/${this.props.match.params.id}`)

    this.getUserData()
  }

  async getUserData () {
    let resp = await this.peopleService.details(this.props.match.params.id)
    this.setState(resp.parsed.data)
  }

  render () {
    return (
      <UserProfile data={this.state} />
    );
  }
}

ViewUserProfileContainer.propTypes = {};

export default ViewUserProfileContainer;