import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import Link from '../components/Link';

export default class Privacy extends React.Component {

  render() {
    return (
      <>
        <div className="ForgotPassword container-fluid mt-5 ">
          <Row className="h-100 align-items-center">
            <Col sm="12" md="10" className="m-auto">
              <Card>
                <CardHeader>
                  <h1 className="puente-light text-center">
                    <Link href="/login">PrismBid</Link>
                  </h1>
                  <h3>Privacy Policy</h3>
                </CardHeader>
                <CardBody>
                  <div className="register-container full-height sm-p-t-30">
                    <div className="container-sm-height full-height">
                      <div className="row row-sm-height">
                        <div className="col-sm-12 col-sm-height col-middle">
                          <div>
                            <p>
                              The following information explains the information that BidRight, LLC collects and how
                              that information may be used by BidRight, LLC for its website www.prismbid.com
                              (hereinafter referred to as “BidRight, LLC”).
                            </p>
                            <p>
                              BidRight, LLC reserves the right to update this policy at any time and without any notice
                              to users of the BidRight, LLCwebsite. Additionally, the information contained in this
                              privacy policy applies to the following BidRight, LLC products: websites, computer
                              software, applications, and/or services provided by and/or purchased from BidRight, LLC.
                              (Collectively hereinafter referred to as “products”).
                            </p>

                            <p>
                              <b>
                                Acceptance by Use: Please note that by using our products, you are agreeing to the
                                policies contained within this policy, as BidRight, LLC as the terms and conditions
                                applicable to our services (available here:
                                <a href="https://www.prismbid.com/terms">https://www.prismbid.com/terms</a>) which are
                                incorporated by reference herein.
                              </b>
                            </p>

                            <ul>
                              <li>
                                <b>I. Information from Website Browsers</b>

                                <p>
                                  When you use our products, regardless of whether or not you have a registered account,
                                  BidRight, LLC may collect information from your visit. Most Websites collect the same
                                  type of information. Also, please note that our Website uses common internet
                                  technologies, such as cookies, to obtain information. Here is the information that
                                  BidRight, LLC may collect from you during your visit:
                                </p>

                                <ul>
                                  <li>
                                    The type of operating system and/or browser that you are using to visit our website
                                    or products;

                                  </li>
                                  <li> Your language preference;
                                  </li>
                                  <li> The website that referred you to us (if applicable);
                                  </li>
                                  <li> The date and time of your visit; and
                                  </li>
                                  <li> Your IP address (please note that this could be potentially
                                    personally-identifiable information).
                                  </li>
                                  <li> Your activity on our website;
                                  </li>
                                </ul>
                              </li>

                              <li>
                                <b>II. Information from Users with Registered Accounts</b>

                                <p>
                                  In addition to the information collected in subsection I above, when you register for
                                  an account with BidRight, LLC, BidRight, LLC will ask you to provide some additional
                                  personal information. BidRight, LLC may collect this information via the internet,
                                  telephone, in-person, or other methods. The personal information that BidRight, LLC
                                  may collect includes:
                                </p>

                                <ul>
                                  <li>Your full name;</li>
                                  <li>Your e-mail address (please note that by providing your e-mail address you are
                                    consenting to BidRight, LLC contacting you through that method for purposes
                                    including marketing);
                                  </li>
                                  <li>Your billing address;</li>
                                  <li>Your payment information (i.e. credit card number, etc.), including CSV numbers or
                                    other fraud-reducing details (please note that security code and/or CSV information
                                    is not retained on file);
                                  </li>
                                  <li>Your username and password (created by you); and</li>
                                  <li>Job site address.</li>
                                </ul>

                              </li>
                              <li>
                                <b>III. How BidRight, LLC Uses the Information Collected</b>

                                <p>Once BidRight, LLC collects any of the above described information from you,
                                  BidRight, LLC may use that information for one or more of the following reasons:</p>

                                <ol>
                                  <li>
                                    Communicating with you in general, including but not limited to the following
                                    purposes:

                                    <ul>
                                      <li>a. Account-related questions and/or concerns;</li>
                                      <li>b. Billing purposes including:</li>
                                      <ul>
                                        <li>i. Processing purchases;</li>
                                        <li>ii. Tracking transactions and/or maintaining an order history;</li>
                                        <li>iii. Fraud prevention</li>
                                      </ul>
                                      <li>c. Marketing purposes;</li>
                                    </ul>
                                  </li>
                                  <li>Providing services associated with our products as requested by you;</li>
                                  <li>Analyzing our business statistics;</li>
                                  <li>Personalizing your BidRight, LLC experience;</li>
                                  <li>Complying with legal and/or governmental requests and/or requirements;</li>
                                  <li>Providing and/or delivering information to Third Parties as detailed below.</li>
                                </ol>
                              </li>

                              <li><b>IV. Disclosure of Information to Third Parties</b>
                                <p>Rest assured, BidRight, LLC does not sell, transfer, or otherwise disclose your
                                  personal information to an unaffiliated third party. Notwithstanding, sometimes an
                                  event occurs or a situation arises whereby BidRight, LLC must disclose or otherwise
                                  provide your information to a third party. These situations include but are not
                                  limited to:</p>

                                <ol>
                                  <li>Providing payment information to payment processing companies including but not
                                    limited to credit card processing companies;
                                  </li>
                                  <li>Providing information as requested and/or required by legal and/or governmental
                                    authorities;
                                  </li>
                                  <li>Fraud prevention;</li>
                                  <li>Providing your contact information to contractors as requested by you.</li>
                                </ol>
                                <p>In accordance with the applicable laws of the United States of America, BidRight, LLC
                                  will not disclose your consumer information without your written consent, except as
                                  necessary to comply with a court order or other legal and/or governmental request
                                  and/or requirement, and/or to complete the billing/payment process. By using BidRight,
                                  LLC products, you are consenting to the disclosure of your information for the above
                                  stated purposes.</p>
                              </li>


                              <li>
                                <b>V. Protecting Your Information</b>
                                <p>BidRight, LLC engages in appropriate and standard industry security protocols to
                                  ensure protection of your personal information. BidRight, LLC cannot guarantee, nor
                                  does BidRight, LLC warrant, absolute security of your information, insofar as no
                                  information and/or data transmitted via the internet is absolutely secure. Please note
                                  that BidRight, LLC data and/or information may be stored on a server in a location or
                                  jurisdiction with different laws than the jurisdiction in which you reside, and may be
                                  subject to requests and/or access from legal and/or governmental authorities different
                                  than those where you reside.</p>
                              </li>
                              <li>
                                <b>VI. Accessing Third Party Websites</b>
                                <p>Occasionally, BidRight, LLC utilizesthird party websites and/or social media
                                  platforms. BidRight, LLC is not responsible for the privacy practices, or lack
                                  thereof, once you leave or otherwise navigate away from the BidRight, LLC
                                  platform.</p>
                              </li>
                              <li>
                                <b>VII. European Economic Area (“EEA”) Notice</b>
                                <p>If you are located or otherwise reside in the European Union (hereinafter referred to
                                  “EU”), and utilize BidRight, LLC products; or if BidRight, LLCin any way obtains
                                  and/or uses your personal information collected from and/or provided by you through
                                  use of our products or otherwise; or if BidRight, LLC transfersyour personal
                                  information in any way, BidRight, LLC does so in accordance with this Privacy Policy,
                                  the applicable BidRight, LLC Terms of Service/Use (incorporated by reference herein),
                                  and in compliance with applicable requirements of the General Data Protection
                                  Regulation (EU 2016/679) (GDPR).</p>
                              </li>

                              <li>
                                <b>Transfers of Personal Information</b>

                                <p>BidRight, LLC is a data controller and responsible for your Personal Information,
                                  which BidRight, LLC may process and store in United States of America. The European
                                  Commission has decided that United States of America ensures an adequate level of
                                  protection of individuals’ Personal Information. Company may use the following
                                  safeguards when transferring your personal information to a country, other than United
                                  States of America, that is not within the EEA:</p>
                                <p>(a) Only transfer your Personal Information to countries that have been deemed by the
                                  European Commission to provide an adequate level of protection for personal
                                  information;</p>
                                <p>(b) Where BidRight, LLC use certain service providers, BidRight, LLC may use specific
                                  contracts approved by the European Commission which give Personal Information the same
                                  protection it has in the EU.</p>
                              </li>

                              <li>
                                <b>
                                  Opt-in
                                </b>
                                <p>If you are an EU resident, BidRight, LLC may only collect your data using cookies and
                                  similar devices, and then track and use your personal information where you have first
                                  consented to that. BidRight, LLC does not automatically collect personal information
                                  from you as described above unless you have consented to us doing so. If you consent
                                  to our use of cookies and similar devices, you may at a later date disable them
                                  (please see above).</p>
                              </li>
                              <li><b>Your Legal Rights</b>

                                <p>Under certain circumstances, you may have rights under the data protection laws in
                                  relation to your personal information, including the right to:</p>

                                <ul>
                                  <li>Request access to your personal information.</li>
                                  <li>Request correction of your personal information.</li>
                                  <li>Request erasure of your personal information.</li>
                                  <li>Object to processing of your personal information.</li>
                                  <li>Request restriction of processing your personal information.</li>
                                  <li>Request transfer of your personal information.</li>
                                  <li>Right to withdraw (revoke) consent.</li>
                                </ul>


                                <p>If you wish to exercise any of these rights, please contact BidRight, LLC.</p>
                              </li>
                              <li>
                                <b>No Fee Usually Required</b>
                                <p>You do not have to pay a fee to access your personal information (or to exercise any
                                  of the other rights). However, BidRight, LLC my charge a reasonable fee if your
                                  request is clearly unfounded, repetitive or excessive. Alternatively, BidRight, LLC
                                  may refuse to comply with your request in these circumstances.</p>
                              </li>
                              <li>
                                <b>What BidRight, LLC May Need From You</b>
                                <p>BidRight, LLC may need to request specific information from you to help us to confirm
                                  your identity and ensure your right to access your personal data (or to exercise any
                                  of your other rights). This is a security measure to ensure that personal information
                                  is not disclosed to any person who has no right to receive it. BidRight, LLC may also
                                  contact you to ask you for further information in relation to your request to speed up
                                  our response.</p>
                              </li>
                              <li>
                                <b>Time Limit to Respond</b>
                                <p>BidRight, LLC tries to respond to all legitimate requests within one (1) month.
                                  Occasionally it may take us longer than one (1) month if your request is particularly
                                  complex and/or you have made a number of requests. In this case, BidRight, LLC will
                                  notify you and provide you with updates.</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}