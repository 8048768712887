import { ModelService } from './ModelService';
import { ProjectsService } from './ProjectsService';
import { StorageService } from './StorageService';

export class AdminService extends ModelService {

  modelName = 'Admin'

  constructor () {
    super()
    this.projectsService = new ProjectsService()
    this.storageService = new StorageService()
  }

  static instance() {
    return new AdminService() // create a singleton with a closure
  }

  getStats(params = {filter: {}}){
    return this.apiService.go(
      `/Admin/stats${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  getPeople(params = {filter: {}}){
    let timeframe = ''
    if (typeof params.filter.timeframe !== 'undefined') {
      timeframe = params.filter.timeframe + '' // copy it
      delete params.filter.timeframe
    }

    let createdAt = ''
    if (typeof params.filter.createdAt !== 'undefined') {
      createdAt = params.filter.createdAt + '' // copy it
      delete params.filter.createdAt
    }

    return this.apiService.go(
      `Admin/People${params.filter ? `?${this.prepareFilter(params)}${createdAt && `&createdAt=${createdAt}`}${timeframe && `&timeframe=${timeframe}`}` : ''}`);
  }

  changePassword (userId, password) {
    return this.apiService.go(`/Admin/${userId}/Person/change-password`, {
      method: 'POST',
      body  : {password},
    })
  }

  getProjects (params = {filter: {}}) {
    return this.apiService.go(
      `Admin/Projects${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  getProjectsByUser (userId, params = {filter: {}}) {
    return this.apiService.go(
      `Admin/${userId}/Project/customer-projects${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  getProjectByUser (userId, projectId) {
    return this.apiService.go(
      `Admin/${userId}/Project/details/${projectId}`);
  }

  getBidProjectsByUser (userId, params = {filter: {}}) {
    return this.apiService.go(
      `Admin/${userId}/Project/bid-projects-by-user${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  getMatchedProjectsByUser (userId, params = {filter: {}}) {
    return this.apiService.go(
      `Admin/${userId}/Project/matched-projects-by-user${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  deleteProject (projectId) {
    return this.apiService.go(`Admin/Project/${projectId}`, {method: 'DELETE'});
  }

  updateProjectByUser (userId, projectId, data) {
    return this.apiService.go(`Admin/${userId}/Person/${projectId}`, {
      method: 'POST',
      body  : {
        projectData: data,
      },
    });
  }

  getMatches (params = {filter: {}}) {
    let timeframe = params.filter['timeframe'] + ''
    delete params.filter['timeframe']
    return this.apiService.go(`Admin/matches/${timeframe}${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  getBids  (params = {filter: {}}) {
    let timeframe = params.filter['timeframe'] + ''
    delete params.filter['timeframe']
    return this.apiService.go(`Admin/bids/${timeframe}${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  getMaterials  (params = {filter: {}}) {
    let timeframe = params.filter['timeframe'] + ''
    delete params.filter['timeframe']
    return this.apiService.go(`Admin/materials/${timeframe}${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  getServices  (params = {filter: {}}) {
    let timeframe = params.filter['timeframe'] + ''
    delete params.filter['timeframe']
    return this.apiService.go(`Admin/services/${timeframe}${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

}