import React from 'react';
import { Route, Switch } from 'react-router-dom';

import OwnerDashboard from '../../views/OwnerDashboard';
import AddProjectContainer from '../../components/Project/AddProjectContainer';
import EditProjectContainer from '../../components/Project/EditProjectContainer';
import { PeopleService } from '../../services/PeopleService';
import MessageCenter from '../../components/MessageCenter/MessageCenter';

class OwnerLayout extends React.Component {
  componentDidMount () {
    if (!['approved', 'temp_approved', 'creating_account'].includes(PeopleService.instance().me('status'))){
      window.app.history.push('/review-account')
    }
  }

  render () {
    return (
      <div className={`OwnerLayout`}>
        <Switch>
          <Route path={`/owner/dashboard`} component={OwnerDashboard}/>
          <Route path={`/owner/projects/add`} component={AddProjectContainer}/>
          <Route path={`/owner/projects/:id`} component={EditProjectContainer}/>
          <Route path={`/owner/messages`} component={MessageCenter}/>
        </Switch>
      </div>
    )
  }
}

export default OwnerLayout;
