import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Assets from '../Project/Assets';

class ContractorType extends Component {
  render () {
    return (
      <div className={`ContractorType`}>
        <Assets
          formTitle={`Contractor Type`}
          className={`arrow-box-left open-right condensed`}
          tab={0}
          selectedAssets={this.props.value}
          type={`radio`}
          onAddToProject={(selectedAssets) => {
            this.props.onChange(selectedAssets)
          }}
          onClose={this.props.onClose}
          showCount={false}
          buttonLabel={'Submit'}
        />
      </div>
    );
  }
}

ContractorType.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContractorType;