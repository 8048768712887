import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as _ from 'lodash';

class Tabs extends Component {

  handleTabChange(activeTab, e){
    e.preventDefault()
    this.props.onChange(activeTab, e)
  }

  render () {
    return (
      <div className={classNames(`Tabs container`, this.props.className)}>
        <div className="row">
          {this.props.tabs
            .map((tab, k) => (
              <button
                key={k}
                onClick={this.handleTabChange.bind(this, k)}
                className={classNames(`text-center col-${12 / this.props.tabs.length}`, {
                  active: this.props.activeTab === k,
                  'd-none': this.props.showOnlyActiveTab ? k !== this.props.activeTab : false
                })}
              >
                {_.upperFirst(tab.toLowerCase())}
              </button>
            ))}
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  activeTab: 0,
  showOnlyActiveTab: false,
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.number,
  showOnlyActiveTab: PropTypes.bool
};

export default Tabs;