import React from 'react';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Row,
  Col, CardHeader,
} from 'reactstrap';
import { Button } from '@material-ui/core'
import Link from '../../components/Link';
import { PeopleService } from '../../services/PeopleService';
import { NotificationService } from '../../services/NotificationService';
import { StorageService } from '../../services/StorageService';
import TextField from '@material-ui/core/es/TextField/TextField';
import InputAdornment from '@material-ui/core/es/InputAdornment/InputAdornment';
import YellowButton from '../../components/YellowButton';
import { withLocationQuery } from '../../utils/withLocationQuery';

class Login extends React.Component {

  constructor (props) {
    super(props)
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
    this.storageService = new StorageService()
    this.state = {
      email   : '',
      password: '',
    }
  }

  async componentDidMount () {
    let loggedInUser = await this.peopleService.me()
    if (loggedInUser) {
      if (this.peopleService.getAfterLoginPath()) {
        let afterLoginPath = this.peopleService.getAfterLoginPath()
        await this.peopleService.deleteAfterLoginPath()
        window.app.history.navigateByUrl(afterLoginPath);
      } else {
        await this.peopleService.navigateToPageBasedOnRole()
      }
    }
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (['PUSH', 'POP'].includes(prevProps.history.action) && prevProps.location.pathname !== '/login' && this.storageService.get('user.data.id')) {
      await this.peopleService.navigateToPageBasedOnRole()
    }
  }

  async login () {
    if (this.state.email && this.state.password) {
      let resp = await this.peopleService.login({
        email   : this.state.email,
        password: this.state.password,
      })

      if (resp.status === 200) {
        if (this.peopleService.getAfterLoginPath()) {
          let afterLoginPath = this.peopleService.getAfterLoginPath()
          await this.peopleService.deleteAfterLoginPath()
          window.app.history.navigateByUrl(afterLoginPath);
        } else {
          await this.peopleService.navigateToPageBasedOnRole()
        }
      }
    } else {
      this.notificationService.error('Provide a valid email and password')
    }
  }

  getTextByLocationQuery() {
    switch (this.props.locationQuery.get('t')) {
      case 'a':
        return 'Free Architectural Leads'
      case 'gc':
        return 'Free Contracting jobs'
      case 's': // subcontractor
        return 'Free Subcontracting jobs'
      case 'm':
        return 'Free Manufacturing Leads'
      case 'r':
        return 'Free Retailer Leads'
      default:
        return 'Free Construction Estimates'
    }
  }

  render () {
    return (
      <div className="Login content container-fluid">
        <Row className="h-100 align-items-center">
          <Col sm="12" md="4" className="ml-0 ml-md-3">
            <Card>
              <CardHeader className={`pb-2`}>
                <div className="row">
                  <div className="col-sm-1">

                  </div>
                  <div className="col-sm-11">
                    <h1 className={`m-0`}>
                      Login to your account
                    </h1>
                    <small>Fill out the form to login into PrismBid</small>
                  </div>
                </div>
              </CardHeader>
              <CardBody className={`pl-5 pt-5 pr-5`}>
                <Form>
                  <Row>
                    <Col className="">
                      <FormGroup>
                        <TextField
                          autoFocus={true}
                          variant={'outlined'}
                          placeholder="Type your email here"
                          type="email"
                          value={this.state.email}
                          required
                          onChange={(e) => this.setState({email: e.target.value})}
                          onKeyUp={(e) => e.key.toLowerCase() === 'enter' && this.login()}
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <span className="fa fa-envelope"></span>
                              </InputAdornment>
                            )
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="">
                      <FormGroup>
                        <TextField
                          variant={'outlined'}
                          placeholder="Password"
                          type="password"
                          value={this.state.password}
                          required
                          onChange={(e) => this.setState({password: e.target.value})}
                          onKeyUp={(e) => e.key.toLowerCase() === 'enter' && this.login()}
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <span className="fa fa-lock"></span>
                              </InputAdornment>
                            )
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <Link href="/forgot-password" className={`small`}>Forgot Password</Link>
                    </Col>
                    <Col className="text-right" md={7}>
                      <Link href="/support" className={`small`}>Help? Contact Support</Link>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter className={`pl-5 pb-5 pr-5`}>
                <div className="row">
                  <div className="col-6">
                    <YellowButton type="button" variant="contained" size="large" onClick={() => this.login()} fullWidth={true}>
                      Login
                    </YellowButton>
                  </div>
                  <div className="col-6">
                    <Button className="pull-right" color="secondary" type="button" variant="contained" size="large" fullWidth={true}
                    onClick={() => window.app.history.push('/register')}>
                      Register
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="4" className="ml-0 ml-md-3">
            <h1 style={{fontSize: 80, color: '#fff'}}>
              {this.getTextByLocationQuery()}
            </h1>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withLocationQuery(Login);
