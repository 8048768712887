import React from 'react'
import PropTypes from 'prop-types'

// reactstrap components
import {
  Table,
} from 'reactstrap';

import moment from 'moment'
import { Button } from '@material-ui/core';
import * as _ from 'lodash'
import Link from '../Link';

class ReceivedEstimates extends React.Component {
  render () {
    return (
      <Table responsive>
        <thead className="text-primary">
        <tr>
          <th>Name</th>
          <th>Project Name</th>
          <th>Project Address</th>
          <th>Amount</th>
          <th>Date</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {this.props.data.length
          ? this.props.data.map((estimate, k) => {
            return (
              <tr key={k}>
                <td><Link href={`/user/profile/${estimate.bidderId}`}>{_.startCase(estimate.name)}</Link></td>
                <td>{estimate.projectName}</td>
                <td>{estimate.projectAddress}</td>
                <td>${estimate.amount}</td>
                <td>{moment(estimate.date).format('MM/DD/YY')}</td>
                <td style={{minWidth: 130}}>
                  {estimate.status === 'accepted'
                    ? <span className="text-success">Accepted</span>
                    : <Button
                      size={'small'}
                      variant={'outlined'}
                      color={'default'}
                      className={`mr-1`}
                      onClick={() => this.props.acceptBid({
                        projectId: estimate.projectId,
                        bidderId : estimate.bidderId,
                        bidIndex : k,
                      })}
                    >
                      <span className="fa fa-check pr-2"></span> Accept
                    </Button>}
                </td>
              </tr>
            )
          })
          : <tr>
            <td className={`text-center`} colSpan={6}>You don't have any estimates yet</td>
          </tr>}
        </tbody>
      </Table>
    );
  }
}

ReceivedEstimates.defaultProps = {
  data: [],
}

ReceivedEstimates.propTypes = {
  data     : PropTypes.array.isRequired,
  acceptBid: PropTypes.func.isRequired,
}

export default ReceivedEstimates