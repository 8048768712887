import { ModelService } from './ModelService';

export class ProjectsService extends ModelService {

  modelName = 'Projects'

  // opportunities
  getProjectsToAuction () {
    return this.apiService.go(this.getAuthModelNamePath() + '/auction-projects');
  }

  getLeadProjects () {
    return this.apiService.go(this.getAuthModelNamePath() + '/lead-projects');
  }

  getCustomerProjects () {
    return this.apiService.go(this.getAuthModelNamePath() + '/customer-projects');
  }

  getCustomerProjectsByUser (id) {
    return this.apiService.go(`People/${id}/Project/customer-projects`);
  }

  getLeadProjectsByUser (id) {
    return this.apiService.go(`People/${id}/Project/lead-projects`);
  }

  getProjectsToAuctionByUser (id) {
    return this.apiService.go(`People/${id}/Project/auction-projects`);
  }

  getProjects (userId = this.storageService.get('user.tokenData.userId'), params = {filter: {}}) {
    return this.apiService.go(`People/${userId}/Project/all${params.filter ? `?${this.prepareFilter(params)}` : ''}`);
  }

  pay (projectId, nonce) {
    return this.apiService.go(this.getAuthModelNamePath() + '/buy-lead', {
      method: 'POST',
      body  : {
        projectId: projectId,
        nonce    : nonce,
      },
    });
  }

  payForFreeLead (projectId) {
    return this.apiService.go(this.getAuthModelNamePath() + '/buy-free-lead', {
      method: 'POST',
      body  : {
        projectId: projectId,
      },
    });
  }

  bid (projectId, bidValue, bidTerms = '', specificationsDivision = '') {
    return this.apiService.go(this.getAuthModelNamePath() + '/bid-lead', {
      method: 'POST',
      body  : {
        projectId             : projectId,
        bidValue              : parseFloat(bidValue),
        bidTerms              : bidTerms,
        specificationsDivision: specificationsDivision,
      },
    });
  }

  acceptBid (projectId, bidderId) {
    return this.apiService.go(this.getAuthModelNamePath() + '/accept-bid', {
      method: 'POST',
      body  : {
        projectId: projectId,
        bidderId : bidderId,
      },
    });
  }

  details (projectId) {
    return this.apiService.go(this.getAuthModelNamePath() + '/details/' + projectId);
  }

  getInvitedProject (projectId) {
    return this.apiService.go('/People/invited-project/' + projectId);
  }

  sendInviteToArchitect (userId, projectId, architectEmail) {
    return this.apiService.go(`People/${userId}/Project/${projectId}/send-invite-to-architect`, {
      method: 'POST',
      body  : {email: architectEmail},
    });
  }

  acceptInvite(userId, projectId){
    return this.apiService.go(`People/${userId}/Project/${projectId}/accept-invite`, {
      method: 'POST',
      body  : {},
    });
  }
}
