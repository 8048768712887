import React, { Component } from 'react';
import { Button } from '@material-ui/core';

class YellowButton extends Component {
  render () {
    return (
      <Button {...this.props} style={{
        background: '#e2d410',
        color: '#000'
      }}>{this.props.children}</Button>
    );
  }
}

export default YellowButton;