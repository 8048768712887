import Login from './views/Login/Login';
import Support from './views/Support';
import ForgotPassword from './views/ForgotPassword';
import Terms from './views/Terms';
import Privacy from './views/Privacy';
import OwnerLayout from './layouts/User/OwnerLayout';
import ContractorLayout from './layouts/User/ContractorLayout';
import Register from './views/Register';
import OnboardContainer from './views/Onboard/OnboardContainer';
import ContractorProfileContainer from './views/ContractorProfileContainer';
import InviteSubcontractorsContainer from './views/InviteSubcontractors/InviteSubcontractorsContainer';
import UserProfileContainer from './views/UserProfileContainer';
import AdminLayout from './layouts/Admin/AdminLayout';
import GcLayout from './layouts/User/GcLayout';
import ArchitectLayout from './layouts/User/ArchitectLayout';
import ProjectInvite from './components/ProjectInvite';
import ReviewAccount from './components/UserProfile/ReviewAccount';
import VerifyEmail from './components/UserProfile/VerifyEmail';
import ResetPassword from './views/ResetPassword';
import MessageCenter from './components/MessageCenter/MessageCenter';

var routes = [
  {
    path     : '/login',
    name     : 'login',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: Login,
    layout   : '/public',
  },
  {
    path     : '/register',
    name     : 'register',
    component: Register,
    layout   : '/public',
  },
  {
    path     : '/support',
    name     : 'support',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: Support,
    layout   : '/public',
  },
  {
    path     : '/forgot-password',
    name     : 'forgot-password',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: ForgotPassword,
    layout   : '/public',
  },
  {
    path     : '/reset-password',
    name     : 'reset-password',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: ResetPassword,
    layout   : '/public',
  },
  {
    path     : '/project/invite/:projectId',
    name     : 'Get Invited to a project',
    icon     : 'tim-icons icon-single-02',
    component: ProjectInvite,
    layout   : '/public',
  },
  {
    path     : '/review-account',
    name     : 'Send your account for review',
    icon     : 'tim-icons icon-single-02',
    component: ReviewAccount,
    layout   : '/public',
  },
  {
    path     : '/verify-email',
    name     : 'Verify Email',
    icon     : 'tim-icons icon-single-02',
    component: VerifyEmail,
    layout   : '/public',
  },
  {
    path     : '/owner/*',
    name     : 'owner-layout',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: OwnerLayout,
    layout   : '/owner',
  },
  {
    path     : '/gc/*',
    name     : 'gc-layout',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: GcLayout,
    layout   : '/gc',
  },
  {
    path     : '/subcontractor/*',
    name     : 'subcontractor-layout',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: ContractorLayout,
    layout   : '/subcontractor',
  },
  {
    path     : '/architect/*',
    name     : 'architect-layout',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: ArchitectLayout,
    layout   : '/architect',
  },
  {
    path     : '/terms',
    name     : 'terms',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: Terms,
    layout   : '/public',
  },
  {
    path     : '/privacy',
    name     : 'privacy',
    rtlName  : '',
    icon     : 'tim-icons icon-chart-pie-36',
    component: Privacy,
    layout   : '/public',
  },
  {
    path     : '/dashboard',
    name     : 'PrismBid Dashboard',
    rtlName  : 'لوحة القيادة',
    icon     : 'tim-icons icon-chart-pie-36',
    component: AdminLayout,
    layout   : '/admin',
  },
  {
    path     : '/profile',
    name     : 'User Profile',
    rtlName  : 'ملف تعريفي للمستخدم',
    icon     : 'tim-icons icon-single-02',
    component: UserProfileContainer,
    layout   : '/user',
  },
  {
    path     : '/professional-profile',
    name     : 'Professional Profile',
    icon     : 'tim-icons icon-single-02',
    component: ContractorProfileContainer,
    layout   : '/user',
  },
  {
    path     : '/onboard/*',
    name     : 'Onboard',
    icon     : 'tim-icons icon-single-02',
    component: OnboardContainer,
    layout   : '/user',
  },
  {
    path     : '/gc/invite-subcontractors',
    name     : 'Invite Subcontractors',
    icon     : 'tim-icons icon-single-02',
    component: InviteSubcontractorsContainer,
    layout   : '/user',
  },
  {
    path     : '/messages',
    name     : 'Messages',
    component: MessageCenter,
    layout   : '/user',
  },
];
export default routes;
