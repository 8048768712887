import React from 'react';
import { Route, Switch } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import classNames from 'classnames';

// core components
import routes from '../../routes';
import SideBanner from '../../components/SideBanner/SideBanner';
import { PeopleService } from '../../services/PeopleService';
import UserHeader from '../../components/UserHeader/UserHeader';
import appStore from '../../AppStore';

var ps;

class UserLayout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: appStore.getState().isLoading,
      backgroundColor: 'blue',
    };

    this.peopleService = new PeopleService()

  }

  async componentDidMount () {
    let loggedInUser = this.peopleService.me()
    if (!loggedInUser)
      return window.app.history.push('/logout')

    this.unsubscribe = appStore.subscribe(() => {
      let checkBeforeUpdateLocally = ['isLoading', 'lastUserUpdate']
      for (let i = 0; i <= checkBeforeUpdateLocally.length; i++) {
        let field = checkBeforeUpdateLocally[i]
        if (this.state[field] !== appStore.getState()[field]) {
          this.setState({[field]: appStore.getState()[field]})
        }
      }
    })

    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel, {suppressScrollX: true});
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }

  componentWillUnmount () {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }

    this.unsubscribe()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.history.action === 'PUSH') {
      if (navigator.platform.indexOf('Win') > -1) {
        let tables = document.querySelectorAll('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes (routes) {
    return routes.map((prop, key) => {
      if (['/admin', '/user'].includes(prop.layout)) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (['/gc', '/owner', '/subcontractor', '/architect'].includes(prop.layout)) {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  getDashboardTitle() {
    let dashboardTypeLabel = ''
    let loggedInUser =  this.peopleService.me()

    switch(loggedInUser.userType) {
      case 'owner':
        dashboardTypeLabel = 'Owner'
        break;
      case 'gc':
        dashboardTypeLabel = 'General Contractor'
        break;
      case 'subcontractor':
        dashboardTypeLabel = 'Subcontractor'
        break;
      case 'architect':
        dashboardTypeLabel = 'Architect'
        break;
      case 'retailer':
        dashboardTypeLabel = 'Retailer'
        break;
      case 'manufacturer':
        dashboardTypeLabel = 'Manufacturer'
        break;
      case 'admin':
        dashboardTypeLabel = 'Admin'
        break;
      default:
        break;
    }

    return `${dashboardTypeLabel} Dashboard`
  }

  render () {
    if (!this.peopleService.me())
      return null

    return (
      <>
        <div className={classNames('UserLayout wrapper', {})}>
          {this.state.isLoading ? <label className={`page-status`}>Loading</label> : null}
          <div
            className={classNames('main-panel container-fluid p-1', {})}
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <SideBanner/>
            {this.props.match.path !== '/onboard'
              ? <UserHeader title={this.getDashboardTitle()} className={`container-fluid`}/>
              : null
            }
            <Switch>
              {this.getRoutes(routes)}
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default UserLayout;
