import React, { Component, PureComponent } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { PeopleService } from './services/PeopleService';
import { createBrowserHistory } from "history";
import * as Sentry from '@sentry/browser';
import { hotjar } from 'react-hotjar/index';
import * as _ from 'lodash'
import ReactNotification from "react-notifications-component";

import AdminLayout from "layouts/Admin/AdminLayout";
import PublicLayout from "layouts/Public/PublicLayout";
import UserLayout from './layouts/User/UserLayout';
import appStore from './AppStore';
import { SubcontractorService } from './services/SubcontractorService';
import { MaterialService } from './services/MaterialService';
import { ServiceService } from './services/ServiceService';
import environment from './environments/environment';

if (environment.production) {
  Sentry.init({dsn: "https://6afc50ceb8cd4b65b0b392692220ab2c@sentry.io/1807620"});

  if (environment.hjid)
    hotjar.initialize(environment.hjid, environment.hjsv)
  else
    console.log('hotjar not enabled')
} else  {
  hotjar.initialize(environment.hjid, environment.hjsv)
}

const hist = createBrowserHistory();
window.app = {
  history: hist
}
// alias
window.app.history.navigateByUrl = window.app.history.push


class LogoutComponent extends PureComponent {
  async componentDidMount() {
    await PeopleService.instance().logout()
    window.app.history.navigateByUrl('/login');
  }

  render = () => null
}

class App extends Component {

  constructor (props) {
    super(props)
    this.state = {}

    this.peopleService = new PeopleService()
    this.subcontractorService = new SubcontractorService()
    this.materialService = new MaterialService()
    this.serviceService = new ServiceService()
  }

  componentDidMount () {
    this.unsubscribe = appStore.subscribe(() => {
      let checkBeforeUpdateLocally = ['isLoading']
      for (let i = 0; i <= checkBeforeUpdateLocally.length; i++) {
        let field = checkBeforeUpdateLocally[i]
        if (this.state[field] !== appStore.getState()[field]) {
          this.setState({[field]: appStore.getState()[field]})
        }
      }
    })

    this.getSubcontractors()
    this.getMaterials()
    this.getServices()
  }

  componentWillUnmount () {
    this.unsubscribe()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.lastUserUpdate, this.state.lastUserUpdate) && this.state.lastUserUpdate) {
      if (this.storageService.get('user.data.id'))
        this.peopleService.refreshUserDataInLocalStorage()
    }
  }

  async getSubcontractors(){
    let resp = await this.subcontractorService.find()

    // group them in categories
    let group = _.groupBy(resp.parsed.data, (row) => row.category)
    let groupAsArray = []
    _.each(group, (data, key) => {
      groupAsArray.push({
        category: key,
        data
      })
    })

    appStore.dispatch({
      type: 'set',
      state: {
        ...appStore.getState(),
        ungroupedSubcontractors: resp.parsed.data,
        subcontractors: groupAsArray
      }
    })
  }

  async getMaterials(){
    let resp = await this.materialService.find()

    // group them in categories
    let group = _.groupBy(resp.parsed.data, (row) => row.category)
    let groupAsArray = []
    _.each(group, (data, key) => {
      groupAsArray.push({
        category: key,
        data
      })
    })

    appStore.dispatch({
      type: 'set',
      state: {
        ...appStore.getState(),
        ungroupedMaterials: resp.parsed.data,
        materials: groupAsArray
      }
    })
  }

  async getServices(){
    let resp = await this.serviceService.find()

    // group them in categories
    let group = _.groupBy(resp.parsed.data, (row) => row.category)
    let groupAsArray = []
    _.each(group, (data, key) => {
      groupAsArray.push({
        category: key,
        data
      })
    })

    appStore.dispatch({
      type: 'set',
      state: {
        ...appStore.getState(),
        ungroupedServices: resp.parsed.data,
        services: groupAsArray
      }
    })
  }

  render () {
    return (
      <div>
        <ReactNotification ref={(ref) => window.notificationDOMRef = ref} />

        <Router history={hist}>
          <Switch>
            <Route path="/admin" render={props => <AdminLayout {...props} />} />
            <Route path="/logout" render={() => <LogoutComponent/>} />
            <Route path="/owner" render={props => <UserLayout {...props} />} />
            <Route path="/gc" render={props => <UserLayout {...props} />} />
            <Route path="/architect" render={props => <UserLayout {...props} />} />
            <Route path="/subcontractor" render={props => <UserLayout {...props} />} />
            <Route path="/user" render={props => <UserLayout {...props} />} />
            <Route path="/" render={props => <PublicLayout {...props} />} />
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </Router>
      </div>
    );
  }
}

App.propTypes = {};

export default App;