import React, { Component } from 'react';
import { NotificationService } from '../../services/NotificationService';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import DataTable from '../../components/DataTable/DataTable';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as _ from 'lodash';
import * as qs from "querystring";
import { AdminService } from '../../services/AdminService';

const StyledCardTitle = styled(CardTitle)`
  font-size: 24px;
`
class AdminMaterialsContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: []
    }

    this.adminService = new AdminService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.processFiltersFromUrl()
    setTimeout(() => this.getData()) // wait for the cycle to update the state
  }


  async processFiltersFromUrl () {
    let search = qs.decode(this.props.location.search.replace('?', ''))
    this.setState({
      filters: {
        ...this.state.filters,
        ...search,
      },
    })
  }

  async getData() {
    let resp = await this.adminService.getMaterials({
      filter: {
        page    : this.state.page,
        limit   : this.state.limit,
        userType: this.props.match.params.userType,
        ...this.state.filters,
      },
    }) // this is for admin, not for user

    if (resp.status === 200) {
      this.setState({data: resp.parsed.data})
    } else {
      this.notificationService.error('There was an error retrieving the data')
    }
  }

  handleSearch () {
    this.getData()
  }

  updateFilters(col, searchValue){
    this.setState({
      filters: _.merge({}, this.state.filters, {[col.field]: searchValue}),
    })
  }

  render () {
    return (
      <div className="AdminMaterialsContainer content container-fluid">
        <Card>
          <CardHeader>
            <StyledCardTitle>Materials</StyledCardTitle>
          </CardHeader>
          <CardBody>
            <DataTable
              search={this.handleSearch.bind(this)}
              cols={[
                {
                  field: 'name', process: (fieldName, project) => (
                    <Link to={`/admin/projects/${project.ownerId}/${project.id}`}>{project.name}</Link>
                  ),
                },
                {field: 'mailingAddress'},
                {field: 'size'},
                {field: 'budget'},
                {field: 'bidStatus'},
                {field: 'status'},
                {field: 'bids',  searchDisabled: true, process: (fieldName, project) => (
                    <span>{project.leadBids ? project.leadBids.length : 0}</span>
                  )},
                {field: 'matches', searchDisabled: true, process: (fieldName, project) => (
                    <span>{project.leadBoughtBy ? project.leadBoughtBy.length : 0}</span>
                  )},

              ]}
              data={this.state.data}
              filters={this.state.filters}
              updateFilters={this.updateFilters.bind(this)}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

AdminMaterialsContainer.propTypes = {};

export default AdminMaterialsContainer;