import React from 'react';
import { Route, Switch } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import classNames from 'classnames';

import routes from '../../routes'

// core components
import Footer from 'components/Footer/Footer';

import SideBanner from '../../components/SideBanner/SideBanner';
import { PeopleService } from '../../services/PeopleService';

var ps;

class PublicLayout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      backgroundColor: 'blue',
    };

    this.peopleService = new PeopleService()
  }

  componentDidMount () {
    if (this.props.location.pathname === '/') {
      this.peopleService.navigateToPageBasedOnRole()
    }

    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel, {suppressScrollX: true});
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }

  componentWillUnmount () {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }

  componentDidUpdate (e) {
    if (e.history.action === 'PUSH') {
      if (navigator.platform.indexOf('Win') > -1) {
        let tables = document.querySelectorAll('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;

    }

    if (['PUSH', 'POP'].includes(e.history.action)) {
      // redirect
      if (this.props.location.pathname === '/') {
        setTimeout(() => this.peopleService.navigateToPageBasedOnRole())
      }
    }
  }

  getRoutes (routes) {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (prop.layout === '/public') {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  render () {
    return (
      <>
        <div className={classNames('PublicLayout wrapper', {
               'no-footer': ['/login'].indexOf(this.props.location.pathname) !== -1,
             })}>
          <div
            className={classNames('main-panel container-fluid p-1', {})}
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <SideBanner/>
            <Switch>
              {this.getRoutes(routes)}
            </Switch>
            {// we don't want the Footer to be rendered on these pages
              ['/login'].indexOf(this.props.location.pathname) !== -1 ? null : (
                <Footer fluid/>
              )}
          </div>
        </div>
      </>
    );
  }
}

export default PublicLayout;
