import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { Col, Row } from 'reactstrap';
import * as _ from 'lodash'
import numeral from 'numeral';
import Link from '../Link';

const StyledDD = styled.dl`
  width: 500px;
  margin-left: 30px;
  
  dt, dd {
    margin: 0;
    padding: 2px 0 2px 10px;
  }
  
  dt {
    font-weight: bold;
    width: 250px;
    font-size: 14px;
  }
  
  dd {
    width: 250px;
  }
  
  
  hr {
    width: 100%;
    height: 1px;
    border-top: 1px solid #ccc;
    margin: 0 0 10px 0;
    padding: 0;
  }
`

const Heading = styled.div`
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 5px;
  color: #444;
`

const StyledFieldSet = styled.fieldset`
  min-height: 220px;
`

class ProjectStats extends Component {

  groupLeadBids() {
    if (!this.props.project)
      return

    let leadBids = this.props.project.leadBids || []
    let groupedLeadBids = _.groupBy(leadBids, (bid) => bid.user.bidOnContractTypeData && bid.user.bidOnContractTypeData.category
      ? `${bid.user.bidOnContractTypeData.category} - ${bid.user.bidOnContractTypeData.title}`
      : _.get(bid, 'user.bidOnContractTypeData.title', 'N/A'))

    // transform object to array
    const groupedLeadBidsArr = _(groupedLeadBids) //wrap object so that you can chain lodash methods
      .mapValues((data, title)=>_.merge({}, {data}, {title}))
      .values() //get the values of the result
      .value() //unwrap array of objects


    return {groupedLeadBids, groupedLeadBidsArr}
  }

  totalAverage(){
    if (!this.props.project)
      return

    let totalAverage = 0
    let {groupedLeadBidsArr} = this.groupLeadBids()

    groupedLeadBidsArr = groupedLeadBidsArr.map((bids) => {
      bids.average = bids.data.reduce((total, bid) => total + parseFloat(bid.bidValue), 0) / bids.data.length
      totalAverage += bids.average
      return bids
    })

    totalAverage = parseFloat(totalAverage / groupedLeadBidsArr.length)

    return totalAverage
  }

  render () {
    let {groupedLeadBids, groupedLeadBidsArr} = this.groupLeadBids()

    return (
      <div id="project-stats" className={`ProjectStats`}>
        <StyledFieldSet className={`custom-fieldset`}>
          <h3 className={`mb-2`}>Construction Estimates Summary</h3>
          {_.size(groupedLeadBids)
            ? <>
              {groupedLeadBidsArr.map((bids, bidsIndex) => (
                <Row key={bidsIndex} className={`pl-4`}>
                  <Col>
                    <Heading>{bids.title} (Total Bids: {bids.data.length})</Heading>
                    <StyledDD>
                      {bids.data.map((bid, bidIndex) => (
                        <div key={bidIndex} className={`row mb-2`}>
                          <dt className={`col-6`}><Link href={`/user/profile/${bid.user.userId}`}>{_.startCase(bid.user.companyName)}</Link></dt><dd className={`col-6`}>{numeral(parseFloat(bid.bidValue)).format('$0,0.00')}</dd>
                        </div>
                      )) || <div className="row"><div className="col-12 text-muted">N/A</div></div>}
                      <div className="row mb-2">
                        <div className="col-12 border-top"></div>
                      </div>
                      <div className={`row bg-light-gray`}>
                        <dt className={`col-6`}>Average </dt><dd className={`col-6`}>{bids.data.length ? numeral(bids.data.reduce((total, bid) => total + parseFloat(bid.bidValue), 0) / bids.data.length).format('$0,0.00') : 'N/A'}</dd>
                      </div>
                    </StyledDD>
                  </Col>
                </Row>
              ))}

              <Row className={`pl-4`}>
                <Col>
                  <Heading>Totals</Heading>
                  <StyledDD>
                    <div className="row mb-2">
                      <dt className="col-6">Total Average Cost:</dt>
                      <dd className="col-6">{numeral(this.totalAverage()).format('$0,0.00')}</dd>
                    </div>

                    <div className="row mb-2">
                      <dt className="col-6">Total Sq.Ft.:</dt>
                      <dd className="col-6">{this.props.project.size} SqFt</dd>
                    </div>
                    <div className="row mb-2">
                      <dt className="col-6">Estimated cost per sq.ft.:</dt>
                      <dd className="col-6">{numeral(this.totalAverage() / this.props.project.size).format('$0,0.00')}</dd>
                    </div>
                  </StyledDD>
                </Col>
              </Row>
            </>
            : <div className={`text-center text-muted`}>There are no bids on this project yet. Once you receive some bids, stats will show here.</div>
          }
        </StyledFieldSet>
      </div>
    );
  }
}

ProjectStats.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectStats;