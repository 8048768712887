import * as _ from 'lodash';

export default class ValidationService {

  static instance() {
    return new ValidationService() // create a singleton with a closure
  }

  getValidationResults(value, rules) {
    let results = []

    if (rules.includes('phone')) {
      results.push({
        type: 'phone',
        isValid: this.validatePhone(value),
        errorMessage: 'Invalid Phone'
      })
    }

    if (rules.includes('email')) {
      results.push({
        type: 'email',
        isValid: this.validateEmail(value),
        errorMessage: 'Invalid Email'
      })
    }

    if (rules.includes('required')) {
      results.push({
        type: 'required',
        isValid: _.size(value) > 0,
        errorMessage: 'Required'
      })
    }

    return results
  }

  validatePhone(value) {
    let cleanValue = value.slice(0).replace(/[^0-9]+/g, '')
    return _.size(cleanValue) === 10
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  getErrorMessages(value, rules){
    let validationResults = this.getValidationResults(value, rules)
    return validationResults.map((result) => {
      return !result.isValid ? result.errorMessage : null
    }).filter((val) => val)
      .join(', ')
  }

  required(value) {
    return typeof value !== 'undefined' && value !== null && value !== ""
      ? undefined :
      'Required'
  }

  email(value) {
    return ValidationService.instance().validateEmail(value) ? undefined : 'Invalid email'
  }

  emptyOrEmail(value) {
    return !value ? undefined : ValidationService.instance().email(value)
  }

}