import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Paper, TextField } from '@material-ui/core';
import { Form, Field } from 'react-final-form'
import ValidationService from '../services/ValidationService';
import * as _ from 'lodash'
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import styled from 'styled-components'
import numeral from 'numeral';

const StyledPaper = styled(Paper)`
  margin: 10px 0 30px;
  padding: 20px;
`

let _form

class BidForm extends Component {
  triggerFormSubmit () {
    document.getElementById('bid-form').dispatchEvent(new Event('submit', {cancelable: true}))
  }

  render () {
    return (
      <div className="BidForm content container-fluid">
        <Row>
          <Col lg={5} md={5} sm={12} className={`m-auto`}>
            <Card>
              <CardHeader>
                <CardTitle tag="h2">Submit Bid</CardTitle>
              </CardHeader>
              <CardBody>
                <StyledPaper elevation={1}>
                  <div><b>Name:</b> {this.props.data.name}</div>
                  <div><b>Description:</b> {this.props.data.description}</div>
                  <div><b>Size:</b> {this.props.data.size}</div>
                  <div><b>Type:</b> {this.props.data.type}</div>
                  <div><b>Has Plans?:</b> {this.props.data.filesCount ? <span className="text-success">Yes</span> : <span
                    className="text-danger">No</span>}</div>
                  <div><b>Previous Bid Amount:</b> {numeral(parseFloat(this.props.data.previousBidAmount))
                    .format('$0,0.00')}</div>
                </StyledPaper>
                <Form
                  onSubmit={this.props.onSubmit.bind(this, _form)}
                  render={({form, handleSubmit}) => {
                    _form = form
                    return (
                      <form onSubmit={handleSubmit} id={`bid-form`}>
                        <Row>
                          <Field
                            name="bidAmount"
                            validate={ValidationService.instance().required}
                          >
                            {({input, meta}) => (
                              <Col sm={12} md={6}>
                                <TextField
                                  {...input}
                                  label={_.startCase(input.name)}
                                  variant={'outlined'}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    min: 1,
                                  }}
                                  fullWidth={true}
                                  error={meta.error && meta.touched}
                                  helperText={meta.error && meta.touched && meta.error}
                                  type={`number`}
                                  placeholder={`Type your bid amount here`}
                                  disabled={parseFloat(this.props.data.previousBidAmount) > 0}
                                />
                              </Col>
                            )}
                          </Field>
                        </Row>

                        <Row>
                          <Field
                            name="bidTerms"
                            validate={ValidationService.instance().required}
                          >
                            {({input, meta}) => (
                              <Col sm={12}>
                                <TextField
                                  {...input}
                                  label={_.startCase(input.name)}
                                  variant={'outlined'}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth={true}
                                  error={meta.error && meta.touched}
                                  helperText={meta.error && meta.touched && meta.error}
                                  placeholder={`Type your terms for this bid`}
                                  disabled={parseFloat(this.props.data.previousBidAmount) > 0}
                                />
                              </Col>
                            )}
                          </Field>
                        </Row>

                        <Row>
                          <Field
                            name="specificationsDivision"
                            validate={ValidationService.instance().required}
                          >
                            {({input, meta}) => (
                              <Col sm={12}>
                                <TextField
                                  {...input}
                                  label={_.startCase(input.name)}
                                  variant={'outlined'}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth={true}
                                  error={meta.error && meta.touched}
                                  helperText={meta.error && meta.touched && meta.error}
                                  placeholder={`Type your specifications/division here`}
                                  disabled={parseFloat(this.props.data.previousBidAmount) > 0}
                                />
                              </Col>
                            )}
                          </Field>
                        </Row>

                      </form>
                    )
                  }}
                />
              </CardBody>
              <CardFooter className={`border-top`}>
                {parseFloat(this.props.data.previousBidAmount) > 0
                ? <span>You already submitted a bid for this project. Only 1 bid allowed.</span>
                : null}
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={() => this.triggerFormSubmit()}
                  className={`pull-right`}
                  disabled={parseFloat(this.props.data.previousBidAmount) > 0}
                >
                  {this.props.submitLabel}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

BidForm.defaultProps = {
  submitLabel: 'Submit',
}

BidForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default BidForm;