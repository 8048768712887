import { ModelService } from './ModelService';
import * as _ from 'lodash';
import environment from '../environments/environment';
import { NotificationService } from './NotificationService';
import { FILE } from '../components/FileSystem/utils/constants';

export class ContainersService extends ModelService {

  constructor () {
    super();
    this.modelName = 'Containers'
    this.notificationService = new NotificationService()
  }

  getFilesFrom (containerName) {
    return this.apiService.go('/' + this.modelName + '/' + containerName + '/files', {
      method: 'GET',
    });
  }

  deleteFileFrom (containerName, fileName, config = {}) {
    return this.apiService.go('/' + this.modelName + '/' + containerName + '/files/' + fileName, {
      method: 'DELETE',
      ...config
    });
  }

  deleteContainer (containerName) {
    return this.apiService.go('/' + this.modelName + '/' + containerName, {
      method: 'DELETE',
    });
  }

  async uploadFiles (containerName, fileList) {
    if (!fileList || !fileList.length)
      return

    try {
      await this.apiService.go(`/containers/${containerName}`, {
        method: 'GET',
      })
    } catch (resp) {
      if (resp.status === 404) { // create the container if it doesn't exist
        await this.apiService.go(`/containers`, {
          method: 'POST',
          body  : {
            name: containerName,
          },
        })
      } else {
        return this.notificationService.error(
          'An error occurred. Our tech team has been alerted and will get to work on this issue shortly.');
      }
    }

    let formData = new FormData(),
        filesToUpload = 0
    if (fileList.length) {
      for (let x = 0; x < fileList.length; x++) {
        if (fileList[x].type !== FILE)
          continue;
        formData.append('file' + x, fileList[x].rawFileData);
        filesToUpload++
      }
    }

    if (!filesToUpload)
      return

    try {
      return await fetch(`${_.trimEnd(environment.apiUrl, '/')}/containers/${containerName}/upload`, {
        method: 'POST',
        body  : formData,
      })
    } catch (e) {
      this.notificationService.error(
        'An error occurred. Our tech team has been alerted and will get to work on this issue shortly.')
    }
  }
}
