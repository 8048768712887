import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap';
import { SuccessButton } from '../Buttons';
import { PeopleService } from '../../services/PeopleService';
import { NotificationService } from '../../services/NotificationService';
const qs = require('query-string')

class VerifyEmail extends Component {

  constructor (props) {
    super(props);
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    let email = atob(qs.parse(window.location.search).e)
    this.peopleService.verifyEmail(email)
  }

  render () {
    return (
      <div className="ConfirmAccountCreation container-fluid mt-5 ">
        <Row className="h-100 align-items-center">
          <Col sm="12" md="4" className="m-auto">
            <Card>
              <CardTitle tag="h2" className={`p-3 border-bottom`}>
                Verify Email
              </CardTitle>
              <CardBody>
                <p>
                  Thank you for verifying your email.
                </p>
              </CardBody>
              <CardFooter>
                <SuccessButton color={'primary'} variant={'contained'} onClick={() => window.app.history.push('/login')}>
                  Login
                </SuccessButton>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>

    );
  }
}

VerifyEmail.propTypes = {};

export default VerifyEmail;