import React, { Component } from 'react';
import EditProjectContainer from '../../components/Project/EditProjectContainer';
import { AdminService } from '../../services/AdminService';

class AdminEditProjectContainer extends Component {
  constructor (props) {
    super(props)
    this.adminService = new AdminService()
  }

  render () {
    return (
      <EditProjectContainer
        {...this.props}
        getData={async (self) => {
          let resp = await this.adminService.getProjectByUser(self.props.match.params.userId, self.props.match.params.id)

          let containerName = `${self.props.match.params.userId}-${self.props.match.params.id}`
          let filesResp = await self.containersService.getFilesFrom(containerName)
          self.setState({...resp.parsed.data, files: filesResp.parsed.data})
        }}
        projectPatchFn={async (self, formValues) => {
          let projectResp = await this.adminService.updateProjectByUser(self.props.match.params.userId, formValues.id, formValues)
          return projectResp
        }}
        onDelete={async (self) => {
          try {
            let resp = await this.adminService.deleteProject(self.state.id)
            if (resp.status >= 200 && resp.status < 300) {
              self.notificationService.success('Project deleted!')
              window.app.history.navigateByUrl('/admin/projects')
            }
          } catch (err) {
            self.notificationService.error('Error deleting project')
          }
        }}
      />
    );
  }
}

export default AdminEditProjectContainer;