import React from 'react'
// import PropTypes from 'prop-types'
import classNames from 'classnames'

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";
import Link from '../Link';

class SideBanner extends React.Component {

  render(){
    return (
      <div className={classNames('SideBanner container', this.props.classname)}>
        <Row className="mb-2 house-icon-wrapper">
          <Col className="house-icon" onClick={() => window.app.history.push('/')}>
            <img src={require("assets/img/house-icon.png")} alt="Go to homepage"/>
          </Col>
        </Row>
        <Row className="yellow-row d-flex align-items-end">
          <Col sm={12}>
            <Row className="p-2">
              <Col sm={12} className="banner-wrapper d-flex align-items-end">
                <img src={require("assets/img/prismbid-vertical.png")} alt="PrimsBid logo"/>
              </Col>
            </Row>

            <hr className="mt-2 mb-0"/>

            <Row className="p-2">
              <Col sm={12} className="link-wrapper p-0">
                <Link href="/terms">Terms</Link> | <Link href="/privacy">Privacy</Link>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Link href="/support" className="support-link">Support</Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

// SideBanner.propTypes = {
// }

export default SideBanner