import { ApiService } from './ApiService';
import { StorageService } from './StorageService';
import * as _ from 'lodash';
import pluralize from 'pluralize'

export class ModelService {

  constructor () {
    this.modelName = ''
    this.state = {}
    this.apiService = new ApiService()
    this.storageService = new StorageService()
    this.onInit();
  }

  onInit () {
    // used to setup variables on child classes after the parent has been init
  }

  getPublicModelNamePath() {
    return `/${this.modelName}`
  }

  getAuthModelNamePath () {
    return this.getAuthPath() + '/' +  pluralize.singular(this.modelName);
  }

  getAuthPath() {
    return '/People/' + this.storageService.get('user.data.id')
  }

  patch (data) {
    let optionalId = _.get(data, 'id', false);
    return this.apiService.go(this.getAuthModelNamePath() + (optionalId ? '/' + optionalId : ''), {
      method: optionalId ? 'PUT' : 'POST', // models in API should have "replaceOnPUT": true
      body  : data,
    });
  }

  update (id, data) {
    return this.apiService.go(this.getAuthModelNamePath() + '/' + id, {

      method: 'PUT', // models in API should have "replaceOnPUT": true
      body  : data,
    });
  }

  post (data) {
    return this.apiService.go(this.getAuthModelNamePath(), {
      method: 'POST',
      body  : data,
    });
  }

  delete (id) {
    return this.apiService.go(this.getAuthModelNamePath() + '/' + id, {
      method: 'DELETE',
    });
  }

  count (mergedParams) {
    mergedParams = _.merge({}, mergedParams, {filter: {}})
    let filter = '';

    if (!_.isUndefined(mergedParams.filter.userType) && mergedParams.filter.userType !== '') {
      filter = `${filter}&filter[where][userType]=${mergedParams.filter.userType}`;
    }

    if (mergedParams.filter.firstName && mergedParams.filter.firstName !== '') {
      const pattern = new RegExp('.*' + mergedParams.filter.firstName + '.*', 'i');
      filter = `${filter}&filter[where][firstName][regexp]=${pattern}`;
    }

    if (mergedParams.filter.lastName && mergedParams.filter.lastName !== '') {
      const pattern = new RegExp('.*' + mergedParams.filter.lastName + '.*', 'i');
      filter = `${filter}&filter[where][lastName][regexp]=${pattern}`;
    }

    if (mergedParams.filter.email && mergedParams.filter.email !== '') {
      const pattern = new RegExp('.*' + mergedParams.filter.email + '.*', 'i');
      filter = `${filter}&filter[where][email][regexp]=${pattern}`;
    }

    if (mergedParams.filter.phone && mergedParams.filter.phone !== '') {
      const pattern = new RegExp('.*' + mergedParams.filter.phone + '.*', 'i');
      filter = `${filter}&filter[where][phone][regexp]=${pattern}`;
    }

    return this.apiService.go(this.getAuthModelNamePath() + '/count' + filter, {
      method: 'GET',
    });
  }

  view (id) {
    return this.apiService.go(this.getAuthModelNamePath() + '/' + id, {
      method: 'GET',
    });
  }

  prepareFilter (params) {
    let paramsDefault = {filter: {skip: 0, page: 1, limit: 20}};
    let mergedParams = _.merge(paramsDefault, params);
    if (_.isUndefined(_.get(params, 'filter.skip', undefined)) &&
      !_.isUndefined(_.get(params, 'filter.page', undefined))) {
      mergedParams.filter.skip = (params.filter.page - 1) * mergedParams.filter.limit;
    }

    let filter = `filter[skip]=${mergedParams.filter.skip}`;

    if (mergedParams.filter.limit) {
      filter = `${filter}&filter[limit]=${mergedParams.filter.limit}`;
    }

    let ignoredKeys = ['limit', 'skip', 'page'];
    _.each(mergedParams.filter, (v, k) => {
      if (ignoredKeys.includes(k)) {
        return;
      }

      if (_.size(v)) {
        const pattern = new RegExp('.*' + v.replace(/[^0-9a-z\-_ .,<>]+/, '') + '.*', 'i');
        filter = `${filter}&filter[where][${k}][regexp]=${pattern}`;
      }
    });

    return filter;
  }

  /**
   * Gets all the rows for a particular model. This method is paginated
   * If you pass `find({filter: {page: 1}})` it will automatically calculate the `skip` value.
   * If you pass `skip` and `page`, `skip` has priority over page because it's more specific
   *
   * @param params
   * @returns {Promise<T>}
   */
  find (params) {
    let filter = this.prepareFilter(params);

    return this.apiService.go(this.getAuthModelNamePath() + `?${filter}`, {
      method: 'GET',
    });
  }

}
