import React, { Component } from 'react';
import styled from 'styled-components';
import { Card, CardBody, Row, Col, Container } from 'reactstrap';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import * as _ from 'lodash'

import Contacts from './Contacts';
import { MessageService } from '../../services/MessageService';
import ActiveConversation from './ActiveConversation';
import Conversations from './Conversations';
import { PeopleService } from '../../services/PeopleService';

const StyledMessageCenter = styled('div')`
  overflow: hidden;
`
const LeftCol = styled(Col)`
  border-right: 1px solid #c1c1c1;
  background: #fcfcfc;
  min-height: 500px;
  overflow: auto;
`
const RightCol = styled(Col)`
  background: #fcfcfc;
`

const NewMessageButton = styled(Button)`
  span {
    font-size: 18px;  
  }
`

class MessageCenter extends Component {

  constructor (props) {
    super(props)

    this.state = {
      showContacts : false,
      activeConversation: null,
      conversations: [],
      conversationSearchValue: ''
    }

    this.messageService = new MessageService()
    this.peopleService = new PeopleService()
  }

  componentDidMount () {
    this.getConversations()
    this.pollGetConversations()
  }

  componentWillUnmount () {
    clearInterval(this.pollInterval)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.conversations, this.state.conversations)) {
      // update the active conversation
      if (this.state.conversations.length && this.state.activeConversation) {
        const activeConversation = this.state.conversations.filter((conv) => conv.contactUserId === this.state.activeConversation.contactUserId)[0]
        this.setState({activeConversation})
      }
    }
  }

  pollGetConversations(){
    this.pollInterval = setInterval(() => this.getConversations(), 1000)
  }

  async getConversations() {
    const conversationsResp = await this.messageService.getConversations()
    this.setState({conversations: conversationsResp.parsed.data})
  }

  async sendMessage(body) {
    let messageResp = await this.messageService.sendMessage(this.state.activeConversation.contactUserId, body)
    let messages = this.state.activeConversation.messages.slice(0)
    messages.push(messageResp.parsed.data)

    this.setState({
      activeConversation: {
        ...this.state.activeConversation,
        messages
      }
    })
  }

  createTempConversation(contact){
    return {
      ...contact,
      from: contact,
      lastMessageSentAt: new Date(),
      messages: []
    }
  }

  handleContactSelected(contact) {

    // check if we already have a conversation with this contact
    const currentConversation = this.state.conversations.filter((conv) => conv.contactUserId === contact.contactUserId)[0]

    if (!currentConversation) {
      this.setState({
        activeConversation: this.createTempConversation(contact),
        conversations: [
          ...this.state.conversations,
          this.createTempConversation(contact)
        ]
      })
    } else {
      this.setState({
        activeConversation: currentConversation
      })
    }
  }

  getFilteredConversations(){
    if (this.state.conversationSearchValue.length) {
      return this.state.conversations.filter((c) =>
        c.firstName.includes(this.state.conversationSearchValue)
        || c.lastName.includes(this.state.conversationSearchValue)
        || c.companyName.includes(this.state.conversationSearchValue)
      )
    } else
      return this.state.conversations
  }

  render () {
    return (
      <StyledMessageCenter className="container">
        <Card>
          <CardBody className="p-0 border">
            <Container className="p-0">
              <Row>
                <LeftCol sm="4">
                  <Contacts
                    isOpen={this.state.showContacts}
                    onClose={() => this.setState({showContacts: false})}
                    onContactSelected={(contact) => this.handleContactSelected(contact)}
                  />
                  <Container className="p-0">
                    <Row className="pt-3 pb-3 pr-3 border-bottom">
                      <Col sm="8"><h2 className="p-0 m-0">Messages</h2></Col>
                      <Col sm="4" className="text-right pr-0">
                        <NewMessageButton
                          type="button"
                          color="default"
                          onClick={() => {
                            this.setState({showContacts: true})
                          }}
                        >
                          <span className="fa fa-edit"/>
                        </NewMessageButton>
                      </Col>
                    </Row>
                    <Row className="pt-3 pb-0 border-bottom">
                      <Col>
                        <TextField
                          placeholder="Search"
                          variant={'outlined'}
                          fullWidth={true}
                          size={'small'}
                          onChange={(e) => this.setState({conversationSearchValue: e.target.value})}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Conversations
                          data={this.getFilteredConversations()}
                          activeConversation={this.state.activeConversation}
                          onSelectConversation={(activeConversation) => this.setState({activeConversation})}
                        />
                      </Col>
                    </Row>
                  </Container>
                </LeftCol>
                <RightCol className="p-0">
                  <ActiveConversation
                    data={this.state.activeConversation}
                    onSend={(value) => this.sendMessage(value)}
                  />
                </RightCol>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </StyledMessageCenter>
    );
  }
}

export default MessageCenter;