import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class Pager extends Component {

  createPages() {
    return this.getPages().map((page, i) => (
      <PaginationItem key={i} active={this.props.page === page}>
        <PaginationLink href="#" onClick={this.onPage.bind(this, page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    ))
  }

  getMin(){
    return ((this.props.perPage * this.props.page) - this.props.perPage) + 1;
  }

  getMax(){
    let max = this.props.perPage * this.props.page;
    if (max > this.props.count) {
      max = this.props.count;
    }
    return max;
  }

  onPage(n){
    this.props.onChange(n)
  }

  onPrev(){
    if (!this.totalPages())
      return
    this.props.onChange(this.props.page - 1 > 0 ? this.props.page - 1 : 1)
  }

  onNext(){
    if (!this.totalPages())
      return
    this.props.onChange(this.props.page + 1 <= this.totalPages() ? this.props.page + 1 : this.totalPages())
  }

  totalPages(){
    return Math.ceil(this.props.count / this.props.perPage) || 0;
  }

  lastPage(){
    return this.props.perPage * this.props.page > this.props.count;
  }

  getPages(){
    const c = Math.ceil(this.props.count / this.props.perPage);
    const p = this.props.page || 1;
    const pagesToShow = this.props.pagesToShow || 9;
    const pages = [];
    pages.push(p);
    const times = pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < pagesToShow) {
        if (Math.max.apply(null, pages) < c) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }

  render () {
    return (
      <Pagination className={this.props.className}>
        <PaginationLink href="#" onClick={this.onPrev.bind(this)}>
          Prev
        </PaginationLink>

        {this.createPages()}

        <PaginationLink href="#" onClick={this.onNext.bind(this)}>
          Next
        </PaginationLink>
      </Pagination>
    );
  }
}

Pager.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  pagesToShow: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Pager;