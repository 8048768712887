import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { Button, Checkbox, Radio, InputAdornment, Paper, TextField } from '@material-ui/core';
import classNames from 'classnames'
import * as _ from 'lodash'
import Tabs from '../Tabs';
import { FaTimes } from 'react-icons/fa';
import appStore from '../../AppStore';

class Assets extends Component {

  constructor (props) {
    super(props)

    this.state = {
      activeTab     : props.tab,
      subcontractors: appStore.getState().subcontractors,
      materials     : appStore.getState().materials,
      services      : appStore.getState().services,
      search        : '',
      selectedAssets: JSON.parse(JSON.stringify(this.props.selectedAssets)), // copy it so we can send it later
    }
  }

  componentDidMount () {
    this.unsubscribe = appStore.subscribe(() => {
      this.setState({
        subcontractors: appStore.getState().subcontractors,
        materials: appStore.getState().materials,
        services: appStore.getState().services,
      })
    })
  }

  componentWillUnmount () {
    this.unsubscribe()
  }

  handleAssetChange (e) {
    // make it read only if we're not allowing the user to add to project
    if (!this.props.onAddToProject)
      return

    let selectedAssets = JSON.parse(JSON.stringify(this.state.selectedAssets)) // copy
    selectedAssets = selectedAssets.filter((sub) => sub !== e.target.value) // filter it out
    if (e.target.checked) {
      if (this.props.type === 'checkbox') {
        selectedAssets.push(e.target.value) // add it back
      } else {
        selectedAssets = [e.target.value] // radio
      }
    }

    this.setState({selectedAssets})
  }

  handleSearch (e) {
    this.setState({search: e.target.value})
  }

  handleTabChange (activeTab, e) {
    e.preventDefault()
    this.setState({activeTab})
  }

  getTabData () {
    switch (this.state.activeTab) {
      default:
      case 0:
        return this.state.subcontractors || []
      case 1:
        return this.state.materials
      case 2:
        return this.state.services
    }
  }

  getTotalAssetCount () {
    let total = 0

    if (!this.props.showCount)
      return null

    this.state.subcontractors.map((cat) => {
      return total += cat.length
    })
    this.state.materials.map((cat) => {
      return total += cat.length
    })
    this.state.services.map((cat) => {
      return total += cat.length
    })

    if (total < 100) {
      return ''
    } else if (total >= 100 && total < 1000) {
      return '100+ Available'
    } else {
      return '1000+ Available'
    }
  }

  handleAddToProject () {
    this.props.onAddToProject && this.props.onAddToProject(this.state.selectedAssets, this.state.activeTab)
    this.props.onClose()
  }

  selectAll(){
    let selectedAssets = []
    this.getTabData().map((r) =>
      r.data.map((rr) =>
        selectedAssets.push(rr.id)
      )
    )

    this.setState({selectedAssets})
  }

  selectNone(){
    this.setState({selectedAssets: []})
  }

  render () {
    return (
      <div className={classNames(`Assets`, this.props.className)}>
        <Paper square elevation={10}>
          <CardHeader>
            <h2 className={'p-0 m-0'}>{this.props.formTitle}</h2>
            {/*<div className={`pb-2`}>*/}
            {/*  <small>{this.getTotalAssetCount()}</small>*/}
            {/*</div>*/}
            {this.props.onClose ? <FaTimes className={`close-btn`} onClick={() => this.props.onClose()}/> : null}
          </CardHeader>
          <CardBody className={`pt-0`}>
            <Row>
              <Tabs
                tabs={['subcontractors', 'materials', 'services']}
                activeTab={this.state.activeTab}
                showOnlyActiveTab={true}
                onChange={this.handleTabChange.bind(this)}
              />
            </Row>
            <Row>
              <Col sm={12}>
                <TextField
                  className={'search-bar'}
                  placeholder={`Search by name`}
                  variant={'outlined'}
                  fullWidth={true}
                  onChange={this.handleSearch.bind(this)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="fa fa-search"></span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col className={`text-center text-muted`}>
                <div><small><b>Select to add to project:</b></small></div>
                <div>
                  {this.props.type === 'checkbox' ?
                  <>
                    <Button variant={'text'} color={`primary`} size={'small'} onClick={this.selectAll.bind(this)}>All</Button>
                    <Button variant={'text'} color={`primary`} size={'small'} onClick={this.selectNone.bind(this)}>None</Button>
                  </>
                  : null}
                </div>
              </Col>
            </Row>

            <div className="results-container">
              <div className="results-wrapper">

                {(() => {
                  let results = this.getTabData().filter((cat) => {
                    if (cat.category.toLowerCase().includes(this.state.search.toLowerCase()))
                      return true

                    for (let i = 0; i < cat.data.length; i++) {
                      if (cat.data[i].title.toLowerCase().includes(this.state.search.toLowerCase()))
                        return true
                    }

                    return false
                  })

                  return !results.length
                    ? <div className="p-2 text-center text-muted font-weight-bold">0 results found</div>
                    : results.map((cat, catKey) => (
                      <Row key={catKey}>
                        <Col>
                          <Row className={`pb-2`}>
                            <Col>
                              <small className="text-muted font-weight-bold">{_.upperCase(cat.category)}</small>
                            </Col>
                          </Row>
                          {cat.data.filter((data) => (
                            data.title.toLowerCase().includes(this.state.search.toLowerCase()) ||
                            data.category.toLowerCase().includes(this.state.search.toLowerCase())
                          )).map((catData, catDataKey) => (
                            <Row key={catDataKey} className={`pb-2`}>
                              <Col sm={2}>
                                {this.props.type === 'checkbox'
                                  ? <Checkbox
                                    className={classNames({'pt-0': !this.props.showCount})}
                                    value={catData.id}
                                    checked={this.state.selectedAssets.includes(catData.id)}
                                    onChange={this.handleAssetChange.bind(this)}
                                  />
                                  : <Radio
                                    className={classNames({'pt-0': !this.props.showCount})}
                                    value={catData.id}
                                    checked={this.state.selectedAssets.includes(catData.id)}
                                    onChange={this.handleAssetChange.bind(this)}
                                  />
                                }
                              </Col>
                              <Col sm={10}>
                                <div className="font-weight-bold">{_.upperFirst(catData.title)}</div>
                                {this.props.showCount
                                  ? <small>{catData.count > 100 ? '100+' : '<100'} contractors available</small>
                                  : null}
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    ))
                })()}
              </div>
              <div className="results-shadow"></div>
            </div>
          </CardBody>
          {this.props.onAddToProject
            ? <CardFooter className={`text-center pb-4`}>
              <Button variant={'outlined'} color={'primary'} className={`add-to-project`}
                      onClick={this.handleAddToProject.bind(this)}>
                <span className="fa fa-angle-right pr-2"></span>
                <span>{this.props.buttonLabel.toUpperCase()}</span>
              </Button>
            </CardFooter>
            : null}
        </Paper>
      </div>
    );
  }
}

Assets.defaultProps = {
  formTitle     : 'Project Assets',
  buttonLabel   : 'Add to Project',
  onAddToProject: null,
  tab           : 0,
  selectedAssets: [],
  type          : 'checkbox',
  showCount     : false,
}

Assets.propTypes = {
  selectedAssets: PropTypes.array,
  onAddToProject: PropTypes.func,
  onClose       : PropTypes.func,
  className     : PropTypes.string,
  tab           : PropTypes.number.isRequired,
  type          : PropTypes.string,
  formTitle     : PropTypes.string,
  buttonLabel   : PropTypes.string,
  showCount     : PropTypes.bool,
};

export default Assets;