import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import moment from 'moment'
import { PeopleService } from '../../services/PeopleService';
import { AdminService } from '../../services/AdminService';
import Link from '../../components/Link';

class AdminBidsSentContainer extends Component {
  constructor (props) {
    super(props);
    this.peopleService = new PeopleService()
    this.adminService = new AdminService()
    this.state = {
      projectsBidOn: null
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.data.id && this.state.projectsBidOn === null)
      this.getData()
  }

  async getData() {
    const projectsBidOnResp = await this.adminService.getBidProjectsByUser(this.props.data.id, {filter: null})
    this.setState({projectsBidOn: projectsBidOnResp.data})
  }

  render () {
    return (
      <div className="AdminBidsSentContainer content container-fluid">
        <Row>
          <Col lg={7} md={7} sm={12} className={`m-auto`}>
            <Card>
              <CardHeader className={`border-bottom mb-3`}>
                <CardTitle tag="h2">Bids Sent</CardTitle>
              </CardHeader>
              <CardBody className="pt-0">
                <table className="table">
                  <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Project Address</th>
                    <th>Owner</th>
                    <th>Bid Amount</th>
                    <th>Bid Status</th>
                    <th>Sent on</th>
                  </tr>
                  </thead>
                  <tbody>
                  {Array.isArray(this.state.projectsBidOn) && this.state.projectsBidOn.length ? this.state.projectsBidOn.map((project, k) => {
                    const bidsByThisUser = project.leadBids.filter((bid) => bid.id === this.props.data.id)
                    return bidsByThisUser.map((bid, kk) => (
                      <tr key={k+'-'+kk}>
                        <td><Link href={`/admin/projects/${project.ownerId}/${project.id}`}>{project.name}</Link></td>
                        <td>{project.address}</td>
                        <td><Link href={`/admin/user/${project.ownerId}`}>{project.owner.firstName} {project.owner.lastName}</Link></td>
                        <td>${bid.bidValue}</td>
                        <td>{bid.status}</td>
                        <td>{moment(bid.createdAt).format('D/M/YY')}</td>
                      </tr>
                    ))
                  }): <tr><td colSpan="6">N/A</td></tr>}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

AdminBidsSentContainer.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AdminBidsSentContainer;