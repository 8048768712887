/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import { Container, Row, Nav, NavItem } from "reactstrap";
import Link from '../Link';

class Footer extends React.Component {
  render() {
    return (
      <footer id="footer" className="footer">
        <Container fluid>
          <div className="row">
            <div className="col-6">
              <div className="container">
                <div className="row">
                  <Nav>
                    <NavItem>
                      <Link href="/support">Support</Link>
                    </NavItem>
                    <NavItem>
                      <a href="#" onClick={(e) => e.preventDefault()}>Help Desk</a>
                    </NavItem>
                    <NavItem>
                      <a href="#" onClick={(e) => e.preventDefault()}>Advertise with us</a>
                    </NavItem>
                    <NavItem>
                      <Link href="/terms">Terms</Link>
                    </NavItem>
                    <NavItem>
                      <Link href="/privacy">Privacy</Link>
                    </NavItem>
                  </Nav>
                </div>
                <div className="row">
                  <div className="copyright">
                    © {new Date().getFullYear()} PrismBid. All Rights Reserved.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 text-right pr-5">
              <img src={require('assets/img/prismbid-horizontal.png')} alt="PrismBid logo"/>
            </div>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
