import FileSystemStore from './FileSystemStore';
import { FILE, FOLDER } from './utils/constants';
import moment from 'moment';

export class FileSystemService {

  static instance() {
    return new FileSystemService()
  }

  getParentFolder(currentEntry) {
    return FileSystemStore.getState().entries.length ? FileSystemStore.getState().entries
      .filter((entry) => entry.type === FOLDER && entry.path === currentEntry.parentPath)[0] || {path: '/', parentPath: null}
      : {path: '/', parentPath: null}
  }

  setCurrentEntry(entry){
    FileSystemStore.dispatch({
      type: 'set',
      state: {
        ...FileSystemStore.getState(),
        entry
      }
    })
  }

  deleteEntry(entryToDelete) {
    FileSystemStore.dispatch({
      type: 'set',
      state: {
        ...FileSystemStore.getState(),
        entries: [
          ...FileSystemStore.getState().entries.filter((entry) => entry.path !== entryToDelete.path),
        ]
      }
    })
  }

  fileDataToFileSystem(file) {
    return {
      type       : FILE,
      date       : moment().format('YYYY-MM-DD'),
      creatorName: FileSystemStore.getState().creatorName,
      parentPath : FileSystemStore.getState().entry.parentPath || '/',
      path       : file.path ? file.path : FileSystemStore.getState().entry.path +
        (FileSystemStore.getState().entry.path !== '/' ? '/' : '') + file.name,
      name       : file.name,
      size       : file.size,
      rawFileData: file
    }
  }

}