import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import * as _ from 'lodash'
import styled from 'styled-components';

export const StyledSearchBox = styled.input`
  padding: 5px 10px;
`

class DataTable extends Component {
  constructor (props) {
    super(props)
    this.debouncedSearch = _.debounce(this.search, 250)
  }

  async search (col, searchValue) {
    if (_.isFunction(this.props.search))
      await this.props.search(col, searchValue)
  }

  render () {
    return (
      <Table responsive>
        <thead className="text-primary">
        <tr className="border-bottom">
          {this.props.cols.map((field, k) => (
            <th key={k}>{field.label || _.startCase(field.field)}</th>
          ))}
        </tr>
        <tr>
          {this.props.cols.map((col, colIndex) =>
            col.searchDisabled
              ? <th key={colIndex}></th>
              : <th key={colIndex}>
                {col.renderSearch
                  ? col.renderSearch(this, col)
                  : <StyledSearchBox
                    type={col.searchType || 'text'}
                    placeholder={'Search...'}
                    onChange={(e) => {
                      this.props.updateFilters(col, e.target.value)
                      this.debouncedSearch()
                    }}
                    value={_.get(this.props.filters, col.field, '')}
                  />}

              </th>,
          )}
        </tr>
        </thead>
        <tbody>
        {this.props.data.length
          ? this.props.data.map((row, k) => {
            return (
              <tr key={k}>
                {this.props.cols.map((field, k) => (
                  <td key={k}>
                    {_.isFunction(field.process)
                      ? field.process(_.get(row, field.name, null), row)
                      : _.get(row, field.field, null)}
                  </td>
                ))}
              </tr>
            )
          })
          : <tr>
            <td className={`text-center`} colSpan={this.props.cols.length}>{this.props.emptyDataMessage}</td>
          </tr>
        }
        </tbody>
      </Table>
    );
  }
}

DataTable.defaultProps = {
  data            : [],
  emptyDataMessage: 'No available records.',
}

DataTable.propTypes = {
  data            : PropTypes.array.isRequired,
  cols            : PropTypes.array.isRequired,
  emptyDataMessage: PropTypes.string,
  search          : PropTypes.func,
  filters         : PropTypes.object,
  updateFilters   : PropTypes.func.isRequired,
};

export default DataTable;