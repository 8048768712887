import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AddProjectContainer from '../../components/Project/AddProjectContainer';
import BidContainer from '../../views/BidContainer';
import ViewProjectContainer from '../../components/Project/ViewProjectContainer';
import ArchitectDashboard from '../../views/ArchitectDashboard';
import { PeopleService } from '../../services/PeopleService';

class ArchitectLayout extends React.Component {
  componentDidMount () {
    if (!['approved', 'temp_approved'].includes(PeopleService.instance().me('status'))){
      window.app.history.push('/review-account')
    }
  }

  render () {
    return (
      <div className={`ArchitectLayout`}>
        <Switch>
          <Route path={`/architect/dashboard`} component={ArchitectDashboard}/>
          <Route path={`/architect/projects/add`} component={AddProjectContainer}/>
          <Route path={`/architect/bid/:projectId`} component={BidContainer}/>
          <Route path={`/architect/projects/:projectId`} render={(props) => (
            <ViewProjectContainer {...props} footerLinks={[
              {
                url: '/architect/bid/:projectId',
                label: 'Submit Estimate'
              }
            ]}/>
          )}/>
        </Switch>
      </div>
    );
  }
}

export default ArchitectLayout;
