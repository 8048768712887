import * as _ from 'lodash';

export class StorageService {

  constructor (){
    this.prefix = 'PB';
    this.ls = window.localStorage || {};
  }

  static instance() {
    return new StorageService()
  }

  set (key, val) {
    let obj = JSON.parse(this.ls.getItem(this.prefix)) || {};
    _.set(obj, key, val);
    this.ls.setItem(this.prefix, JSON.stringify(obj));
  }

  get (keyPath) {
    if (!keyPath || !_.size(keyPath))
      return JSON.parse(this.ls.getItem(this.prefix));
    else
      return _.get(JSON.parse(this.ls.getItem(this.prefix)), keyPath, null);
  };

  delete(keyPath) {
    let obj = JSON.parse(this.ls.getItem(this.prefix)) || {};
    _.unset(obj, keyPath);
    this.ls.setItem(this.prefix, JSON.stringify(obj));
  };

}