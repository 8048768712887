import appStore from '../AppStore';

export class LoadingService {
  constructor () {
    appStore.subscribe(() => {
      appStore.getState().isLoading ? this.show() : this.hide()
    })
  }

  show() {

  }

  hide() {

  }
}