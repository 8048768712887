const FILE = '__file__';
const FOLDER = '__folder__';

const ADD_ENTRY = '__add_entry__';
const DELETE_ENTRY = '__delete_entry__';

const LOCAL = '__local__';
const GLOBAL = '__global__';

export { FILE, FOLDER, ADD_ENTRY, DELETE_ENTRY, LOCAL, GLOBAL };
