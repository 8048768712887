import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap';
import { ProjectsService } from '../services/ProjectsService';
import { SuccessButton } from './Buttons';
import { NotificationService } from '../services/NotificationService';
import { PeopleService } from '../services/PeopleService';
import { StorageService } from '../services/StorageService';

class ProjectInvite extends Component {

  constructor (props) {
    super(props);
    this.state = {
      error: null,
      errorMsg: null,
    }
    this.projectsService = new ProjectsService()
    this.notificationService = new NotificationService()
    this.peopleService = new PeopleService()
    this.storageService = new StorageService()
  }

  componentDidMount () {
    if (this.isUserLoggedIn())
      this.getData()
    else {
      this.setState({
        errorMsg: 'You have to login before accepting the invite.'
      })
    }
  }

  async getData(){
    let resp = await this.projectsService.getInvitedProject(this.props.match.params.projectId)

    if (resp.status === 200){
      this.setState(resp.parsed.data)
    }
    else {
      let errorMsg = 'There was an error loading the project you requested. Please make sure the link you were provided is valid.'
      this.setState({errorMsg})
      this.notificationService.error(errorMsg)
    }
  }

  isUserLoggedIn(){
    return !!this.peopleService.me('userId')
  }

  async acceptInvite(){
    try {
      await this.projectsService.acceptInvite(this.storageService.get('user.data.id'), this.state.id)
      await this.peopleService.navigateToPageBasedOnRole()
    } catch(err) {
      // stay in the page
    }
  }

  render () {
    return (
      <div className="ProjectInvite container-fluid mt-5 ">
        <Row className="h-100 align-items-center">
          <Col sm="12" md="4" className="m-auto">
            <Card>
              <CardTitle tag="h2" className={`p-3 border-bottom`}>
                {!this.state.errorMsg ? 'Congratulations' : 'Error'}
              </CardTitle>
              <CardBody>
                {!this.state.errorMsg
                ? <p>You were invited to the project <b>"{this.state.name}"</b>. Click on the button below to accept the invite</p>
                : <>
                    <p>{this.state.errorMsg}</p>
                  </>
                }
              </CardBody>
              <CardFooter>
                {this.isUserLoggedIn()
                  ? <SuccessButton color={'primary'} variant={'contained'} onClick={this.acceptInvite.bind(this)}>Accept Invite</SuccessButton>
                  : <SuccessButton
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => {
                      this.peopleService.setAfterLoginPath(window.location.pathname)
                      this.props.history.navigateByUrl('/login')
                    }}
                  >
                    Login
                  </SuccessButton>
                }
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

ProjectInvite.propTypes = {};

export default ProjectInvite;