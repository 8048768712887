import { ModelService } from './ModelService';
import pluralize from 'pluralize';

export class MessageService extends ModelService {

  modelName = 'Message'

  getConversations () {
    const last = this.storageService.get('messagesFetchTimestamp') || 0
    return this.apiService.go(`/${pluralize.plural(this.modelName)}/conversations?last=${last}`, {
      method: 'GET',
      silentLoading: true
    });
  }

  getContacts () {
    return this.apiService.go(`/${pluralize.plural(this.modelName)}/contacts`)
  }

  sendMessage (toId, body) {
    return this.apiService.go(`/${pluralize.plural(this.modelName)}/send-message`, {
      method: 'POST',
      body  : {toId, body},
    })
  }

  markAsRead (messageId) {
    return this.apiService.go(`/${pluralize.plural(this.modelName)}/mark-as-read/${messageId}`)
  }
}