import React, { Component } from 'react';
import UserProfile from '../../components/UserProfile/UserProfile';
import { PeopleService } from '../../services/PeopleService';
import { NotificationService } from '../../services/NotificationService';
import { Route, Switch } from 'react-router-dom';
import ContractorProfileContainer from '../ContractorProfileContainer';
import AdminNotesContainer from './AdminNotesContainer';
import { AdminService } from '../../services/AdminService';
import AdminBidsSentContainer from './AdminBidsSentContainer';
import AdminUserMatchesContainer from './AdminUserMatchesContainer';
import AdminUserProjectsContainer from './AdminUserProjectsContainer';

class AdminUserContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // user data
    }

    this.peopleService = new PeopleService()
    this.adminService = new AdminService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.getUserData()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.state.id || prevProps.match.params.id !== this.props.match.params.id)
      this.getUserData()
  }

  async getUserData () {
    let resp = await this.peopleService.findUserById(this.props.match.params.id)
    this.setState(resp.parsed.data)
    window.scrollTo(0, 0)
  }

  async handleSubmit (formValues) {
    let resp = await this.peopleService.patch(formValues)

    if (resp.status >= 200 && resp.status < 300) {
      this.notificationService.success('Updated!')
    } else {
      this.notificationService.error('Error updating account')
    }
  }

  async handleChangePassword() {
    let password = prompt('Set a password')
    if (!password)
      return

    let resp = await this.adminService.changePassword(this.props.match.params.id, password)
    if (resp.status === 200)
      this.notificationService.success('Password updated')
    else
      this.notificationService.error('An error occurred')
  }

  render () {
    return (
      <Switch>
        <Route path={`/admin/user/:id/professional-profile`} render={(props) => (
          <ContractorProfileContainer
            {...props}
            getInitialData={async (self) => {
              let resp = await self.peopleService.findUserById(this.props.match.params.id)
              if (resp.status === 200){
                self.setState(resp.parsed.data)
              }
              else {
                self.notificationService.error('There was an error loading your profile. Please, reload the page')
              }
            }}
            onSubmit={async (self, formValues) => {
              formValues.userId = await this.props.match.params.id
              let resp = await self.peopleService.patch(formValues)

              self.props.afterSubmit && self.props.afterSubmit(formValues, resp)

              if (resp.status === 200){
                self.notificationService.success('Updated!')
                this.props.history.push(`/admin/user/${this.props.match.params.id}`) // dashboard
              } else {
                self.notificationService.error('There was an error updating your profile. Please check your answers and try again.')
              }
            }}
          />
        )}/>
        <Route path={`/admin/user/:id`} render={(props) => (
          <>
            <UserProfile
              {...props}
              data={this.state}
              onSubmit={this.handleSubmit.bind(this)}
              onChange={(data) => this.setState(data)}
              onChangePassword={this.handleChangePassword.bind(this)}
              submitLabel={'Submit'}
              nextPageLinks={{
                gc        : [
                  {
                    label: 'Professional Profile',
                    next : `/admin/user/${this.props.match.params.id}/professional-profile`,
                  }],
                architect        : [
                  {
                    label: 'Professional Profile',
                    next : `/admin/user/${this.props.match.params.id}/professional-profile`,
                  }],
                subcontractor: [
                  {
                    label: 'Professional Profile',
                    next : `/admin/user/${this.props.match.params.id}/professional-profile`,
                  }],
              }}
            />

            {this.state.userType !== 'owner' &&
            <AdminBidsSentContainer
              {...props}
              data={this.state}
            />}

            {this.state.userType !== 'owner' &&
            <AdminUserMatchesContainer
              {...props}
              data={this.state}
            />}

            {this.state.userType === 'owner' &&
            <AdminUserProjectsContainer
              {...props}
              data={this.state}
            />}

            <AdminNotesContainer
              {...props}
              onChange={(data) => this.setState(data)}
              data={this.state}
            />
          </>
        )}/>
      </Switch>
    );
  }
}

export default AdminUserContainer;