import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import styled from 'styled-components';
import classNames from 'classnames'

const ClickableListItem = styled(ListItem)`
  cursor: pointer;
  
  &.active,
  &:hover {
    background: #f1f1f1;
  }
`

const Conversations = (props) => {
  const {data, activeConversation, onSelectConversation} = props
  return (
    <List>
      {data && data.length ?
        data.map((conversation, index) => (
          <ClickableListItem
            key={index}
            onClick={() => onSelectConversation(conversation)}
            className={classNames({active: activeConversation && conversation.contactUserId === activeConversation.contactUserId})}
          >
            <ListItemAvatar>
              <Avatar>
                {conversation.firstName[0]}{conversation.lastName[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${conversation.firstName} ${conversation.lastName} ${conversation.companyName ? `(${conversation.companyName})` : ''}`}
                          secondary={moment(conversation.lastMessageSentAt).format('MMMM D, YYYY @ h:mm A')}
            />
          </ClickableListItem>
        ))
        : <ListItem className="pl-1">
          <ListItemText primary={`Your conversations list is empty`}/>
        </ListItem>}

    </List>
  )
}

Conversations.props = {
  data: PropTypes.array,
  activeConversation: PropTypes.object,
  onSelectConversation: PropTypes.func,
}

export default Conversations