import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import {Field} from 'react-final-form'
import ValidationService from '../../services/ValidationService';
import * as _ from 'lodash'

class FieldsetRadioGroup extends React.Component {
  render(){
    return (
      <FormControl component="fieldset" className={`border border-gray mb-3 ${this.props.className}`} fullWidth={true}>
        <FormLabel component="legend" className={`w-auto ml-2`}>
          <small className={`pl-1 pr-2`}>{this.props.title || _.startCase(this.props.field)}</small>
        </FormLabel>
        <Row className={`ml-0`}>
          {this.props.options.map((option, k) => (
            <Col key={k}>
              <Field
                name={this.props.field}
                type={"radio"}
                validate={ValidationService.instance().required}
              >
                {({input, meta}) => (
                  <>
                  <FormControlLabel
                    {...input}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    onChange={(e) => this.props.onChange({[this.props.field]: e.target.value})}
                    checked={option.value === this.props.value}
                  />
                    {k === 0 && meta.error && meta.touched ? <FormHelperText error={true} className={`m-0 pb-2`}>{meta.error}</FormHelperText> : null}
                  </>
                )}
              </Field>
            </Col>
          ))}
        </Row>
      </FormControl>

    )
  }
}

FieldsetRadioGroup.propTypes = {
  title: PropTypes.string,
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
}

export default FieldsetRadioGroup