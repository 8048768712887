import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import { ProjectsService } from '../services/ProjectsService';
import * as _ from 'lodash'
import ListProjectsTable from '../components/Project/ListProjectsTable';
import Link from '../components/Link';
import { Button } from '@material-ui/core';
import moment from 'moment'

class ArchitectDashboard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      projectsToBidOn: [],
      opportunities  : [],
      customers      : [],
    }

    this.projectsService = new ProjectsService()
  }

  componentDidMount () {
    this.getOpportunities()
    this.getProjectsToBidOn()
    this.getCustomerProjects()
  }

  async getOpportunities () {
    let resp = await this.projectsService.getProjectsToAuction()
    let opportunities = resp.parsed.data
    this.setState({opportunities})
  }

  async getProjectsToBidOn () {
    let resp = await this.projectsService.getLeadProjects()
    let projectsToBidOn = resp.parsed.data
    this.setState({projectsToBidOn})
  }

  async getCustomerProjects () {
    let resp = await this.projectsService.getProjects()
    let customers = resp.parsed.data
    this.setState({customers})
  }

  async handleGetLead (project) {
    await this.projectsService.payForFreeLead(project.id)
    await this.getOpportunities()
    await this.getProjectsToBidOn()
  }

  render () {
    return (
      <>
        <div className="ArchitectDashboard content container-fluid">
          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">OPPORTUNITIES</CardTitle>
                </CardHeader>
                <CardBody>
                  <ListProjectsTable
                    data={this.state.opportunities}
                    cols={[
                      {
                        label  : 'Project Name',
                        process: (fieldValue, row) => (
                          <Link href={`/subcontractor/projects/${row.id}`}>{row.name || 'N/A'}</Link>
                        ),
                      },
                      {
                        label: 'Sq. Ft.',
                        field: 'size',
                      },
                      {
                        label  : 'Project Address',
                        process: (fieldValue, row) => (
                          <span>(Get lead to unlock)</span>
                        ),
                      },
                      {
                        label  : 'Bid Due Date',
                        process: (fieldValue, row) => (
                          moment(row.bidDue).format('MM/DD/YY')
                        ),
                      },
                      {
                        label  : 'Plans',
                        process: (fieldValue, row) => (
                          <span>{row.filesCount ? <span className="text-success">Yes</span> : <span
                            className="text-danger">No</span>}</span>
                        ),
                      },
                      {
                        label  : '',
                        process: (fieldValue, row) => (
                          <Button
                            size={'small'}
                            variant={'outlined'}
                            color={'secondary'}
                            className={`mr-1`}
                            onClick={() => this.handleGetLead(row)}
                          >
                            <span className="fa fa-plus pr-2"></span> Get Lead
                          </Button>
                        ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">
                    SEND PROPOSAL / CONTACT CUSTOMER
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ListProjectsTable
                    data={this.state.projectsToBidOn}
                    cols={[
                      {
                        label  : 'Name',
                        process: (fieldValue, row) => (
                          <Link href={`/subcontractor/projects/${row.id}`}>
                            {`${_.get(row, 'owner.firstName', 'Private')} ${_.get(row, 'owner.lastName', '')}` || 'N/A'}
                          </Link>
                        ),
                      },
                      {
                        label  : 'Project Name',
                        process: (fieldValue, row) => (
                          <Link href={`/subcontractor/projects/${row.id}`}>{row.name || 'N/A'}</Link>
                        ),
                      },
                      {
                        label: 'Sq. Ft.',
                        field: 'size',
                      },
                      {
                        label  : 'Project Address',
                        process: (fieldValue, row) => (
                          <span>{row.address} {row.suite ? `,${row.suite}` : null}</span>
                        ),
                      },
                      {
                        label  : 'Bid Due Date',
                        process: (fieldValue, row) => (
                          moment(row.bidDue).format('MM/DD/YY')
                        ),
                      },
                      {
                        label  : 'Plans',
                        process: (fieldValue, row) => (
                          row.filesCount
                            ? <span className="text-success">Yes</span>
                            : <span className="text-danger">No</span>
                        ),
                      },
                      {
                        label  : '',
                        process: (fieldValue, row) => (
                          <Button
                            size={'small'}
                            variant={'outlined'}
                            color={'default'}
                            className={`mr-1`}
                            onClick={() => this.props.history.push(`/subcontractor/bid/${row.id}`)}
                          >
                            <span className="fa fa-plus pr-2"></span> New Estimate
                          </Button>
                        ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="10">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">
                    Invites and Referrals
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <h3 className={`text-muted`}>Coming soon</h3>
                </CardBody>
              </Card>
            </Col>
            <Col md="2" className={`text-center`}>
              <Card color={'secondary'}>
                <CardBody style={{height: 313}}>
                  <div className={` pt-5 mt-5`}>AD</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ArchitectDashboard;
