import React, { Component } from 'react';

import {
  Row,
  Col,
} from 'reactstrap';
import TitledList from '../components/Admin/TitledList';
import styled from 'styled-components';
import { AdminService } from '../services/AdminService';

const RotatedLabel = styled.div`
  transform: rotate(-90deg);
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  width: 40px;
  padding-top: 15px;
`

class AdminDashboard extends Component {

  constructor (props) {
    super(props)
    this.state = {
      totalArchitects: 0,
      totalExpiredArchitects: 0,
      totalArchitectsToBeApproved: 0,
      totalGc: 0,
      totalExpiredGc: 0,
      totalGcToBeApproved: 0,
      totalSubcontractors: 0,
      totalExpiredSubcontractors: 0,
      totalSubcontractorsToBeApproved: 0,
      totalOwners: 0,
      totalOwnersToBeApproved: 0,
      totalOwnersCreatingAccount: 0,
      totalRetailers: 0,
      totalRetailersToBeApproved: 0,
      totalRetailersCreatingAccount: 0,
      totalManufacturers: 0,
      totalManufacturersToBeApproved: 0,
      totalManufacturersCreatingAccount: 0,
      totalProjects: 0,
      totalOpenProjects: 0,
      totalClosedProjects: 0,
      totalProjectsToBeApproved: 0,
      bids: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      matches: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      materials: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      services: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      owner: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      gc: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      architect: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      subcontractor: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      manufacturer: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
      retailer: {
        today: 0,
        last7days: 0,
        last30days: 0,
      },
    }
    this.adminService = new AdminService()
  }

  componentDidMount () {
    this.getData()
  }

  async getData() {
    let resp = await this.adminService.getStats()
    this.setState({...resp.parsed.data})
  }

  render () {
    return (
      <div className="AdminDashboard content container-fluid">
        <Row>
          <div className="d-md-flex">
            <div className="d-sm-none d-md-flex">
              <RotatedLabel>Users</RotatedLabel>
            </div>
          </div>
          <Col>
            <Row>
              <Col sm={10} md={4} lg={2}>
                <TitledList
                  title={`Architects`}
                  tall
                  data={[
                    {
                      title: 'Total',
                      count: this.state.totalArchitects,
                      url: '/admin/architect'
                    },
                    {
                      title: 'Expired GL Insurance',
                      count: this.state.totalExpiredArchitects,
                      url: '/admin/architect?status=expired'
                    },
                    {
                      title: 'To Be Approved',
                      count: this.state.totalArchitectsToBeApproved,
                      url: '/admin/architect?status=pending'
                    },
                    {
                      title: 'Signup Stats:',
                    },
                    {
                      title: 'Today',
                      count: this.state.architect.today,
                      url: '/admin/architect?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.architect.last7days,
                      url: '/admin/architect?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.architect.last30days,
                      url: '/admin/architect?timeframe=30'
                    },
                  ]}
                />
              </Col>
              <Col sm={10} md={4} lg={2}>
                <TitledList
                  title={`General Contractors`}
                  tall
                  data={[
                    {
                      title: 'Total',
                      count: this.state.totalGc,
                      url: '/admin/gc'
                    },
                    {
                      title: 'Expired GL Insurance',
                      count: this.state.totalExpiredGc,
                      url: '/admin/gc?status=expired'
                    },
                    {
                      title: 'To Be Approved',
                      count: this.state.totalGcToBeApproved,
                      url: '/admin/gc?status=pending'
                    },
                    {
                      title: 'Signup Stats:',
                    },
                    {
                      title: 'Today',
                      count: this.state.gc.today,
                      url: '/admin/gc?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.gc.last7days,
                      url: '/admin/gc?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.gc.last30days,
                      url: '/admin/gc?timeframe=30'
                    },
                  ]}
                />
              </Col>
              <Col sm={10} md={4} lg={2}>
                <TitledList
                  title={`Subcontractors`}
                  tall
                  data={[
                    {
                      title: 'Total',
                      count: this.state.totalSubcontractors,
                      url: '/admin/subcontractor'
                    },
                    {
                      title: 'Expired GL Insurance',
                      count: this.state.totalExpiredSubcontractors,
                      url: '/admin/subcontractor?status=expired'
                    },
                    {
                      title: 'To Be Approved',
                      count: this.state.totalSubcontractorsToBeApproved,
                      url: '/admin/subcontractor?status=pending'
                    },
                    {
                      title: 'Signup Stats:',
                    },
                    {
                      title: 'Today',
                      count: this.state.subcontractor.today,
                      url: '/admin/subcontractor?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.subcontractor.last7days,
                      url: '/admin/subcontractor?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.subcontractor.last30days,
                      url: '/admin/subcontractor?timeframe=30'
                    },
                  ]}
                />
              </Col>
              <Col sm={10} md={4} lg={2}>
                <TitledList
                  title={`Owners`}
                  tall
                  data={[
                    {
                      title: 'Total',
                      count: this.state.totalOwners,
                      url: '/admin/owner'
                    },
                    {
                      title: 'To Be Approved',
                      count: this.state.totalOwnersToBeApproved,
                      url: '/admin/owner?status=pending'
                    },
                    {
                      title: 'Creating Account',
                      count: this.state.totalOwnersCreatingAccount,
                      url: '/admin/owner?status=creating_account'
                    },
                    {
                      title: 'Signup Stats:',
                    },
                    {
                      title: 'Today',
                      count: this.state.owner.today,
                      url: '/admin/owner?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.owner.last7days,
                      url: '/admin/owner?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.owner.last30days,
                      url: '/admin/owner?timeframe=30'
                    },
                  ]}
                />
              </Col>
              <Col sm={10} md={4} lg={2}>
                <TitledList
                  title={`Retailers`}
                  tall
                  data={[
                    {
                      title: 'Total',
                      count: this.state.totalRetailers,
                      url: '/admin/retailer'
                    },
                    {
                      title: 'To Be Approved',
                      count: this.state.totalRetailersToBeApproved,
                      url: '/admin/retailer?status=pending'
                    },
                    {
                      title: 'Creating Account',
                      count: this.state.totalRetailersCreatingAccount,
                      url: '/admin/retailer?status=creating_account'
                    },
                    {
                      title: 'Signup Stats:',
                    },
                    {
                      title: 'Today',
                      count: this.state.retailer.today,
                      url: '/admin/retailer?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.retailer.last7days,
                      url: '/admin/retailer?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.retailer.last30days,
                      url: '/admin/retailer?timeframe=30'
                    },
                  ]}
                />
              </Col>
              <Col sm={10} md={4} lg={2}>
                <TitledList
                  title={`Manufacturers`}
                  tall
                  data={[
                    {
                      title: 'Total',
                      count: this.state.totalManufacturers,
                      url: '/admin/manufacturer'
                    },
                    {
                      title: 'To Be Approved',
                      count: this.state.totalManufacturersToBeApproved,
                      url: '/admin/manufacturer?status=pending'
                    },
                    {
                      title: 'Creating Account',
                      count: this.state.totalManufacturersCreatingAccount,
                      url: '/admin/manufacturer?status=creating_account'
                    },
                    {
                      title: 'Signup Stats:',
                    },
                    {
                      title: 'Today',
                      count: this.state.manufacturer.today,
                      url: '/admin/manufacturer?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.manufacturer.last7days,
                      url: '/admin/manufacturer?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.manufacturer.last30days,
                      url: '/admin/manufacturer?timeframe=30'
                    },
                  ]}
                />
              </Col>
            </Row>

          </Col>

        </Row>

        <Row>
          <div className="d-md-flex">
            <div className="d-sm-none d-md-flex">
              <RotatedLabel>Projects</RotatedLabel>
            </div>
          </div>

          <Col>
            <Row>
              <Col sm={12} md={4} lg={2}>
                <TitledList
                  title={`Projects`}
                  data={[
                    {
                      title: 'Total',
                      count: this.state.totalProjects,
                      url: '/admin/projects'
                    },
                    {
                      title: 'Open',
                      count: this.state.totalOpenProjects,
                      url: '/admin/projects?bidStatus=accepting_bids'
                    },
                    {
                      title: 'Closed',
                      count: this.state.totalClosedProjects,
                      url: '/admin/projects?bidStatus=bid_accepted'
                    },
                    {
                      title: 'To Be Approved',
                      count: this.state.totalProjectsToBeApproved,
                      url: '/admin/projects?status=pending'
                    },
                  ]}
                />
              </Col>

              <Col sm={12} md={4} lg={2}>
                <TitledList
                  title={`Submtt'd & Accpt'd Bids`}
                  data={[
                    {
                      title: 'Today',
                      count: this.state.bids.today,
                      url: '/admin/bids?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.bids.last7days,
                      url: '/admin/bids?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.bids.last30days,
                      url: '/admin/bids?timeframe=30'
                    },
                  ]}
                />
              </Col>

              <Col sm={12} md={4} lg={2}>
                <TitledList
                  title={`Paid Leads`}
                  data={[
                    {
                      title: 'Today',
                      count: this.state.matches.today,
                      url: '/admin/matches?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.matches.last7days,
                      url: '/admin/matches?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.matches.last30days,
                      url: '/admin/matches?timeframe=30'
                    },
                  ]}
                />
              </Col>
              <Col sm={12} md={4} lg={2}>
                <TitledList
                  title={`Materials`}
                  data={[
                    {
                      title: 'Today',
                      count: this.state.materials.today,
                      url: '/admin/materials?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.materials.last7days,
                      url: '/admin/materials?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.materials.last30days,
                      url: '/admin/materials?timeframe=30'
                    },
                  ]}
                />
              </Col>
              <Col sm={12} md={4} lg={2}>
                <TitledList
                  title={`Services`}
                  data={[
                    {
                      title: 'Today',
                      count: this.state.services.today,
                      url: '/admin/services?timeframe=today'
                    },
                    {
                      title: 'Last 7 Days',
                      count: this.state.services.last7days,
                      url: '/admin/services?timeframe=7'
                    },
                    {
                      title: 'Last 30 Days',
                      count: this.state.services.last30days,
                      url: '/admin/services?timeframe=30'
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

AdminDashboard.propTypes = {};

export default AdminDashboard;