import React from 'react';
import PropTypes from 'prop-types'
// nodejs library that concatenates classes
import classNames from 'classnames';
import * as _ from 'lodash'

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavLink,
  Nav,
  Container,
  Card, CardBody,
} from 'reactstrap';
import { StorageService } from '../../services/StorageService';
import { PeopleService } from '../../services/PeopleService';

import styled from 'styled-components'
import NotificationsListContainer from '../NotificationsList/NotificationsListContainer';
import Link from '../Link';

const StyledFloatingMenu = styled(Collapse)`
  position: absolute;
  right: 0;
  
  .dropdown-toggle:after {
    position: absolute;
    top: 20px;
    margin-left: 30px !important;
  }
  
  @media screen and (max-width: 991px){
    position: absolute;
    top: 110px;
    background: #fff;
    width: 230px;
    right: 20px;
    box-shadow: 0px 2px 10px -1px rgba(0,0,0,0.2);
    padding: 20px 10px;
  }
`

const StyledKebabButton = styled.button`
  position: absolute;
  right: 20px;
`

class UserHeader extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch : false,
      color       : 'navbar-transparent',
    };

    this.storageService = new StorageService()
    this.peopleService = new PeopleService()
  }

  // this function opens and closes the collapse on small devices
  toggleCollapse () {
    if (this.state.collapseOpen) {
      this.setState({
        color: 'navbar-transparent',
      });
    } else {
      this.setState({
        color: 'bg-white',
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  }

  render () {
    return (
      <div className={classNames('UserHeader container-fluid mb-2', this.props.className)}>
        <Card className="mb-2">
          <CardBody className={`row pt-0 pb-0`}>
            <div className="title-wrapper col-6 d-flex">
              <h1 className="align-self-center m-0 font-weight-bold">{this.props.title.toUpperCase()}</h1>
            </div>
            <Navbar
              className={classNames('col-6 pr-0', this.state.color)}
              expand="lg"
            >
              <Container fluid>
                <StyledKebabButton
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#navigation"
                  data-toggle="collapse"
                  id="navigation"
                  type="button"
                  onClick={this.toggleCollapse.bind(this)}
                >
                  <span className="navbar-toggler-bar navbar-kebab"/>
                  <span className="navbar-toggler-bar navbar-kebab"/>
                  <span className="navbar-toggler-bar navbar-kebab"/>
                </StyledKebabButton>
                <StyledFloatingMenu navbar isOpen={this.state.collapseOpen}>
                  <Nav className="ml-auto" navbar>
                    <li className="text-right user-fullname pr-2">
                      <h3 className="font-weight-bold p-0 m-0">{_.upperCase(
                        this.storageService.get('user.data.firstName'))} {_.upperCase(
                        this.storageService.get('user.data.lastName'))}</h3>
                      <h3 className="font-weight-bold p-0 m-0">{_.upperFirst(
                        this.storageService.get('user.data.userType'))}</h3>
                    </li>

                    <NotificationsListContainer/>

                    <li className="nav-item">
                      <Link href="/user/messages" className="border nav-link btn btn-link m-0">
                        <span className="fa fa-inbox p-2"></span>
                      </Link>
                    </li>

                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        caret
                        color="default"
                        data-toggle="dropdown"
                        nav
                        onClick={e => e.preventDefault()}
                        className="m-0 border"
                      >
                        <Button color="link" className="m-0">
                          <span className="fa fa-cog"></span>
                        </Button>
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-navbar" right tag="ul">
                        <NavLink tag="li">
                          <DropdownItem className="nav-item" onClick={(e) => window.app.history.push('/user/profile')}>
                            My Profile
                          </DropdownItem>
                        </NavLink>

                        {['gc', 'subcontractor'].includes(this.peopleService.me().userType)
                          ? <NavLink tag="li">
                            <DropdownItem
                              className="nav-item"
                              onClick={(e) => window.app.history.push('/user/professional-profile')}
                            >
                              Professional Profile
                            </DropdownItem>
                          </NavLink>
                          : null}

                        <DropdownItem divider tag="li"/>

                        <NavLink tag="li">
                          <DropdownItem className="nav-item" onClick={(e) => window.app.history.push('/logout')}>
                            Log out
                          </DropdownItem>
                        </NavLink>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </StyledFloatingMenu>
              </Container>
            </Navbar>
          </CardBody>
        </Card>
      </div>
    );
  }
}

UserHeader.propTypes = {
  title    : PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default UserHeader