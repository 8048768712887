import environment from '../environments/environment'
import { ModelService } from './ModelService'

import * as moment from 'moment'
import * as _ from 'lodash'
import { ProjectsService } from './ProjectsService';
import { StorageService } from './StorageService';
import appStore from '../AppStore';

export class PeopleService extends ModelService {

  modelName = 'People'

  constructor () {
    super()
    this.projectsService = new ProjectsService()
    this.storageService = new StorageService()
  }

  static instance() {
    return new PeopleService() // create a singleton with a closure
  }

  register (data) {
    return this.apiService.go(this.modelName, {
      method: 'POST',
      body  : {
        ...data,
        email: data.email.toLowerCase(),
      },
    })
  }

  async login (data) {
    let resp = {}
    try {
      resp = await this.apiService.go(this.getPublicModelNamePath() + '/login', {
        method: 'POST',
        body  : {
          ...data,
          email: data.email.toLowerCase(),
        },
      })
    } catch (e) {
      return resp
    }

    // save the token for future calls
    this.storageService.set('user', {
      tokenData: resp.parsed.data,
    })
    environment.oAuthToken = this.storageService.get('user.tokenData.id')
    this.storageService.set('user.data.id', resp.parsed.data.userId)

    let userDataResp = await this.refreshUserDataInLocalStorage()
    return userDataResp
  }

  async refreshUserDataInLocalStorage(){
    let resp = await this.apiService.go(this.getAuthPath())
    resp.parsed.data.id = this.storageService.get('user.data.id') // monkeypatch the user id
    this.storageService.set('user.data', resp.parsed.data)

    return resp
  }

  getLoggedInUser () {
    if (!_.isNull(this.storageService.get('user.tokenData.userId')))
      return this.apiService.go(this.getPublicModelNamePath() + '/' + this.storageService.get('user.tokenData.userId'))
    else
      throw new Error('User is not logged in')
  }

  details(userId){
    return this.apiService.go(this.getPublicModelNamePath() + `/${userId}/details`)
  }

  async logout () {
    try {
      await this.apiService.go(this.getPublicModelNamePath() + '/logout', {
        method: 'POST',
      })
    } catch (e) {
      console.error('Error!!', e)
      // swallow the errors. They are mostly "can't logout without token"
    }
    this.storageService.set('user', {})
    environment.oAuthToken = this.storageService.get('user.tokenData.id')
  }

  /**
   * Returns the current logged in user
   */
  me (path = '') {
    return this.storageService.get('user.data' + (path ? '.' + path : ''))
  }

  isValidToken (tokenData) {
    if (_.size(tokenData)) {
      let exp = moment(tokenData.created).utc().add(tokenData.ttl, 'seconds').valueOf()
      let now = moment().utc().valueOf()
      return exp > now
    }

    return false
  }

  resetPasswordWithToken (password, accessToken) {
    return this.apiService.go(`/Public/reset-password-with-token`, {
      method: 'POST',
      body  : {password, accessToken},
      csrfToken: accessToken
    })
  }

  resetPassword (email) {
    return this.apiService.go('/Public/forgot-password', {
      method: 'POST',
      body  : {email},
    })
  }


  clientToken () {
    return this.apiService.go(
      this.getAuthModelNamePath() + '/' + this.storageService.get('user.tokenData.userId') + '/client-token')
  }

  findUserById (id) {
    return this.apiService.go(`/People/${id}`, {
      method: 'GET',
    })
  }

  verifyEmail (email) {
    return this.apiService.go(`/Public/verify-email/${encodeURIComponent(email.toLowerCase())}`, {
      method: 'GET',
    })
  }

  getInvalidFields (formControls) {
    let invalidFields = []
    _.each(formControls, (v, k) => {
      if (v.status === 'INVALID') {
        invalidFields.push({[k]: v.value})
      }
    })
    return invalidFields
  }

  getInvalidFieldsAsString (formControls) {
    // on form submit, if the form is not valid, mark all fields as 'touched' that way the error messages show
    let invalidFields = this.getInvalidFields(formControls)
    let invalidFieldsString = ''
    _.each(invalidFields, (v) => invalidFieldsString = invalidFieldsString.concat(
      _.upperFirst(Object.keys(v)[0]).replace(/_/g, ' ').replace(/([A-Z])/g, ' $1')) + ', ')
    return _.trim(invalidFieldsString, ', ')
  }

  sendSupportEmail (data) {
    return this.apiService.go(`/Public/send-support-email`, {
      method: 'POST',
      body  : {data},
    })
  }


  async navigateToPageBasedOnRole () {
    let loggedInUser = null
    try {
      let resp = await this.getLoggedInUser()
      loggedInUser = resp.parsed.data
    } catch (err) {
      // user is not logged in
    }

    if (!loggedInUser) {
      if (window.location.pathname !== '/login')
        return window.app.history.navigateByUrl('/login');
      else
        console.log('User is logged out')
        return
    }

    switch (loggedInUser.userType) {
      case 'admin':
        window.app.history.navigateByUrl('/admin/dashboard');
        break;
      case 'architect':
        window.app.history.navigateByUrl('/architect/dashboard');
        break;
      case 'owner':
        window.app.history.navigateByUrl('/owner/dashboard');
        break;
      case 'gc':
        window.app.history.navigateByUrl('/gc/dashboard');
        break;
      case 'subcontractor':
        if ((this.storageService.get('user.data.companyName') || '').length) {
          window.app.history.navigateByUrl('/subcontractor/dashboard');
        } else {
          window.app.history.navigateByUrl('/user/professional-profile');
        }
        break;
      case 'retailer':
      case 'manufacturer':
        // if ((this.storageService.get('user.data.companyName') || '').length) {
        //   window.app.history.navigateByUrl('/architect/dashboard');
        // } else {
        //   window.app.history.navigateByUrl('/user/onboard/architect');
        // }

        window.app.history.push('/user/onboard/coming-soon')
        break;
      default:
        window.app.history.navigateByUrl('/user/onboard/profile');
        break;
    }
  }

  async patch (data) {
    let optionalId = _.get(data, 'id', false) || _.get(data, 'userId', false);
    let resp = await this.apiService.go(`/People` + (optionalId ? '/' + optionalId : ''), {
      method: optionalId ? 'PUT' : 'POST', // models in API should have "replaceOnPUT": true
      body  : data,
    });

    await this.refreshUserDataInLocalStorage()

    appStore.dispatch({
      type: 'set',
      state: {
        ...appStore.getState(),
        lastUserUpdate: resp.parsed.data.lastUpdated
      }
    })

    return resp
  }

  async patchFields (data) {
    let resp = await this.apiService.go(`${this.getAuthPath()}/patchFields`, {
      method: 'POST',
      body  : {data},
    });

    await this.refreshUserDataInLocalStorage()

    appStore.dispatch({
      type: 'set',
      state: {
        ...appStore.getState(),
        lastUserUpdate: resp.parsed.data.lastUpdated
      }
    })

    return resp
  }

  getPreferredSubs(){
    return this.apiService.go(`${this.getAuthPath()}/preferred-sub`)
  }

  deletePreferredSub(id) {
    return this.apiService.go(`${this.getAuthPath()}/preferred-sub/${id}`, {
      method: 'delete'
    })
  }

  inviteSubcontractors(data) {
    return this.apiService.go(`${this.getAuthPath()}/invite-subcontractors`, {
      method: 'post',
      body: data
    })
  }

  submitForAccountReview(){
    return this.apiService.go(`${this.getAuthPath()}/review-account`, {
      method: 'post',
      body: {}
    })
  }

  setAfterLoginPath(path) {
    this.storageService.set('afterLoginPath', path)
  }

  getAfterLoginPath() {
    return this.storageService.get('afterLoginPath')
  }

  deleteAfterLoginPath() {
    return this.storageService.delete('afterLoginPath')
  }
}
