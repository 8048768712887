import React, { Component } from 'react';
import PropTypes from 'prop-types'
import InviteSubcontractors from './InviteSubcontractors';
import { PeopleService } from '../../services/PeopleService';
import { NotificationService } from '../../services/NotificationService';

class InviteSubcontractorsContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      subcontractors: []
    }
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.getInitialData()
  }

  async getInitialData () {
    let invitedSubcontractorsResp = await this.peopleService.getPreferredSubs()
    this.setState({
      subcontractors: invitedSubcontractorsResp.parsed.data.map((preferredSub) => preferredSub.user),
    })
  }

  async handleSubmit (formValues) {
    let resp = {}
    let alreadyInvited = this.state.subcontractors.map((sub) => sub.email)
    let invitedSubcontractors = formValues.subcontractors.filter((sub) => !alreadyInvited.includes(sub.email))
    if (invitedSubcontractors.length) {
      try {
        resp = await this.peopleService.inviteSubcontractors({invitedSubcontractors})
      } catch (e) {

      }
    } else {
      return window.app.history.navigateByUrl('/user/profile')
    }

    await this.peopleService.refreshUserDataInLocalStorage()
    this.props.onSubmit && this.props.onSubmit(formValues, resp)

    if (resp.status === 200) {
      this.notificationService.success('An invite will be sent to the subcontractors')
      window.app.history.navigateByUrl('/user/profile')
    }
  }

  render () {
    return (
      <div className={`InviteSubcontractorsContainer content container-fluid`}>
        <InviteSubcontractors
          data={this.state.subcontractors}
          onSubmit={this.handleSubmit.bind(this)}
        />
      </div>
    );
  }
}

InviteSubcontractorsContainer.propTypes = {
  onSubmit: PropTypes.func,
};

export default InviteSubcontractorsContainer;