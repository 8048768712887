import React  from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import Link from '../Link';
//
// const Heading = styled.div`
//   font-size: 16px;
//   font-weight: bold;
//   border-bottom: 1px solid #ccc;
//   margin-bottom: 10px;
//   padding-bottom: 5px;
//   color: #444;
// `

const StyledFieldSet = styled.fieldset`
  min-height: 220px;
`

const ProjectMatches = (props) => {
  const {project} = props
  return (
    <div id="project-matches" className={`ProjectMatches`}>
      <StyledFieldSet className={`custom-fieldset`}>
        <h3 className={`mb-2`}>Matches</h3>
        {project.leadBoughtBy && project.leadBoughtBy.map((company, index) =>
        <Row key={index}>
          <Col>{index + 1}. <Link href={`/user/profile/${company.userId}`}>{company.companyName} ({company.firstName} {company.lastName})</Link></Col>
        </Row>
        )}
      </StyledFieldSet>
    </div>
  )
}

ProjectMatches.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectMatches;