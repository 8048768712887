import React, { Component } from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ProjectsService } from '../../services/ProjectsService';
import ProjectForm from './ProjectForm';
import { NotificationService } from '../../services/NotificationService';
import { StorageService } from '../../services/StorageService';
import { ContainersService } from '../../services/ContainerService';
import * as _ from 'lodash'
import styled from 'styled-components'
import { Button } from '@material-ui/core';
import { FILE, FOLDER } from '../FileSystem/utils/constants';

const StyledSmallTitle = styled('div')`
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  
`

class EditProjectContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      copyShareableLinkCopyBtnLabel: 'copy',
    }

    this.projectsService = new ProjectsService()
    this.notificationService = new NotificationService()
    this.storageService = new StorageService()
    this.containersService = new ContainersService()
  }

  componentDidMount () {
    this.getData()
  }

  async getData() {
    if (_.isFunction(this.props.getData))
      return this.props.getData(this)

    let resp = await this.projectsService.details(this.props.match.params.id)
    if (!resp.parsed.data.entries)
      resp.parsed.data.entries = []

    this.setState({...resp.parsed.data, })
  }

  onChange(data) {
    this.setState(data)
  }

  async onSubmit(formValues, acceptedFiles) {
    formValues.architect = formValues.architect || ""
    formValues.entries = [
      ...(formValues.entries || []),
      ...acceptedFiles
    ]

    // remove duplicates
    formValues.entries = formValues.entries.reduce((acc, current) => {
      const x = acc.find(item => item.path === current.path);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    formValues = _.omit(formValues, ['files']) // we should have entries

    let errorUploading = false

    try {
      let containerName = `${this.state.ownerId}-${this.state.id}`
      let resp = await this.containersService.uploadFiles(containerName, acceptedFiles)

      if (resp && resp.status !== 200) {
        throw resp
      }
    } catch (err) {
      console.log(err)
      errorUploading = true
      this.notificationService.error('There was an error uploading the files')
      return false
    }

    if (!errorUploading) {
      try {
        let projectResp
        if (_.isFunction(this.props.projectPatchFn))
          projectResp = await this.props.projectPatchFn(this, formValues)
        else
          projectResp = await this.projectsService.patch(formValues)

        projectResp.status === 200 && this.notificationService.success('Saved')
      } catch (err) {
        this.notificationService.error('There was an error saving the data')
        return false
      }
    }

    await this.getData()

    return true // submit was successful
  }

  async onDelete() {
    if (_.isFunction(this.props.onDelete))
      return this.props.onDelete(this)

    try {
      let resp = await this.projectsService.delete(this.state.id)
      if (resp.status === 204) {
        this.notificationService.success('Project deleted!')
        window.app.history.push('/')
      }
    } catch (err) {
      this.notificationService.error('Error deleting project')
    }
  }

  async recursivelyDeleteFolder(entry) {
    if (entry.type !== FOLDER)
      return

    let entriesToDelete = this.state.entries.filter((currentEntry) => currentEntry.parentPath === entry.path)
    for (let i = 0; i < entriesToDelete.length; i++) {
      let currentEntry = entriesToDelete[i]
      if (currentEntry.type === FOLDER) {
        await this.recursivelyDeleteFolder(currentEntry)
      } else {
        try {
          // delete the physical file
          await this.containersService.deleteFileFrom(`${this.state.ownerId}-${this.state.id}`, currentEntry.name, {disableNotifications: true})
        } catch(err) {
          // do nothing, since this file might already been deleted
        }
        // remove the file from project state
        this.setState({ entries: this.state.entries.filter(e => e.path !== currentEntry.path) })
      }
    }
    // all files and sub-folders should have been delete and this should be an empty folder.
    // remove this folder from the project state
    this.setState({ entries: this.state.entries.filter(e => e.path !== entry.path) })
  }

  async onDeleteEntry(entry) {
    if (entry.type === FILE) {
      try {
        // remove physical file
        await this.containersService.deleteFileFrom(`${this.state.ownerId}-${this.state.id}`, entry.name)
      } catch (err) {
        // do nothing, since this file might already been deleted
      }
      this.setState({ entries: this.state.entries.filter(e => e.path !== entry.path) })
    } else if (entry.type === FOLDER) {
      await this.recursivelyDeleteFolder(entry)
    }

    if (this.props.projectPatchFn)
      await this.props.projectPatchFn(this, this.state)
    else
      await this.projectsService.patch(this.state)

    await this.getData()
  }

  onAddEntry(formValues, acceptedFiles, entry) {
    // we do this here in order to have a middle layer and for consistency
    return this.onSubmit(formValues, acceptedFiles)
  }

  copyShareableLinkToClipboard(newClip){
    navigator.permissions.query({name: "clipboard-write"}).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(newClip).then(() => {
          this.setState({copyShareableLinkCopyBtnLabel: 'copied!'})
          setTimeout(() => {
            this.setState({copyShareableLinkCopyBtnLabel: 'copy'})
          }, 2000)
        }, () => {
          this.setState({copyShareableLinkCopyBtnLabel: 'copy to clipboard failed'})
          setTimeout(() => {
            this.setState({copyShareableLinkCopyBtnLabel: 'copy'})
          }, 2000)
        });
      }
    });
  }

  getProjectStatus(){
    switch (this.state.status) {
      default:
      case 'pending':
        return 'Pending admin approval'
      case 'denied':
        return 'Denied by admin'
      case 'temp_approved':
      case 'approved':
        return 'Approved by admin'
    }
  }

  render () {
    return (
      <ProjectForm
        data={this.state}
        formTitle={
          <div>
            Edit Project
            <StyledSmallTitle>
              <b>Shareable Link:</b> {window.location.protocol}{'//'}{window.location.host}/project/invite/{this.state.id}
              <Button
                variant={'text'}
                color={'primary'}
                onClick={() => this.copyShareableLinkToClipboard(`${window.location.protocol}//${window.location.host}/project/invite/${this.state.id}`)}
                className={`pl-2 pr-2`}
              >
                <span className={`fa fa-copy mr-2`}/> {this.state.copyShareableLinkCopyBtnLabel}
              </Button>
              <div>Project status: <span className={classNames('font-weight-bold', {
                'text-success': ['approved', 'temp_approved'].includes(this.state.status),
                'text-warning': this.state.status === 'pending',
                'text-danger': this.state.status === 'denied',
              })}>{this.getProjectStatus()}</span></div>
            </StyledSmallTitle>
          </div>
        }
        submitLabel={`Submit`}
        onChange={this.onChange.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        onDelete={this.onDelete.bind(this)}
        onAddEntry={this.onAddEntry.bind(this)}
        onDeleteEntry={this.onDeleteEntry.bind(this)}
        showStats={true}
      />
    );
  }
}

EditProjectContainer.propTypes = {
  getData: PropTypes.func,
  projectPatchFn: PropTypes.func,
  onDelete: PropTypes.func,
}

export default EditProjectContainer;