import React, { Component } from 'react';
import UserProfile from '../components/UserProfile/UserProfile';
import { PeopleService } from '../services/PeopleService';
import { NotificationService } from '../services/NotificationService';
import { Route, Switch } from 'react-router-dom';
import ViewUserProfileContainer from '../components/UserProfile/ViewUserProfileContainer';

class UserProfileContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // user data
    }

    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.getUserData()
  }

  async getUserData () {
    let resp = await this.peopleService.getLoggedInUser()
    this.setState(resp.parsed.data)
  }

  async handleSubmit (formValues) {
    let resp = await this.peopleService.patch(formValues)

    if (resp.status >= 200 && resp.status < 300) {
      this.notificationService.success('Updated!')
    } else {
      this.notificationService.error('Error updating account')
    }
  }

  async handleChangePassword (formValues) {
    let resp = await this.peopleService.resetPassword(formValues.email)

    if (resp.status === 200)
      this.notificationService.success('Reset password email sent')
    else
      this.notificationService.error('Error sending reset password email')
  }

  render () {
    return (
      <Switch>
        <Route path={`/user/profile/:id`} render={(props) => (
          <ViewUserProfileContainer {...props} />
        )}/>
        <Route path={`/user/profile`} render={(props) => (
          <UserProfile
            {...props}
            data={this.state}
            onSubmit={this.handleSubmit.bind(this)}
            onChange={(data) => this.setState(data)}
            onChangePassword={this.handleChangePassword.bind(this)}
            submitLabel={'Submit'}
            nextPageLinks={{
              gc        : [
                {
                  label: 'Professional Profile',
                  next : '/user/professional-profile',
                },
                {
                  label: 'Invite Subcontractors',
                  next : '/user/gc/invite-subcontractors',
                }],
              architect        : [
                {
                  label: 'Professional Profile',
                  next : '/user/professional-profile',
                },
                {
                  label: 'Invite Subcontractors',
                  next : '/user/gc/invite-subcontractors',
                }],
              subcontractor    : [
                {
                  label: 'Professional Profile',
                  next : '/user/professional-profile',
                }],
              retailer    : [
                {
                  label: 'Professional Profile',
                  next : '/user/professional-profile',
                }],
              manufacturer    : [
                {
                  label: 'Professional Profile',
                  next : '/user/professional-profile',
                }],
            }}
          />
        )}/>
      </Switch>
    );
  }
}

export default UserProfileContainer;