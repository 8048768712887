import { Button, withStyles } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';

export const SuccessButton = withStyles(theme => ({
  root: {
    color          : '#FFF',
    // color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover'      : {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const DangerButton = withStyles(theme => ({
  root: {
    color          : theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover'      : {
      backgroundColor: red[700],
    },
  },
}))(Button);

export const InfoButton = withStyles(theme => ({
  root: {
    color          : theme.palette.getContrastText(grey[500]),
    backgroundColor: '#f1f1f1',
    '&:hover'      : {
      backgroundColor: '#c1c1c1',
    },
  },
}))(Button);