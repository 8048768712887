import React, { Component } from 'react';
import UserProfile from '../../components/UserProfile/UserProfile';
import { PeopleService } from '../../services/PeopleService';
import { NotificationService } from '../../services/NotificationService';
import { Route, Switch } from 'react-router-dom';
import ContractorProfileContainer from '../ContractorProfileContainer';
import InviteSubcontractorsContainer from '../InviteSubcontractors/InviteSubcontractorsContainer';

class OnboardContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // user data
    }

    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.getUserData()
  }

  async getUserData () {
    let resp = await this.peopleService.getLoggedInUser()
    this.setState(resp.parsed.data)
  }

  async handleSubmit (formValues) {
    let resp = await this.peopleService.patch(formValues)

    if (resp.status >= 200 && resp.status < 300) {
      this.notificationService.success('Updated!')

      if (formValues.userType === 'owner')
        window.app.history.navigateByUrl('/owner/projects/add');
      else if (formValues.userType === 'gc')
        window.app.history.navigateByUrl('/user/onboard/gc/professional-profile');
      else if (formValues.userType === 'subcontractor')
        window.app.history.navigateByUrl('/user/onboard/subcontractor');
      else if (formValues.userType === 'architect')
        window.app.history.navigateByUrl('/architect/dashboard');
      else if (['retailer', 'manufacturer'].includes(formValues.userType))
        window.app.history.navigateByUrl('/user/onboard/coming-soon');
      else
        window.app.history.navigateByUrl('/'); // dashboard
    } else {
      this.notificationService.error('Error updating account')
    }
  }

  render () {
    return (
      <div>
        <Switch>
          <Route
            path={`/user/onboard/profile`}
            render={() => (
              <UserProfile
                data={this.state}
                onSubmit={this.handleSubmit.bind(this)}
                onChange={(data) => this.setState(data)}
                submitLabel={this.state.userType === 'subcontractor' ? 'Next' : 'Submit'}
              />)}
          />

          <Route
            path={`/user/onboard/subcontractor`}
            render={(props) => (
              <ContractorProfileContainer
                afterSubmit={(formValues, submitResp) => {
                  if (submitResp.status === 200) {
                    this.props.history.push(`/`) // dashboard
                  }
                }}
              />
            )}
          />

          <Route
            path={`/user/onboard/gc/invite-subcontractors`}
            render={(props) => (
              <InviteSubcontractorsContainer
                onSubmit={(formValues, submitResp) => {
                  if (submitResp.status === 200) {
                    this.props.history.push(`/`) // dashboard
                  }
                }}
              />
            )}
          />

          <Route
            path={`/user/onboard/gc/professional-profile`}
            component={(props) => (
              <ContractorProfileContainer
                afterSubmit={(formValues, submitResp) => {
                  if (submitResp.status === 200) {
                    this.props.history.push(`/user/onboard/gc/invite-subcontractors`)
                  }
                }}
              />
            )}
          />

          <Route
            path={`/user/onboard/coming-soon`}
            render={(props) => (
              <h3 className={`text-center mt-5`}>Coming soon...</h3>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default OnboardContainer;