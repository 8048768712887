import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
} from "reactstrap";

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import * as _ from 'lodash'
import ValidationService from '../services/ValidationService';
import Link from '../components/Link';
import { PeopleService } from '../services/PeopleService';
import { NotificationService } from '../services/NotificationService';
import { withLocationQuery } from '../utils/withLocationQuery';

class ResetPassword extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      fields: {
        password: {
          value: '',
          validation: ['required']
        },
        password2: {
          value: '',
          validation: ['required']
        },
      },

      isFormValid: false,
      runValidations: false,
      messageSent: false
    }

    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
    this.validationService = new ValidationService()
  }

  getErrorMessages(value, rules) {
    if (this.state.runValidations) {
      return this.validationService.getErrorMessages(value, rules)
    }
    return []
  }

  validateAllFields() {
    let errors = 0
    _.each(this.state.fields, (fieldData, fieldName) => {
      if (this.validationService.getErrorMessages(fieldData.value, fieldData.validation).length > 0) {
        errors++
      }
    })

    return errors === 0
  }

  async send(e){
    e.preventDefault()

    this.setState({runValidations: true})

    if (this.validateAllFields()) {
      if (this.state.fields.password.value !== this.state.fields.password2.value)
        return this.notificationService.error('Passwords didn\'t match')

      const accessToken = this.props.locationQuery.get('access_token')
      let resp = await this.peopleService.resetPasswordWithToken(this.state.fields.password.value, accessToken)

      if (resp.status === 200) {
        this.notificationService.success('Password was reset')
        setTimeout(() => {
          window.app.history.push('/login')
        }, 5 * 1000)
      }
      else {
        this.notificationService.error('Error resetting your password')
      }

      this.setState({messageSent: true})
    }
  }

  /**
   *  This extra `updateField` is necessary along with `validateAllFields` and a diff structure for the state fields
   *  if we want to support <Form> hitting enter and using the browsers' default behavior.
   *  The same can be accomplished by capturing the Enter key onKeyUp/onKeyDown on each of the input fields.
   */
  updateField(fieldData) {
    let fields = _.merge({}, this.state.fields, fieldData)
    this.setState({fields})
  }

  render() {
    return (
      <>
        <div className="ResetPassword container-fluid mt-5 ">
          <Row className="h-100 align-items-center">
            <Col sm="12" md="4" className="m-auto">
              <Card>
                <CardHeader>
                  <h1 className="puente-light text-center">
                    <Link href="/login">PrismBid</Link>
                  </h1>
                  <h3>Reset Password</h3>
                  <p>Please, provide the email you used to sign up to recover your password.</p>
                </CardHeader>
                <CardBody>
                    <Row className={`mt-3`}>
                      <Col>
                        <TextField
                          type="password"
                          error={!!_.size(this.getErrorMessages(this.state.fields.password.value, this.state.fields.password.validation))}
                          helperText={this.getErrorMessages(this.state.fields.password.value, this.state.fields.password.validation)}
                          label="New Password"
                          fullWidth
                          value={this.state.fields.password.value}
                          onChange={(e) => this.updateField({password: {value: e.target.value}})}
                          variant="outlined"
                          onKeyUp={(e) => e.key === 'Enter' ? this.send(e) : null}
                        />
                      </Col>
                    </Row>
                    <Row className={`mt-3`}>
                      <Col>
                        <TextField
                          type="password"
                          error={!!_.size(this.getErrorMessages(this.state.fields.password2.value, this.state.fields.password2.validation))}
                          helperText={this.getErrorMessages(this.state.fields.password2.value, this.state.fields.password2.validation)}
                          label="Confirm Password"
                          fullWidth
                          value={this.state.fields.password2.value}
                          onChange={(e) => this.updateField({password2: {value: e.target.value}})}
                          variant="outlined"
                          onKeyUp={(e) => e.key === 'Enter' ? this.send(e) : null}
                        />
                      </Col>
                    </Row>
                </CardBody>
                <CardFooter className={`text-center mb-2`}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.send(e)}
                    size="large"
                    hidden={this.state.messageSent}
                  >
                    Update Password
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withLocationQuery(ResetPassword);
