import React, { Component } from 'react';
import ListProjectsTable from '../Project/ListProjectsTable';
import { PeopleService } from '../../services/PeopleService';
import Link from '../Link';
import { Button } from '@material-ui/core';
import { NotificationService } from '../../services/NotificationService';

class ListPreferredSubcontractorsContainer extends Component {

  constructor (props) {
    super(props);
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
    this.state = {
      data: [],
    }
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    let resp = await this.peopleService.getPreferredSubs()
    if (resp.status === 200)
      this.setState({data: resp.parsed.data})
    else
      this.notificationService.error('Error getting preferred subcontractors')
  }

  async handleRemoveFav (favData) {
    if (!window.confirm('Are you sure?'))
      return

    let resp = await this.peopleService.deletePreferredSub(favData.id)
    if (resp.status === 204) {
      this.notificationService.success('Deleted!')
      this.getData()
    } else
      this.notificationService.error('Error getting preferred subcontractors')
  }

  render () {
    return (
      <ListProjectsTable
        data={this.state.data}
        cols={[
          {
            label  : 'Full Name',
            process: (fieldValue, row) => (
              row.user.firstName
                ? <Link href={`/user/profile/${row.id}`}>{row.user.firstName} {row.user.lastName}</Link>
                : 'N/A'
            ),
          },
          {
            label  : 'Email',
            process: (fieldValue, row) => (<a href={`mailto:${row.user.email}`} target="_blank" rel="noopener noreferrer">{row.user.email}</a>),
          },
          {
            label  : '',
            process: (fieldValue, row) => (
              <Button
                size={'small'}
                variant={'outlined'}
                color={'secondary'}
                className={`mr-1`}
                onClick={() => this.handleRemoveFav(row)}
              >
                <span className="fa fa-minus pr-2"></span> Remove
              </Button>
            ),
          },
        ]}
      />
    );
  }
}

export default ListPreferredSubcontractorsContainer;