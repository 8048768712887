import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form'
import { Button, TextField } from '@material-ui/core';
import ValidationService from '../../services/ValidationService';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import * as _ from 'lodash'

let _form, _fields

class InviteSubcontractors extends Component {

  render () {
    return (
      <div className={`InviteSubcontractors`}>
        <Row>
          <Col lg={7} md={7} sm={12} className={`m-auto`}>
            <Card>
              <CardHeader className={`border-bottom mb-3`}>
                <CardTitle tag="h2">Invite Subcontractors</CardTitle>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={this.props.onSubmit.bind(this)}
                  initialValues={{
                    subcontractors: _.size(this.props.data) ? this.props.data : [{firstName: '', lastName: '', email: ''}]
                  }}
                  mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                  }}
                  render={({
                             handleSubmit,
                             form: {
                               mutators: {
                                 pop, push,
                               },
                             },
                             form,
                           }) => {
                    _form = form
                    return (
                      <form onSubmit={handleSubmit}>
                        <FieldArray name="subcontractors">
                          {({fields}) => {
                            _fields = fields
                            return (
                              <>
                                {fields.map((subcontractor, index) => (
                                  <Row key={subcontractor}>
                                    <Col md={3}>
                                      <Field
                                        name={`${subcontractor}.firstName`}
                                        validate={ValidationService.instance().required}
                                      >
                                        {({input, meta}) => (
                                          <>
                                            <TextField
                                              {...input}
                                              label="First Name"
                                              variant={'outlined'}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              fullWidth={true}
                                              error={meta.error && meta.touched}
                                              helperText={meta.error && meta.touched && meta.error}
                                            />
                                          </>
                                        )}
                                      </Field>
                                    </Col>
                                    <Col md={3}>
                                      <Field
                                        name={`${subcontractor}.lastName`}
                                        validate={ValidationService.instance().required}
                                      >
                                        {({input, meta}) => (
                                          <>
                                            <TextField
                                              {...input}
                                              label="Last Name"
                                              variant={'outlined'}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              fullWidth={true}
                                              error={meta.error && meta.touched}
                                              helperText={meta.error && meta.touched && meta.error}
                                            />
                                          </>
                                        )}
                                      </Field>
                                    </Col>

                                    <Col md={5}>
                                      <Field
                                        name={`${subcontractor}.email`}
                                        validate={ValidationService.instance().required}
                                      >
                                        {({input, meta}) => (
                                          <>
                                            <TextField
                                              {...input}
                                              label="Email"
                                              variant={'outlined'}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              fullWidth={true}
                                              error={meta.error && meta.touched}
                                              helperText={meta.error && meta.touched && meta.error}
                                            />
                                          </>
                                        )}
                                      </Field>
                                    </Col>

                                    <Col md={1} className={`text-center pt-2`}>

                                      {index === fields.length - 1
                                        ? <Button
                                          variant={'text'}
                                          color={'primary'}
                                          onClick={() => _fields.push({name: '', email: ''})}
                                        >
                                          Add
                                        </Button>
                                        : <Button
                                          variant={'text'}
                                          color={'primary'}
                                          onClick={() => fields.remove(index)}
                                        >&times;</Button>}
                                    </Col>
                                  </Row>
                                ))}
                              </>
                            )
                          }}

                        </FieldArray>
                      </form>
                    )
                  }}
                />
              </CardBody>
              <CardFooter className={`border-top`}>
                <Button variant={'contained'} color={'secondary'} onClick={() => _form.submit()}
                        className={`pull-right`}>
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

InviteSubcontractors.propTypes = {
  data: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InviteSubcontractors;