import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import Link from '../components/Link';

export default class Terms extends React.Component {

  render() {
    return (
      <>
        <div className="ForgotPassword container-fluid mt-5 ">
          <Row className="h-100 align-items-center">
            <Col sm="12" md="10" className="m-auto">
              <Card>
                <CardHeader>
                  <h1 className="puente-light text-center">
                    <Link href="/login">PrismBid</Link>
                  </h1>
                  <h3>Terms of Service</h3>
                </CardHeader>
                <CardBody>
                  <div className="register-container terms full-height sm-p-t-30">
                    <div className="container-sm-height full-height">
                      <div className="row row-sm-height">
                        <div className="col-sm-12 col-sm-height col-middle">
                          <div>
                            <h3 className={`m-0`}><b>BidRight, LLC</b></h3>
                            <div>One Financial Plaza</div>
                            <div>100 SE 3rd Ave, 10 th floor</div>
                            <div>Ft Lauderdale, FL 33394</div>
                            <br/>
                            <div className="m-t-20">
                              <ul>
                                <li>
                                  <b>I. INTRODUCTION</b>

                                  <ul>
                                    <li>
                                      A. Website Ownership
                                      <ol>
                                        <li>This website and the services provided thereon are owned and operated by
                                          BidRight, LLC(hereinafter referred to as “company” or “our” or “us” or “we” or
                                          “BidRight”), and your use of our Website (www.prismbid.com) is subject to the
                                          following Terms of Use (hereinafter referred to as “Terms”), which may be
                                          updated by us at any timewith or without notice to you.
                                        </li>
                                        <li>These terms of use constitute a contract or agreement between you, the user,
                                          and <b>BidRight, LLC</b>.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      B. Agreement by Use
                                      <ol>
                                        <li>
                                          By using our website (www.prismbid.com), you are agreeing to be bound by and
                                          accept the following Terms. Acceptance of the Terms is absolute and applies to
                                          you as a user, regardless of the number of accounts that you create. You, as a
                                          user, are solely responsible for all accounts belonging to you and you promise
                                          adherence to these Terms of Service by any and all accounts belonging to you.
                                          <ul>
                                            <li>a. You must read and agree to the Terms in order to use our website
                                              and/or services.
                                            </li>
                                            <li>b. If you do not agree with the Terms, you may not use our services.
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          Violation of any of the terms below may result in the immediate termination of
                                          your Account without further warning. We are not responsible for any and all
                                          content posted on our website by a third party. By using our website, you
                                          agree that you are doing so at your own risk.
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <b>II. INTELLECTUAL PROPERTY</b>

                                  <ul>
                                    <li>
                                      A. The contents of our website are our copyrighted property and/or the copyrighted
                                      property of our licensors or licensees.
                                    </li>

                                    <li>
                                      B. All trademarks, service marks, trade names, trade dress and other intellectual
                                      property rights contained in our services are owned by us and/or our licensors or
                                      licensees.
                                    </li>

                                    <li>
                                      C. Unless specifically agreed to in writing, no element of our Website (may be
                                      used or exploited in any way other than as part of the specific services offered
                                      to you.
                                    </li>

                                    <li>
                                      D. Our company retains full and complete ownership of the content on our services,
                                      and we do not transfer title to any portion of our intellectual property to you.
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <b>
                                    III. USER REQUIREMENTS
                                  </b>

                                  <ul>
                                    <li>
                                      A. Age

                                      <ol>
                                        <li>You must be at least 18 years old to create an account on or otherwise use
                                          our services.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      B. No Bots Permitted

                                      <ul>
                                        <li>You must be a human or registered business entity in order to use our
                                          services. No bot use is permitted.
                                        </li>
                                      </ul>
                                    </li>

                                    <li>
                                      C. Information Provided

                                      <ul>
                                        <li>You must provide all of the required information to sign up for an account
                                          on our services.
                                        </li>
                                      </ul>
                                    </li>

                                    <li>
                                      D. Login Restrictions

                                      <ol>
                                        <li>
                                          Login Information Security

                                          <ul>
                                            <li>a. You are solely responsible for maintaining the confidentiality of
                                              your login information, and you agree not to share or otherwise make
                                              public your login information.
                                            </li>
                                            <li>b. You must properly exit out of your account when you are finished with
                                              each session.
                                            </li>
                                            <li>c. You must notify us immediately upon discovery of a breach of security
                                              and/or confidentiality of your login information.
                                            </li>
                                          </ul>
                                        </li>

                                        <li>
                                          Responsibility

                                          <ul>
                                            <li>a. You are solely responsible for any and all activity that occurs on
                                              your account, even if the activity is generated on your account by a third
                                              party.
                                            </li>
                                          </ul>
                                        </li>

                                      </ol>
                                    </li>

                                    <li>
                                      E. No Assignment of Accounts

                                      <ol>
                                        <li>Assignment and/or transfer of accounts is strictly prohibited.</li>
                                      </ol>
                                    </li>

                                    <li>
                                      F. Use for Legal Purposes Only

                                      <ol>
                                        <li>You may only use our services for legal and authorized purposes.</li>
                                        <li>You must not use our services to violate any law in any jurisdiction.</li>
                                      </ol>
                                    </li>


                                  </ul>
                                </li>

                                <li>
                                  <b>IV. VIOLATION OF TERMS</b>

                                  <ul>
                                    <li>
                                      A. Governing Law and Venue and Waiver

                                      <ol>
                                        <li>This agreement is governed by the law of the State of Florida.</li>
                                        <li>Venue for any and all disputes that arise under this agreement is Broward
                                          County, Florida.
                                        </li>
                                        <li>The failure of our company to exercise or enforce any rights contained
                                          within these Terms does not constitute a waiver of those rights.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      B. Cancellation of Account

                                      <ol>
                                        <li>Failure to comply with these Terms may result in immediate termination of
                                          your account.
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <b>
                                    V. COMMUNITY GUIDELINES AND SERVICES PROVIDED
                                  </b>

                                  <ul>
                                    <li>
                                      A. Our Services

                                      <ol>
                                        <li>Unless otherwise provided in this Agreement, BidRight, LLC services are
                                          provided to the customers as a service to connect consumers with contractors
                                          and/or other construction professionals.BidRight, LLC assumes no
                                          responsibility for the contractor(s) and/or construction professionals and/or
                                          personnel that you select, nor does BidRight, LLC assume any responsibility
                                          for any services provided to you by any entity and/or individual found through
                                          the BidRight, LLC platform.
                                        </li>
                                        <li>BidRight, LLC is in no way connected with or otherwise affiliated with Prism
                                          Architecture, LLC and/or Prism Build, LLC and/or any individual owners
                                          thereof.
                                        </li>
                                        <li>By using BidRight, LLC services, you agree to indemnify and/or hold harmless
                                          BidRight, LLC and Prism Architecture, LLC and Prism Build, LLC and the
                                          officers, directors, employees, agents, stockholders, affiliates,
                                          subcontractors, independent contractors, customers, and clients of BidRight,
                                          LLC and Prism Architecture, LLC and Prism Build, LLC respectively against all
                                          claims, allegations, actions, lawsuits, demands, damages, liabilities,
                                          obligations, losses, settlements, judgments, costs, and expenses (including
                                          but not limited to attorney’s fees) which arise out of or otherwise relate to
                                          your use of BidRight, LLC.
                                        </li>
                                        <li>Subject to your compliance with this Agreement and your payment of any
                                          applicable fees, the Company grants you a non-transferable, non-exclusive,
                                          worldwide right to access and use the BidRight, LLCproducts and/or services.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      B. Usage and Security

                                      <ol>
                                        <li>
                                          BidRight’s products and/or services may be subject to usage limits and
                                          restrictions, which may be specified in the Subscription Agreement
                                          (incorporated by reference herein) including, but not limited to:

                                          <ul>
                                            <li>a. The locations at or through which you can use BidRight’s products
                                              and/or services;
                                            </li>
                                            <li>b. The number of allowable users, usage volumes, and/or limits on the
                                              number of recipients.
                                            </li>
                                          </ul>
                                        </li>

                                        <li>
                                          By using BidRight’s products and/or services, you agree to use BidRight’s
                                          products and/or services within the usage limits set out in the Subscription
                                          Agreement.
                                        </li>

                                        <li>
                                          It is your responsibility to ensure that you do not exceed those limits and/or
                                          restrictions.
                                        </li>

                                        <li>
                                          BidRight, LLChas the exclusive right, but not the obligation, to monitor
                                          and/or remotely audit your use of any product and/or service provided to you
                                          by BidRight.
                                        </li>

                                        <li>
                                          Please refer to BidRight’s Privacy Policy (incorporated by reference herein)
                                          for additional details regarding information usage and security.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      C. Third Parties

                                      <ol>
                                        <li>You acknowledge and understand that our company may use third party vendors
                                          to service and/or maintain our services.
                                        </li>
                                        <li>You acknowledge and agree that our company is not responsible for the acts
                                          and/or omissions of any third party, nor does our company guarantee any
                                          services provided by a third party.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      D. Service Materials and Non-Liability

                                      <ol>
                                        <li>Please note that any documents and/or other materials available through
                                          BidRight, LLC and/or any advice provided by BidRight, LLC, (collectively, the
                                          "Service Materials") are for informational and/or educational purposes only,
                                          and they are not guaranteed to be correct, complete or up-to-date.
                                        </li>
                                        <li>You are solely responsible for the choosing and/or hiring of any/all
                                          contractors. BidRight, LLC does not guarantee satisfaction or accuracy of any
                                          end product or work produced by any person or entity that you hire or
                                          otherwise work with as a result of BidRight, LLC.
                                        </li>
                                        <li>You understand that BidRight, LLC providing products and/or services to you
                                          does not constitute any guarantee of a particular outcome or otherwise make us
                                          responsible in any way for the success or failure of any contractor and/or
                                          entity you hire or otherwise work with in connection with BidRight LLC’s
                                          products and/or services. By using the BidRight, LLC services and/or platform
                                          you hereby waive any and all rights to assert any claims against BidRight, LLC
                                          arising from or otherwise associated with your use of the BidRight website,
                                          services, and/or platform or the individuals and/or entities that you connect
                                          with and/or hire through BidRight.
                                        </li>
                                        <li>While BidRight attempts to confirm the active license status of any/all
                                          contractors and/or construction professionals and/or personnel utilizing the
                                          BidRight platform, BidRight in no way guarantees the licensing status. It is
                                          the sole responsibility of the contractors and/or construction professionals
                                          and/or personnel utilizing the BidRight platform to maintain the appropriate
                                          certifications and/or licensures. BidRight strongly recommends verifying a
                                          license with your local licensing agency before hiring.
                                        </li>
                                      </ol>
                                    </li>

                                  </ul>
                                </li>

                                <li>
                                  <b>
                                    VI. LINKS TO EXTERNAL WEBSITES
                                  </b>

                                  <ul>
                                    <li>A. Our company is not responsible for any content that you are exposed to and/or
                                      damage that you may sustain resulting from a link on our services to an external
                                      or third party website.
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <b>VII. CHANGES TO TERMS</b>

                                  <ul>
                                    <li>A. Our company may change or alter these Terms at any time without notice.</li>
                                  </ul>
                                </li>

                                <li>
                                  <b>VIII. PAYMENTS AND TERM</b>

                                  <ul>
                                    <li>
                                      A. Fees

                                      <ol>
                                        <li>You agree to pay all fees, if any, associated with your use of BidRight,
                                          LLC, attached hereto
                                          and incorporated by reference herein.
                                        </li>
                                        <li>All fees are non-refundable and non-transferable.</li>
                                        <li>Additional fees may apply.
                                          <ul>
                                            <li>
                                              a. Additional fees will be applied at the current fee rate at the time
                                              when you exceed your usage limits.
                                            </li>
                                          </ul>
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      B. Payments

                                      <ol>
                                        <li>Your payment is due upon receipt of invoice.
                                        </li>
                                        <li>Payment may be made via one or more of the following methods:
                                          <ul>
                                            <li>a. Credit Card;</li>
                                            <li>b. Debit Card</li>
                                          </ul>
                                        </li>
                                        <li>
                                          You represent and warrant that any and all payment information provided by you
                                          to us is accurate, that you are authorized to use the payment method, and that
                                          you will notify us immediately should any changes to your payment method
                                          occur.
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <b>IX. ENTIRETY, SEVERABILITY, TERM, AND TERMINATION</b>

                                  <ul>
                                    <li>A. ENTIRETY
                                      <ol>
                                        <li>This agreement and the documents referenced and incorporated above
                                          constitute the entire agreement by the parties. No other statement, agreement,
                                          and/or promise made on or before the effective date of this agreement shall be
                                          binding on the parties.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>B. SEVERABILITY

                                      <ol>
                                        <li>In the event that any provision of this agreement is deemed invalid and/or
                                          unenforceable in whole or in part for any reason, the remainder of that
                                          provision (if applicable) and the remaining portions of the entire agreement
                                          will be severable and remain in effect and enforceable.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      C. TERM

                                      <ol>
                                        <li>The term of this agreement begins on the effective date of the subscription
                                          agreement attached hereto and incorporated by reference herein and extends
                                          through and including the cancellation and/or termination date of the
                                          agreement
                                        </li>
                                        <li>This agreement shall continue in effect until a party terminates the
                                          agreement in accordance with this the termination provision of this agreement.
                                        </li>
                                      </ol>
                                    </li>

                                    <li>
                                      D. TERMINATION

                                      <ol>
                                        <li>
                                          How we handle the termination

                                          <ul>
                                            <li>a. You, the Customer, can cancel your account at any time.
                                            </li>
                                            <li>b. You, the Customer,must contact customer service at
                                              customerservice@prismbid.com to cancel your account.
                                            </li>
                                            <li>c. Upon receipt of a cancellation request, we will verify that the
                                              user/person/entity attempting cancellation is in fact a legal
                                              representative of You and/or Your Company in an effort to prevent
                                              fraudulent adjustments to the account.
                                            </li>
                                          </ul>
                                        </li>
                                      </ol>
                                    </li>

                                  </ul>

                                </li>

                                <li>
                                  <b>X. TECHNICAL SUPPORT</b>

                                  <ul>
                                    <li>
                                      A. Please use the following contact information if technical support associated
                                      with our services is needed: technicalsupport@prismbid.com
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <b>XI. NOTICE TO INTERNATIONAL USERS</b>

                                  <p>
                                    BidRight, and the services and/or products provided by BidRight, are controlled,
                                    operated and administered by BidRight, LLC(or its licensees) from its offices within
                                    the United States of America, and is not intended to subject BidRight, LLCto the
                                    laws or jurisdiction of any state, country and/or territory other than those of the
                                    United States of America.
                                  </p>

                                  <p>
                                    Those who choose to access BidRight, and the services and/or products provided by
                                    BidRight, LLCdo so on their own initiative and at their own risk, and are
                                    responsible for complying with all local laws, rules and regulations. You are also
                                    subject to the usage and/or export laws of the United States of America, and are
                                    responsible for any violations of such laws and/or regulations, including but not
                                    limited to federal rules and regulations restricting exports. Additional charges
                                    such as customs, fees, taxes, and import duties are the responsibility of the
                                    purchaser. Without limiting the foregoing, BidRight, LLCmay limit the availability
                                    of BidRight, and the services and/or products provided by BidRight, in whole or in
                                    part, to any person, geographic area or jurisdiction we choose, at any time and in
                                    our sole discretion and without further notice.
                                  </p>
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}