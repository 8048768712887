import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { PeopleService } from '../services/PeopleService';
import ContractorProfile from '../components/UserProfile/ContractorProfile';
import { NotificationService } from '../services/NotificationService';
import * as _ from 'lodash'

class ContractorProfileContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.notificationService = new NotificationService()
    this.peopleService = new PeopleService()
  }

  async componentDidMount () {
    await this.getUserData()
  }

  async getUserData(){
    if (this.props.getInitialData && _.isFunction(this.props.getInitialData))
      return this.props.getInitialData(this)
    this.setState(this.peopleService.me())

    // let resp = await this.peopleService.getLoggedInUser()
    // this.setState(resp.parsed.data)
  }

  async handleSubmit(formValues){
    if (_.isFunction(this.props.onSubmit))
      return this.props.onSubmit(this, formValues)

    formValues.userId = await this.peopleService.me().userId
    let resp = await this.peopleService.patch(formValues)

    this.props.afterSubmit && this.props.afterSubmit(formValues, resp)

    if (resp.status === 200){
      this.notificationService.success('Updated!')
    } else {
      this.notificationService.error('There was an error updating your profile. Please check your answers and try again.')
    }
  }

  getTitle(){
    switch (this.state.userType) {
      case  'gc':
        return 'General Contractor Profile'
      case 'subcontractor':
        return 'Subcontractor Profile'
      case 'architect':
        return 'Architect Profile'
      case 'retailer':
        return 'Retailer Profile'
      case 'manufacturer':
        return 'Manufacturer Profile'
      default:
        return 'Admin'
    }
  }

  render () {
    return (
      <div className={`ContractorProfileContainer`}>
        <ContractorProfile
          data={this.state}
          onChange={(data) => this.setState(data)}
          onSubmit={this.handleSubmit.bind(this)}
          submitLabel={this.props.submitLabel}
          formTitle={this.getTitle()}
        />
      </div>
    );
  }
}

ContractorProfileContainer.defaultProps = {
  getInitialData: null,
}

ContractorProfileContainer.propTypes = {
  onSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
  getInitialData: PropTypes.func,
}

export default ContractorProfileContainer;