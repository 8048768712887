import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
} from "reactstrap";

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core'

import * as _ from 'lodash'
import ValidationService from '../services/ValidationService';
import Link from '../components/Link';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types'
import { PeopleService } from '../services/PeopleService';
import { NotificationService } from '../services/NotificationService';
import { Form, Field } from 'react-final-form'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      style={{width: '100%'}}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

let _form

class Support extends React.Component {

  constructor (props) {
    super(props)
    this.state = this.getInitialState()
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
  }

  getInitialState(){
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    }
  }

  async send(formData){
    let resp = await this.peopleService.sendSupportEmail(formData)

    if (resp.status === 200){
      this.notificationService.success('Message sent!')
      _form.reset()
    }
    else
      this.notificationService.error('There was an error sending your message')
  }

  triggerFormSubmit () {
    document.getElementById('contact-form').dispatchEvent(new Event('submit', {cancelable: true}))
  }

  handleSubmit(formData) {
    this.send(formData)

    // other things can be done here in handleSubmit. For now it's just `send`, but in the future it might
    // call props or other methods
  }

  render() {
    return (
      <>
        <div className="Support container-fluid mt-5 ">
          <Row className="h-100 align-items-center">
            <Col sm="12" md="4" className="m-auto">
              <Card>
                <CardHeader>
                  <h1 className="puente-light text-center">
                    <Link href="/login">PrismBid</Link>
                    {/*<a href="/login" onClick={(e) => this.goTo('/login', e)}>PrismBid</a>*/}
                  </h1>
                  <h3>Contact support</h3>
                  <p>At PrismBid, we care about our customer service. Please, let us know what can we do to better serve you.</p>
                </CardHeader>
                <CardBody>
                  <Form
                    initialValues={this.props.data}
                    onSubmit={this.handleSubmit.bind(this)}
                    render={({handleSubmit, form, submitting, pristine, values, invalid, active, reset}) => {
                      _form = form
                      return (
                        <form onSubmit={handleSubmit} id={`contact-form`}>
                          <Row>
                            <Col sm={12} md={6} className={`mt-3`}>
                              <Field
                                name="firstName"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      label={_.startCase(input.name)}
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                    />
                                  </>
                                )}
                              </Field>
                            </Col>
                            <Col sm={12} md={6} className={`mt-3`}>
                              <Field
                                name="lastName"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      label={_.startCase(input.name)}
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                    />
                                  </>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <Row className={`mt-3`}>
                            <Col>

                              <Field
                                name="email"
                                validate={ValidationService.instance().email}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      label={_.startCase(input.name)}
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                    />
                                  </>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <Row className={`mt-3`}>
                            <Col>
                              <Field
                                name="phone"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <FormControl className={'w-100'}>
                                      <InputLabel htmlFor="formatted-text-mask-input" shrink variant="outlined">Phone</InputLabel>
                                      <OutlinedInput
                                        {...input}
                                        label="Phone"
                                        labelWidth={60}
                                        id="formatted-text-mask-input"
                                        inputComponent={TextMaskCustom}
                                        notched={true}
                                      />
                                      {meta.touched && meta.error
                                        ? <FormHelperText error>{meta.error}</FormHelperText> : null
                                      }
                                    </FormControl>
                                  </>
                                )}
                              </Field>


                            </Col>
                          </Row>
                          <Row className={`mt-3`}>
                            <Col>
                              <Field
                                name="message"
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <TextField
                                      {...input}
                                      label={_.startCase(input.name)}
                                      variant={'outlined'}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth={true}
                                      error={meta.error && meta.touched}
                                      helperText={meta.error && meta.touched && meta.error}
                                    />
                                  </>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <input type="submit" hidden={true}/>
                        </form>
                      )
                    }}
                    />
                </CardBody>
                <CardFooter className={`text-center mb-2`}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.triggerFormSubmit.bind(this)}
                    size="large"
                  >
                    Send Message
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Support;
