import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'reactstrap';
import moment from 'moment';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { TextField } from '@material-ui/core';
import classNames from 'classnames'
import { PeopleService } from '../../services/PeopleService';

const Header = styled(Row)`
  background: #fcfcfc;
  padding: 22px 10px 23px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  margin: 0;
`
const StyledAvatar = styled(Avatar)`
  && {
      color: #fafafa;
      background-color: #bdbdbd;
      float: left;
      width: 23px;
      height: 23px;
      font-size: 12px;
      margin-right: 10px;
  }
`
const Body = styled('div')`
  height: 375px;
  margin: 0 0 55px 0;
  overflow-y: auto;
  overflow-x: hidden;
  
  //col
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`

const Footer = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  margin: 0;
  
  input[type="text"] {
    background: #fff;
    padding-right: 50px;
  }
`

const FloatingButton = styled('span')`
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 1;
  font-size: 24px;
  
  &:hover {
    cursor: pointer;
  }
`

const Bubble = styled('div')`
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.3);
  padding: 10px;
  max-width: 70%;
  min-width: 30%;
  position: relative;
  float: right;
  clear: both;
  border-radius: 7px 7px;
  margin: 10px 20px;
  
  /** 
   * css arrow
   */
  
  // default is 'sent'
  &:after, &:before {
    right: auto;
    left: 100%;
    top: 13px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: transparent;
    border-left-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }
  &:before {
    border-color: rgba(204, 204, 204, 0);
    border-left-color: #ccc;
    border-right-color: transparent;
    border-width: 9px;
    margin-top: -9px; 
  }
  
  // changes for 'received'
  &.received {
    float: left;
    clear: both;
    
    &:after, &:before {
      left: auto;
      right: 100%;
    }
    
    &:after {
      border-left-color: transparent;
      border-right-color: #fff;
    }
    &:before {
      border-left-color: transparent;
      border-right-color: #ccc;
    }
  }
`

const peopleService = new PeopleService()

const ActiveConversation = (props) => {
  const {data, onSend} = props
  const handleSend = (value) => {
    onSend(value)
    setValue('')
  }

  let bodyRef = React.createRef(),
      inputRef = React.createRef(),
      userId = peopleService.me() ? peopleService.me().userId : null

  const [value, setValue] = useState('')

  useEffect(() => {
    if (bodyRef.current){
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight
    }

    if (inputRef.current)
      inputRef.current.focus()
  }, [data])

  return data ?
    <Container className="p-0">
      <Header>
        <Col className="p-0">
          <StyledAvatar>
            {data.firstName[0]}{data.lastName[0]}
          </StyledAvatar>
          {data.firstName} {data.lastName} {data.companyName ? `(${data.companyName})` : ''}
        </Col>
      </Header>
      <Row className="p-0">
        <Body ref={bodyRef}>
          <Container>
            {data.messages && data.messages.map((message, index) => (
              <Row key={index}>
                <Col>
                  <Bubble className={classNames({received: message.fromId !== userId})}>
                    {message.body}
                    <div className="text-right small text-muted">{moment(message.createdAt).format(('MM/D/YY - h:mm A'))}</div>
                  </Bubble>
                </Col>
              </Row>
            ))}
          </Container>
        </Body>
      </Row>
      <Footer>
        <Col className="p-0">
          <TextField
            inputRef={inputRef}
            label=""
            variant={'outlined'}
            fullWidth={true}
            placeholder={`Type a message`}
            className="mb-0"
            autoFocus={true}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSend(value)
              }
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <FloatingButton className="fa fa-angle-right" onClick={() => handleSend(value)}/>
        </Col>
      </Footer>
    </Container>
    : <div className="text-center pt-5 mt-5">You don't have any messages yet.</div>
}

ActiveConversation.props = {
  data  : PropTypes.object, // activeConversation
  onSend: PropTypes.func,
}

export default ActiveConversation