import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { MessageService } from '../../services/MessageService';

const Wrapper = styled('div')`
  background: #fcfcfc;
  min-height: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  transition: left 250ms;
  z-index: 100;
   
  &.isOpen {
    left: 0%;
    box-shadow: -10px 0px 20px rgba(0,0,0,0.5);
  }
`
const CloseButton = styled('span')`
  font-size: 22px;
  margin: 0 10px 0 0;
  cursor: pointer;
`
const ClickableListItem = styled(ListItem)`
  &:hover {
    background: #f1f1f1;
    cursor: pointer;
  }
`

const Contacts = (props) => {
  const [contacts, setContacts] = useState([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    const messageService = new MessageService()

    const getContactsAsync = async () => {
      const contactsResp = await messageService.getContacts()
      return Array.isArray(contactsResp.parsed.data) ? contactsResp.parsed.data : []
    }

    const getData = async () => {
      const contacts = await getContactsAsync()
      setContacts(contacts)
    }
    getData()
  }, [])

  const getFilteredContacts = () => {
    if (searchValue.length)
      return contacts.filter((c) => c.firstName.includes(searchValue)
        || c.lastName.includes(searchValue)
        || c.companyName.includes(searchValue))
    return contacts
  }

  return (
    <Wrapper className={classNames('Contacts border-right', {isOpen: props.isOpen})}>
      <Container>
        <Row className="pt-3 pb-3 pr-3 border-bottom">
          <Col sm="12">
            <h2 className="p-0 m-0">
              <CloseButton className="fa fa-angle-left" onClick={() => {
                props.onClose()
              }}/> New Chat
            </h2>
          </Col>
        </Row>
        <Row className="pt-3 pb-0 border-bottom">
          <Col>
            <TextField
              placeholder="Search"
              variant={'outlined'}
              fullWidth={true}
              size={'small'}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <List>
              {getFilteredContacts().map((contact, index) => (
                <ClickableListItem key={index} onClick={() => {
                  props.onContactSelected(contact)
                  props.onClose()
                }}>
                  <ListItemAvatar>
                    <Avatar>
                      {contact.firstName[0]}{contact.lastName[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${contact.firstName} ${contact.lastName} ${contact.companyName ? `(${contact.companyName})` : ''}`}
                                secondary={contact.companyName}
                  />
                </ClickableListItem>
              ))}

            </List>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

Contacts.propTypes = {
  isOpen : PropTypes.bool,
  onClose: PropTypes.func,
  onContactSelected: PropTypes.func,
};

export default Contacts;