import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import ReceivedEstimates from '../components/Estimates/ReceivedEstimates';
import { Button } from '@material-ui/core';
import { ProjectsService } from '../services/ProjectsService';
import * as _ from 'lodash'
import ListProjectsTable from '../components/Project/ListProjectsTable';
import Link from '../components/Link';
import numeral from 'numeral';
import moment from 'moment';
import classNames from 'classnames';
import { NotificationService } from '../services/NotificationService';

class OwnerDashboard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      projects : [],
      estimates: [],
    }

    this.projectsService = new ProjectsService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.getProjects()
  }

  async getProjects () {
    let resp = await this.projectsService.getProjects()

    let projects = resp.parsed.data
    let estimates = []

    projects.map((project) => {
      if (!_.isUndefined(project.leadBids)) {
        return project.leadBids.map((bid) => {
          return estimates.push(
            {
              projectId     : project.id,
              bidderId      : bid.id,
              name          : bid.user.companyName
                ? bid.user.companyName
                : `${bid.user.firstName} ${bid.user.lastName}`, // subcontractor name?
              projectName   : project.name,
              projectAddress: `${project.address} ${project.suite ? `, ${project.suite}` : ``}`,
              amount        : bid.bidValue,
              date          : bid.createdAt,
              status        : bid.status,
            },
          )
        })
      }
      return null
    })

    // let estimates = await this.projectsService.getBids() // this is for admin, not for user
    this.setState({projects, estimates})
  }

  calculateBidAverage (row) {
    let bidAverage = 0,
        bidsTotal  = 0
    if (!_.isUndefined(row.leadBids)) {
      row.leadBids.map((bid) => bidsTotal += bid.bidValue)
      bidAverage = bidsTotal / row.leadBids.length
    }
    return bidAverage
  }

  async handleAcceptBid ({projectId, bidderId}) {
    let resp = await this.projectsService.acceptBid(projectId, bidderId)

    if (resp.status === 200) {
      this.notificationService.success('Bid Accepted!')
      this.getProjects()
    } else {
      this.notificationService.error(
        'There was an error accepting the bid bid. Please check try again.')
    }
  }

  render () {
    return (
      <>
        <div className="OwnerDashboard content container-fluid">
          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">CONSTRUCTION ESTIMATES</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReceivedEstimates data={this.state.estimates} acceptBid={this.handleAcceptBid.bind(this)}/>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">
                    PROJECTS
                    <Button variant={'contained'} color={'secondary'} className={`pull-right`}
                            onClick={() => window.app.history.push('/owner/projects/add')}>
                      Add project
                    </Button>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ListProjectsTable
                    data={this.state.projects}
                    cols={[
                      {
                        label  : 'Project Name',
                        process: (fieldValue, row) => (
                          <Link href={`/owner/projects/${row.id}`}>{row.name || 'N/A'}</Link>),
                      }, {
                        label  : 'Construction Budget',
                        process: (fieldValue, row) => (
                          <span>{row.budget ? numeral(parseFloat(row.budget)).format('$0,0.00') : 'N/A'}</span>),
                      }, {
                        label  : 'Bid Average',
                        process: (fieldValue, row) => {
                          let bidAverage = this.calculateBidAverage(row)
                          return (<>{bidAverage ? numeral(parseFloat(bidAverage)).format('$0,0.00') : 'N/A'}</>)
                        },
                      }, {
                        label  : <>Budget % <span className="fa fa-question-o"></span></>,
                        process: (fieldValue, row) => {
                          let budgetPercentage = this.calculateBidAverage(row) / row.budget
                          return (
                            <Link
                              href={`/owner/projects/${row.id}`}
                              className={classNames({
                                'text-info'  : budgetPercentage <= 1,
                                'text-danger': budgetPercentage > 1,
                              })}
                            >
                              {budgetPercentage ? numeral(budgetPercentage).format('0%') : 'N/A'}
                            </Link>
                          )
                        },
                      }, {
                        label  : 'Bid Due Date',
                        process: (fieldValue, row) => (moment(row.bidDue).format('MM/DD/YY')),
                      }, {
                        label  : 'Plans',
                        process: (fieldValue, row) => (
                          <span>{row.filesCount ? <span className="text-success">Yes</span> : <span
                            className="text-danger">No</span>}</span>
                        ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Materials</CardTitle>
                </CardHeader>
                <CardBody>
                  <h3 className={`text-muted`}>Coming soon</h3>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">
                    Services
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <h3 className={`text-muted`}>Coming soon</h3>
                </CardBody>
              </Card>
            </Col>
            <Col md="2" className={`text-center`}>
              <Card color={'secondary'}>
                <CardBody style={{height: 313}}>
                  <div className={` pt-5 mt-5`}>AD</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default OwnerDashboard;
