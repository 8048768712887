import React, { Component } from 'react';
import { Form, Field } from 'react-final-form'
import ValidationService from '../services/ValidationService';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import * as _ from 'lodash'
import Link from '../components/Link';
import { PeopleService } from '../services/PeopleService';
import { NotificationService } from '../services/NotificationService';

class Register extends Component {

  constructor (props) {
    super(props)
    this.state = {}
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService();
  }

  async handleSubmit (formValues) {
    let resp = {}
    try {
      resp = await this.peopleService.register(formValues)
    } catch (err) {
      resp = err
    }

    if (resp.status === 200) {
      // log the user in
      await this.peopleService.login(formValues)
      window.app.history.push('/user/onboard/profile')
    } else if (resp.status === 422) {
      this.notificationService.error('Email already exists. Please use a different email.')
    }
  }

  render () {
    return (
      <div className="Register content container-fluid">
        <Row className="h-100 align-items-center">
          <Col sm="12" md="4" className="ml-0 ml-md-3">
            <Card>
              <CardHeader className={`pb-2`}>
                <div className="row">
                  <div className="col-sm-1 text-center pt-2">
                    <Link href={`/login`} className="btn btn-link">
                      <span className="fa fa-angle-left"></span>
                    </Link>
                  </div>
                  <div className="col-sm-11">
                    <h1 className={`m-0`}>
                      Create a new account
                    </h1>
                    <small>Fill out the form to get started</small>
                  </div>
                </div>
              </CardHeader>
              <CardBody className={`pl-5 pt-5 pr-5`}>
                <Form
                  onSubmit={this.handleSubmit.bind(this)}
                  render={({form, handleSubmit}) => (
                    <form onSubmit={handleSubmit} id="registration-form">
                      <Row>
                        <Col className="">
                          <FormGroup>
                            <Field
                              name="email"
                              validate={ValidationService.instance().required}
                            >
                              {({input, meta}) => (
                                <>
                                  <TextField
                                    {...input}
                                    label={_.startCase(input.name)}
                                    variant={'outlined'}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <span className="fa fa-envelope"></span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    placeholder="Type your email here"
                                    fullWidth={true}
                                    error={meta.error && meta.touched}
                                    helperText={meta.error && meta.touched && meta.error}
                                    autoFocus={true}
                                  />
                                </>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="">
                          <FormGroup>
                            <Field
                              name="password"
                              type={`password`}
                              validate={ValidationService.instance().required}
                            >
                              {({input, meta}) => (
                                <>
                                  <TextField
                                    {...input}
                                    label={_.startCase(input.name)}
                                    variant={'outlined'}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <span className="fa fa-lock"></span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    placeholder="Password"
                                    fullWidth={true}
                                    error={meta.error && meta.touched}
                                    helperText={meta.error && meta.touched && meta.error}
                                  />
                                </>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            name="terms"
                            type="checkbox"
                            validate={ValidationService.instance().required}
                          >
                            {({input, meta}) => (
                              <>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...input}
                                      value={'yes'}
                                      id="terms"
                                      type="checkbox"
                                    />
                                  }
                                  label={<span>I accept the <Link href={`/terms`}>Terms and Services</Link></span>}
                                />
                                {meta.error && meta.touched
                                  ? <FormHelperText error={true} className={`m-0 pb-2`}>{meta.error}</FormHelperText>
                                  : null}
                              </>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <button type={`submit`} hidden></button>
                    </form>
                  )}
                />
              </CardBody>
              <CardFooter className={`pl-5 pb-5 pr-5`}>
                <div className="row">
                  <div className="col-6 m-auto text-center">
                    <Button className="pull-right" color="secondary" type="button" variant="contained" size="large"
                            fullWidth={true}
                            onClick={() => document.getElementById('registration-form').dispatchEvent(new Event('submit', {cancelable: true}))}>
                      Register
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="4" className="ml-0 ml-md-3">
            <h1 style={{fontSize: 80, color: '#fff'}}>FREE <br/>CONSTRUCTION <br/>ESTIMATES</h1>
          </Col>
        </Row>
      </div>
    );
  }
}

Register.propTypes = {};

export default Register;