import { createStore } from 'redux'

const defaultState = {
  isLoading: false,
  loadingQueue: [],
  lastUserUpdate: null,
  subcontractors: [],
  materials: [],
  services: [],
  ungroupedSubcontractors: []
}

let appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'set':
      // we need to overwrite the state (and not merge) to avoid weird bugs.
      // If we need to merge a value into the state, it has to be done by the component
      state = action.state
      break;
    case 'clear':
      state = defaultState
      break;
    default:
      return state
  }
  
  return state
}

let appStore = createStore(appReducer)

export default appStore
