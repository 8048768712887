import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from './components/Icon';
import { FOLDER } from './utils/constants';
import FileSystemStore, { ROOT_ENTRY } from './FileSystemStore';
import { FileSystemService } from './FileSystemService';
import styled from 'styled-components'
import Add from './components/Add';
import md5 from 'md5';

const LinkButton = styled.button`
  border: none;
  background: transparent;
  color: #1d8cf8;
  
  &:hover {
    color: #1b5db2;
    text-decoration: underline;
  }
`

const StyledContainer = styled.div`
  min-height: 190px;
`

const Container = styled.div`
  display: flex;
`

class FileSystem extends Component {
  constructor (props) {
    super(props);
    this.state = FileSystemStore.getState()
  }

  componentDidMount () {
    this.unsubscribe = FileSystemStore.subscribe(() => {
      this.setState(FileSystemStore.getState())
    })

    setTimeout(() => {
      FileSystemStore.dispatch({
        type: 'set',
        state: {
          ...FileSystemStore.getState(),
          creatorName: this.props.creatorName,
          entries: [ROOT_ENTRY, ...this.props.entries.filter((entry) => entry.path !== '/')],
          entry: ROOT_ENTRY
        }
      })
    })
  }

  componentWillUnmount () {
    this.unsubscribe()
  }

  getCurrentFolderContents(){
    return this.state.entries.filter((entry) => entry.parentPath === this.state.entry.path)
  }

  renderBreadCrumbsLinks(){
    let paths = [ROOT_ENTRY]
    let folders = this.state.entry.path.split('/').slice(1)
    for(let i = 0; i < folders.length; i++) {
      let path = folders[i]
      if (!path)
        continue;

      paths.push({
        path: paths[paths.length - 1].path + (paths[paths.length - 1].path !== '/' ? '/' : '') + path,
        label: path
      })
    }

    return paths.map((pathData, k) => {
      let newEntry = FileSystemStore.getState().entries.filter((entry) => entry.path === pathData.path )[0]
      return (
        <span key={k}>
          <span className={`pl-1 pr-1`}>{pathData.label !== '/' && paths[k - 1] && paths[k - 1].label !== '/' ? '/' : null}</span>
          <LinkButton
            onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            FileSystemService.instance().setCurrentEntry(newEntry)
          }}>
            {pathData.label}
          </LinkButton>
        </span>
    )})
  }

  async saveEntry(value) {
    let entry = {
      ...value,
      parentID: md5(FileSystemStore.getState().entry.path + FOLDER),
      parentPath: FileSystemStore.getState().entry.path,
      path: FileSystemStore.getState().entry.path + (FileSystemStore.getState().entry.path !== '/' ? '/' : '') + value.name
    }

    let wasSubmitSuccessful = await this.props.onAddEntry(entry)

    if (wasSubmitSuccessful) {
      FileSystemStore.dispatch({
        type: 'set',
        state: {
          ...FileSystemStore.getState(),
          entries: [
            ...FileSystemStore.getState().entries,
            entry
          ]
        }
      })
    }
  }

  deleteEntry(entry) {
    if (!window.confirm('Are you sure you want to delete this?'))
      return

    FileSystemService.instance().deleteEntry(entry)
    this.props.onDeleteEntry(entry)
  }

  render () {
    return (
      <StyledContainer>
        <div className={`pt-2 mb-3`}>
          You are here: {FileSystemService.instance().getParentFolder(this.state.entry).path
          ? this.renderBreadCrumbsLinks()
          : 'Home'}
          {!this.getCurrentFolderContents().length ? <div className={`small text-muted`}>This folder is empty </div>: null}
        </div>


        <Container>
          {this.getCurrentFolderContents().map((entry, k) => (
            <Icon
              key={k}
              entry={entry}
              deleteFn={this.props.onDeleteEntry ? (entry) => this.deleteEntry(entry) : false}
              onIconClick={this.props.onIconClick.bind(this)}
            />
          ))}
          {this.props.onAddEntry ? <Add saveEntry={value => this.saveEntry(value)} /> : null}
        </Container>
      </StyledContainer>
    );
  }
}

FileSystem.propTypes = {
  entries: PropTypes.array.isRequired,
  onAddEntry: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onDeleteEntry: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  creatorName: PropTypes.string.isRequired,
}

export default FileSystem;