import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import classNames from 'classnames'

class TitledList extends Component {
  handleListItemOnClick(url, e) {
    e.stopPropagation()
    e.preventDefault()
    window.app.history.navigateByUrl(url)
  }

  render () {
    return (
      <Card className={classNames({'tall-card': this.props.tall})}>
        <CardHeader>
          <h2 className={`mb-2`}>{this.props.title}</h2>
        </CardHeader>
        <CardBody>
          <div className="list-group">
            {this.props.data.map((row, k) => (
              typeof row.count !== 'undefined' && typeof row.url !== 'undefined' ?
              <button
                key={k}
                className="list-group-item list-group-item-action font-weight-normal d-flex justify-content-between align-items-center"
                onClick={this.handleListItemOnClick.bind(this, row.url)}
              >
                {row.title}
                <span className={classNames('badge badge-pill', {
                  'badge-none': !row.count,
                  'badge-success': row.count,
                })}>{row.count}</span>
              </button>
              : <div key={k} className="list-group-item font-weight-normal text-sm-center pt-2 pb-2 font-weight-bold">
                  {row.title}
                </div>
            ))}
          </div>
        </CardBody>
      </Card>

    );
  }
}

TitledList.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default TitledList;