import React from 'react';
import PropTypes from 'prop-types'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { TextField } from '@material-ui/core';
import styled from 'styled-components'

const StyledAutocompleteDropdown = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 100;
  width: 100%;
  max-height: 400px;
  background: #fff;
  border: 1px solid #f1f1f1;
`

const StyledAutocompleteContainer = styled.div`
  position: relative;
`

class LocationSearchInput extends React.Component {
  constructor (props) {
    super(props);
    this.inputName = `_non_standard_name_to_prevent_autofill_${parseInt(Math.random() * 10000000)}`
  }

  handleChange = address => {
    this.props.onChange({target: {value: address}})
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.onGeolocationEnd({latLng, address})
        this.props.onChange({target: {value: address}})
      })
      .catch(error => this.props.onError(error));
  };

  render () {
    let {onGeolocationEnd, onChange, ...props} = this.props

    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <StyledAutocompleteContainer>
            <TextField
              {...props}
              {...getInputProps()}
              onBlur={(e) => this.handleSelect(e.target.value) && getInputProps().onBlur(e) && props.onBlur(e)}
              placeholder={'Search for an address...'}
              name={this.inputName}
            />
            {loading || suggestions.length
              ? <StyledAutocompleteDropdown>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </StyledAutocompleteDropdown>
              : null}
          </StyledAutocompleteContainer>
        )}
      </PlacesAutocomplete>
    );
  }
}

LocationSearchInput.defaultProps = {
  onError: () => {},
}

LocationSearchInput.propTypes = {
  onGeolocationEnd: PropTypes.func.isRequired,
  onError         : PropTypes.func,
}

export default LocationSearchInput