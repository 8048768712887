import React, { Component } from 'react';
import BidForm from '../components/BidForm';
import { ProjectsService } from '../services/ProjectsService';
import { NotificationService } from '../services/NotificationService';

class BidContainer extends Component {

  constructor (props) {
    super(props)
    this.state = {
      project: {}
    }

    this.projectsService = new ProjectsService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.getInitialData()
  }

  async getInitialData(){
    let resp = await this.projectsService.details(this.props.match.params.projectId)
    this.setState({project: resp.data})
  }

  async handleSubmit (form, bidData) {
    let resp = await this.projectsService.bid(this.props.match.params.projectId, bidData.bidAmount, bidData.bidTerms,
      bidData.specificationsDivision)

    if (resp.status === 200) {
      this.notificationService.success('Bid Submitted!')
      this.getInitialData()
      setTimeout(() => form.reset())
    } else {
      this.notificationService.error(
        'There was an error submitting your bid. Please check the information provided and try again.')
    }
  }

  render () {
    return (
      <BidForm
        data={this.state.project}
        onSubmit={this.handleSubmit.bind(this)}
      />
    );
  }
}

BidContainer.propTypes = {};

export default BidContainer;