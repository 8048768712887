import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap';
import { SuccessButton, InfoButton } from '../Buttons';
import { PeopleService } from '../../services/PeopleService';
import { NotificationService } from '../../services/NotificationService';

class ReviewAccount extends Component {

  constructor (props) {
    super(props);
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
  }

  async submit(){
    let resp = await this.peopleService.submitForAccountReview()
    if (resp.status === 200) {
      this.notificationService.success('You account has been approved! Redirecting...')
      setTimeout(async () => {
        // this.peopleService.logout()
        await this.peopleService.refreshUserDataInLocalStorage()
        this.props.history.push('/')
      }, 3000)
    } else {
      this.notificationService.error('There was an error submitting your account. Please try again.')
    }
  }

  render () {
    return (
      <div className="ConfirmAccountCreation container-fluid mt-5 ">
        <Row className="h-100 align-items-center">
          <Col sm="12" md="4" className="m-auto">
            <Card>
              <CardTitle tag="h2" className={`p-3 border-bottom`}>
                Submit account for automatic verification and approval
              </CardTitle>
              <CardBody>
                <p>
                  Click the button below to submit your account for automatic review to get full access to the system.
                </p>
              </CardBody>
              <CardFooter>
                <SuccessButton color={'primary'} variant={'contained'} onClick={this.submit.bind(this)}>
                  Submit
                </SuccessButton>

                <InfoButton
                  color={'secondary'}
                  variant={'contained'}
                  className={`pull-right`}
                  onClick={() => window.app.history.push('/user/profile')}
                >
                  My Profile
                </InfoButton>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>

    );
  }
}

ReviewAccount.propTypes = {};

export default ReviewAccount;