import { ModelService } from './ModelService';
import pluralize from 'pluralize';

export class SubcontractorService extends ModelService {

  modelName = 'Subcontractor'

  find (params) {
    return this.apiService.go('/' +  pluralize.plural(this.modelName) + `?skip=0&limit=99999`, {
      method: 'GET',
    });
  }

}