import React, { Component } from 'react';
import { ProjectsService } from '../../services/ProjectsService';
import ProjectForm from './ProjectForm';
import { StorageService } from '../../services/StorageService';
import { ContainersService } from '../../services/ContainerService';

class AddProjectContainer extends Component {
  constructor (props) {
    super(props);
    this.state = {
    }

    this.projectsService = new ProjectsService()
    this.storageService = new StorageService()
    this.containersService = new ContainersService()
  }

  onChange(data) {
    this.setState(data)
  }

  async onSubmit(formValues, acceptedFiles) {
    let projectResp
    try {
      projectResp = await this.projectsService.post(formValues)
    } catch (err){
      console.log({err})
    }

    try {
      if (!projectResp || projectResp.status !== 200)
        return

      if (acceptedFiles.length) {
        let containerName = `${this.storageService.get('user.tokenData.userId')}-${projectResp.parsed.data.id}`
        await this.containersService.uploadFiles(containerName, acceptedFiles)
      }
    } catch (err) {
      console.log({err})
    }

    window.app.history.push('/')
  }

  render () {
    return (
      <ProjectForm
        data={this.state}
        formTitle={'New Project'}
        submitLabel={`Submit`}
        onChange={this.onChange.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        showDocs={false}
      />
    );
  }
}

export default AddProjectContainer;