import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={'(999) 999-9999'}
      maskChar={' '}
      style={{width: '100%'}}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


export default TextMaskCustom