import React, { Component } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import styled from 'styled-components';
import * as qs from 'querystring'

import * as _ from 'lodash'
import { AdminService } from '../../services/AdminService';
import { StorageService } from '../../services/StorageService';
import { DangerButton, SuccessButton } from '../../components/Buttons';
import { ProjectsService } from '../../services/ProjectsService';
import { Link } from 'react-router-dom';
import { NotificationService } from '../../services/NotificationService';
import Pager from '../../components/Pager';

const StyledCardTitle = styled(CardTitle)`
  font-size: 24px;
  margin-bottom: 0px !important;
`

class AdminProjectsContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data   : [],
      page   : 1,
      limit  : 10,
      filters: {},
      total  : 0,
    }

    this.adminService = new AdminService()
    this.storageService = new StorageService()
    this.projectsService = new ProjectsService()
    this.notificationService = new NotificationService()
  }

  componentDidMount () {
    this.processFiltersFromUrl()
    setTimeout(() => this.getData()) // wait for the cycle to update the state
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.page, this.state.page)) {
      this.getData()
    }
  }

  async processFiltersFromUrl () {
    let search = qs.decode(this.props.location.search.replace('?', ''))
    this.setState({
      filters: {
        ...this.state.filters,
        ...search,
      },
    })
  }

  async getData () {
    let resp = await this.adminService.getProjects({
      filter: {
        page    : this.state.page,
        limit   : this.state.limit,
        userType: this.props.match.params.userType,
        ...this.state.filters,
      },
    })

    if (resp.status === 200) {
      this.setState({data: resp.parsed.data.data, total: resp.parsed.data.meta.total})
    } else {
      this.notificationService.error('There was an error retrieving the data')
    }
  }

  async changeStatus (project, status) {
    await this.adminService.updateProjectByUser(project.ownerId, project.id, {status})
    await this.getData()
  }

  handleSearch () {
    this.getData()
  }

  updateFilters (col, searchValue) {
    this.setState({
      filters: _.merge({}, this.state.filters, {[col.field]: searchValue}),
    })
  }

  render () {
    return (
      <div className="AdminProjectsContainer content container-fluid">
        <Card>
          <CardHeader>
            <StyledCardTitle>Projects</StyledCardTitle>
            <small>{this.state.total} records found</small>
          </CardHeader>
          <CardBody>
            <Pager
              className={`pull-right`}
              count={this.state.total}
              perPage={this.state.limit}
              page={this.state.page}
              pagesToShow={3}
              onChange={(page) => {
                this.setState({page})
              }}
            />
            <DataTable
              search={this.handleSearch.bind(this)}
              cols={[
                {
                  field: 'name', process: (fieldName, project) => (
                    <Link to={`/admin/projects/${project.ownerId}/${project.id}`}>{project.name || 'N/A'}</Link>
                  ),
                },

                {field: 'mailingAddress', process: (fieldName, project) => (
                    <Link to={`/admin/projects/${project.ownerId}/${project.id}`}>{project.mailingAddress || 'N/A'}</Link>
                  ),
                },
                {field: 'size', process: (fieldName, project) => (
                    <Link to={`/admin/projects/${project.ownerId}/${project.id}`}>{project.size || 'N/A'}</Link>
                  ),
                },
                {field: 'budget', process: (fieldName, project) => (
                    <Link to={`/admin/projects/${project.ownerId}/${project.id}`}>{project.budget || 'N/A'}</Link>
                  ),
                },
                {field: 'bidStatus'},
                {field: 'status', label: 'Project Status'},
                {
                  field         : 'bids',
                  searchDisabled: true,
                  process       : (fieldName, project) => (
                    <a href={`/admin/projects/${project.ownerId}/${project.id}#project-stats`}>{project.leadBids ? project.leadBids.length : 0}</a>
                  ),
                },
                {
                  field         : 'matches',
                  searchDisabled: true,
                  process       : (fieldName, project) => (
                    <a href={`/admin/projects/${project.ownerId}/${project.id}#project-matches`}>{project.leadBoughtBy ? project.leadBoughtBy.length : 0}</a>
                  ),
                },
                {
                  field         : '',
                  searchDisabled: true,
                  process       : (fieldName, project) => (
                    <>
                      {project.status !== 'approved'
                        ? <SuccessButton color={'primary'} variant={'contained'}
                                         onClick={() => this.changeStatus(project, 'approved')}>Approve</SuccessButton>
                        : <DangerButton color={'secondary'} variant={'contained'}
                                        onClick={() => this.changeStatus(project, 'denied')}>Deny</DangerButton>}
                    </>
                  ),
                },
              ]}
              data={this.state.data}
              filters={this.state.filters}
              updateFilters={this.updateFilters.bind(this)}
            />

            <Pager
              className={`pull-right`}
              count={this.state.total}
              perPage={this.state.limit}
              page={this.state.page}
              pagesToShow={3}
              onChange={(page) => {
                this.setState({page})
              }}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

AdminProjectsContainer.propTypes = {};

export default AdminProjectsContainer;