import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import withModal from '../../elements/Modal';

import { FILE, FOLDER } from '../../utils/constants';

import { Container, Error, Top, Toggle, Form } from './styles';
import { Button } from '@material-ui/core';
import ButtonDropzone from '../ButtonDropzone/ButtonDropzone';
import { FileSystemService } from '../../FileSystemService';

const TodayDate = () => {
  let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

function FileInfo(_props) {
  const [type, handleType] = useState(FILE);
  return (
    <Container>
      <Top>
        <Toggle.Container>
          <Toggle.Option
            className={type === FILE ? 'selected' : ''}
            onClick={() => handleType(FILE)}
          >
            File
          </Toggle.Option>
          <Toggle.Option
            className={type === FOLDER ? 'selected' : ''}
            onClick={() => handleType(FOLDER)}
          >
            Folder
          </Toggle.Option>
        </Toggle.Container>
      </Top>

      {type === FOLDER
      ? <Formik
          initialValues={{
            type: 'file',
            name: '',
            size: 0,
            date: TodayDate()
          }}
          validate={values => {
            let errors = {};
            if (!values.name) {
              errors.name = 'Name is Required';
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            _props.saveEntry({
              ...values,
              type: FOLDER
            });
            _props.closeFn();
          }}
        >
          {props => (
            <Form.Container>
              <div className="formField">
                <Field
                  placeholder="Name"
                  onChange={props.handleChange}
                  name="name"
                  className="field"
                  value={props.values.name}
                  autoComplete="off"
                />
                {props.errors.name && props.touched.name ? (
                  <Error>{props.errors.name}</Error>
                ) : (
                  ''
                )}
              </div>

              <Button
                type="submit"
                variant={'contained'}
                color={'primary'}
                disabled={!props.dirty && !props.isSubmitting}
                onClick={() => {
                  props.handleSubmit(props.values)
                }}
              >
                Create
              </Button>
            </Form.Container>
          )}
        </Formik>
        : <div className={`text-center`}>
          <ButtonDropzone onDrop={(files) => {
            files.map((file) => _props.saveEntry(FileSystemService.instance().fileDataToFileSystem(file), FILE))
          }}/>
        </div>}
    </Container>
  );
}

export default withModal(FileInfo)({});
