import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import * as _ from 'lodash';
import { PeopleService } from '../../services/PeopleService';
import { NotificationService } from '../../services/NotificationService';
import { Button } from '@material-ui/core';

class AdminNotesContainer extends Component {
  constructor (props) {
    super(props);
    this.peopleService = new PeopleService()
    this.notificationService = new NotificationService()
  }

  async handleSubmit(formValues){
    formValues.userId = await this.props.match.params.id
    let resp = await this.peopleService.patch(formValues)

    this.props.afterSubmit && this.props.afterSubmit(formValues, resp)

    if (resp.status === 200){
      this.notificationService.success('Updated!')
      this.props.history.push(`/admin/user/${this.props.match.params.id}`) // dashboard
    } else {
      this.notificationService.error('There was an error updating your profile. Please check your answers and try again.')
    }
  }

  triggerFormSubmit () {
    document.getElementById('user-form').dispatchEvent(new Event('submit', {cancelable: true}))
  }

  render () {
    return (
      <div className="AdminNotesContainer content container-fluid">
        <Row>
          <Col lg={7} md={7} sm={12} className={`m-auto`}>
            <Card>
              <CardHeader className={`border-bottom mb-3`}>
                <CardTitle tag="h2">Admin</CardTitle>
              </CardHeader>
              <CardBody>
                <Form
                  initialValues={this.props.data}
                  onSubmit={this.handleSubmit.bind(this)}
                  render={({handleSubmit, form, submitting, pristine, values, invalid, active, reset}) => {
                    return (
                      <form onSubmit={handleSubmit} id={`user-form`}>
                        <Row>
                          <Col sm={12} md={12} className={``}>
                            <Field
                              name="adminNotes"
                            >
                              {({input, meta}) => (
                                <>
                                  <TextField
                                    {...input}
                                    label={_.startCase(input.name)}
                                    variant={'outlined'}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    fullWidth={true}
                                    error={meta.error && meta.touched}
                                    helperText={meta.error && meta.touched && meta.error}
                                    onChange={(e) => this.props.onChange({[input.name]: e.target.value})}
                                  />
                                </>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </form>
                    )
                  }}
                />


              </CardBody>

              <CardFooter className={`border-top`}>
                <Button variant={'contained'} color={'secondary'} onClick={() => this.triggerFormSubmit()}
                        className={`pull-right`}>
                  SUBMIT
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

AdminNotesContainer.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AdminNotesContainer;