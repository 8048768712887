import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ContractorDashboard from '../../views/ContractorDashboard';
import AddProjectContainer from '../../components/Project/AddProjectContainer';
import BidContainer from '../../views/BidContainer';
import ViewProjectContainer from '../../components/Project/ViewProjectContainer';
import { PeopleService } from '../../services/PeopleService';

class ContractorLayout extends React.Component {
  componentDidMount () {
    if (!['approved', 'temp_approved'].includes(PeopleService.instance().me('status'))){
      window.app.history.push('/review-account')
    }
  }

  render () {
    return (
      <div className={`ContractorLayout`}>
        <Switch>
          <Route path={`/subcontractor/dashboard`} component={ContractorDashboard}/>
          <Route path={`/subcontractor/projects/add`} component={AddProjectContainer}/>
          <Route path={`/subcontractor/bid/:projectId`} component={BidContainer}/>
          <Route path={`/subcontractor/projects/:projectId`} render={(props) => (
            <ViewProjectContainer {...props} />
          )}/>
        </Switch>
      </div>
    );
  }
}

export default ContractorLayout;
