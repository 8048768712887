import React from "react";
import ReactDOM from "react-dom";
import environment from './environments/environment';

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "assets/css/puente-fonts.css";

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import "react-notifications-component/dist/theme.css";
import { StorageService } from './services/StorageService';
import * as _ from 'lodash'
import { LoadingService } from './services/LoadingService';
import App from './App';

new LoadingService() // so we can subscribe to the store

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  cssRoot: {
    // color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: purple[500],
    // '&:hover': {
    //   backgroundColor: purple[700],
    // },
  },
});

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   // main: '#e2d410'
    // },
    secondary: {
      main: '#000',
      contrastText: '#e2d410'
    },
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    useNextVariants: true,
    button: {
      fontWeight: 600,
      letterSpacing: '0px',
    },
  },
  overrides: {
    MuiButton: {
      sizeSmall: {
        fontSize: 10,
        minWidth: 'auto',
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: 15
      }
    }
  }
});


let StylizedComponent = withStyles(styles)(() => {
  let storageService = new StorageService()
  if (_.size(storageService.get('user.data'))) {
    environment.oAuthToken = storageService.get('user.tokenData.id')
  }

  return (
    <MuiThemeProvider theme={theme}>
      <App/>
    </MuiThemeProvider>
  )
});


ReactDOM.render(<StylizedComponent/>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}