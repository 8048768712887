import React, { Component } from 'react';
import DataTable  from '../../components/DataTable/DataTable';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import styled from 'styled-components';

import * as _ from 'lodash'
import { AdminService } from '../../services/AdminService';
import { StorageService } from '../../services/StorageService';
import * as qs from 'querystring';
import { PeopleService } from '../../services/PeopleService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DangerButton, SuccessButton } from '../../components/Buttons';
import Pager from '../../components/Pager';

const StyledCardTitle = styled(CardTitle)`
  font-size: 24px;
  margin-bottom: 0px !important;
`

class AdminUsers extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data   : [],
      page   : 1,
      limit  : 10,
      filters: {},
      total: 0
    }

    this.adminService = new AdminService()
    this.peopleService = new PeopleService()
    this.storageService = new StorageService()
  }

  componentDidMount () {
    this.processFiltersFromUrl()
    setTimeout(() => this.getData()) // wait for the cycle to update the state
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!_.isEqual(prevState.page, this.state.page)) {
      this.getData()
    }
  }

  async processFiltersFromUrl () {
    let search = qs.decode(this.props.location.search.replace('?', ''))
    this.setState({
      filters: {
        ...this.state.filters,
        ...search,
      },
    })
  }

  async getData () {
    let resp = await this.adminService.getPeople({
      filter: {
        page    : this.state.page,
        limit   : this.state.limit,
        userType: this.props.match.params.userType,
        ...this.state.filters,
      },
    })

    this.setState({data: resp.parsed.data.data, total: resp.parsed.data.meta.total})
  }

  handleSearch () {
    this.getData()
  }

  updateFilters(col, searchValue){
    this.setState({
      filters: _.merge({}, this.state.filters, {[col.field]: searchValue}),
    })
  }

  async changeStatus (user, status) {
    await this.peopleService.patch({id: user.userId, status})
    await this.getData()
  }

  render () {
    return (
      <div className="AdminUsers content container-fluid">
        <Card>
          <CardHeader>
            <StyledCardTitle>Users</StyledCardTitle>
            <small>{this.state.total} records found</small>
          </CardHeader>
          <CardBody>
            <Pager
              className={`pull-right`}
              count={this.state.total}
              perPage={this.state.limit}
              page={this.state.page}
              pagesToShow={3}
              onChange={(page) => {
                this.setState({page})
              }}
            />

            <DataTable
              search={this.handleSearch.bind(this)}
              cols={[
                {
                  field: 'firstName', process: (fieldName, user) => (
                    <Link to={`/admin/user/${user.userId}`}>{user.firstName}</Link>
                  ),
                },
                {field: 'lastName'},
                {field: 'email'},
                {field: 'phone'},
                (this.props.match.params.userType !== 'owner' ? {
                  field  : 'companyName',
                } : null),
                {field: 'mailingAddress'},
                (this.props.match.params.userType !== 'owner' ? {
                  field  : 'glExp',
                  process: (fieldName, user) => user.glExp ? moment(user.glExp).format('MM/DD/YYYY') : 'N/A',
                } : null),
                {field: 'status'},
                (this.props.match.params.userType !== 'owner' ? {field: 'bidOnContractType',
                  process: (fieldName, user) => {
                    return user.bidOnContractType
                  },
                } : null),
                (this.props.match.params.userType === 'owner' ? {
                  field  : 'totalProjects',
                  renderSearch: () => {}
                } : null),
                {
                  field: 'createdAt',
                  label: 'Sign up on',
                  searchType: 'date',
                  process: (fieldName, user) => (
                    moment(user.createdAt).format('MM/DD/YY HH:ma')
                  ),
                },
                {
                  field         : '',
                  searchDisabled: true,
                  process       : (fieldName, user) => (
                    <>
                      {!['approved'].includes(user.status)
                        ? <SuccessButton color={'primary'} variant={'contained'}
                                         onClick={() => this.changeStatus(user, 'approved')}>Approve</SuccessButton>
                        : <DangerButton color={'secondary'} variant={'contained'}
                                        onClick={() => this.changeStatus(user, 'denied')}>Deny</DangerButton>}
                    </>
                  ),
                },
              ].filter((row) => !!row)}
              data={this.state.data}
              filters={this.state.filters}
              updateFilters={this.updateFilters.bind(this)}
            />

            <Pager
              className={`pull-right`}
              count={this.state.total}
              perPage={this.state.limit}
              page={this.state.page}
              pagesToShow={3}
              onChange={(page) => {
                this.setState({page})
              }}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

AdminUsers.propTypes = {};

export default AdminUsers;