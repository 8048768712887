import React from 'react'
import PropTypes from 'prop-types'

// reactstrap components
import {
  Table,
} from 'reactstrap';
import * as _ from 'lodash'

class ListProjectsTable extends React.Component {
  render () {
    return (
      <Table responsive>
        <thead className="text-primary">
        <tr>
          {this.props.cols.map((field, k) => (
            <th key={k}>{field.label}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {this.props.data.length
          ? this.props.data.map((row, k) => {
            return (
              <tr key={k}>
                {this.props.cols.map((field, k) => (
                  <td key={k}>
                    {_.isFunction(field.process) ? field.process(_.get(row, field.name, null), row) : _.get(row, field.field, null)}
                  </td>
                ))}
              </tr>
            )
          })
          : <tr>
            <td className={`text-center`} colSpan={this.props.cols.length}>{this.props.emptyDataMessage}</td>
          </tr>
        }
        </tbody>
      </Table>
    );
  }
}

ListProjectsTable.defaultProps = {
  data          : [],
  emptyDataMessage: 'No available data to show here'
}

ListProjectsTable.propTypes = {
  data          : PropTypes.array.isRequired,
  cols          : PropTypes.array.isRequired,
  emptyDataMessage          : PropTypes.string,
}

export default ListProjectsTable