import { environmentProduction } from './environment.production';
import { environmentLocal } from './environment.local';
import { environmentStaging } from './environment.staging';

let environment;

console.log('ENVIRONMENT:', process.env.NODE_ENV)

if (process.env.NODE_ENV === 'production') {
  environment = Object.assign({}, environmentProduction, {});
}
else if (process.env.NODE_ENV === 'staging') {
  environment = Object.assign({}, environmentStaging, {});
}
else {
  environment = Object.assign({}, environmentLocal, {});
}

export default environment;