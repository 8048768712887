import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Row } from 'reactstrap';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form'
import ValidationService from '../../services/ValidationService';
import * as _ from 'lodash'
import TextMaskCustom from '../TextMaskCustom';
import FieldsetRadioGroup from '../RadioGroup/FieldsetRadioGroup';
import Link from '../Link';
import { NotificationService } from '../../services/NotificationService';
import LocationSearchInput from '../LocationSearchInput';
import { InfoButton } from '../Buttons';
import { PeopleService } from '../../services/PeopleService';

class UserProfile extends Component {

  constructor (props) {
    super(props)
    this.state = {}

    this.notificationService = new NotificationService()
    this.peopleService = new PeopleService()
  }

  triggerFormSubmit () {
    if (!this.props.onSubmit)
      return
    document.getElementById('project-form').dispatchEvent(new Event('submit', {cancelable: true}))
  }

  handleChangePassword (values, e) {
    if (!this.props.onSubmit || !this.props.onChangePassword)
      return

    e.preventDefault()
    this.props.onChangePassword(values)
  }

  handleOnChange(data){
    this.props.onChange && this.props.onChange(data)
  }

  handleOnSubmit(data) {
    this.props.onSubmit && this.props.onSubmit(data)
  }

  getUserTypes(){
    let allTypes = [
      {value: 'owner', label: 'Home Owner'},
      {value: 'gc', label: 'General Contractor'},
      {value: 'subcontractor', label: 'Subcontractor'},
      {value: 'architect', label: 'Architect'},
      {value: 'retailer', label: 'Retailer'},
      {value: 'manufacturer', label: 'Manufacturer'},
    ]

    if (['approved', 'temp_approved'].includes(this.peopleService.me('status')) && this.peopleService.me('userType') !== 'admin' && this.props.onChange) {
      return allTypes.filter((typeData) => {
        if (['gc', 'subcontractor'].includes(typeData.value) && ['gc'].includes(this.peopleService.me('userType'))) {
          return true
        }
        return this.peopleService.me('userType') === typeData.value
      })
    } else {
      return allTypes
    }
  }

  render () {
    const userType = this.peopleService.me('userType')

    return (
      <>
        <div className="UserProfile content container-fluid">
          <Row>
            <Col lg={7} md={7} sm={12} className={`m-auto`}>
              <Card>
                <CardHeader className={`border-bottom mb-3`}>
                  <CardTitle tag="h2">{this.props.formTitle}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    initialValues={this.props.data}
                    onSubmit={this.handleOnSubmit.bind(this)}
                    onChange={this.handleOnChange.bind(this)}
                    render={({handleSubmit, form, submitting, pristine, values, invalid, active, reset}) => (
                      <form onSubmit={handleSubmit} id={`project-form`}>
                        <Row>
                          <Col className="" sm={12} md={6}>
                            <fieldset className="custom-fieldset mb-4 bg-lighter field-email">
                              <legend>Email</legend>
                              <div>
                                <span className={'pull-left'}>{this.props.data.email}</span>
                                {this.props.onSubmit && this.props.onChangePassword ? <Link
                                  href={'/'}
                                  className={'small pull-right change-password-link'}
                                  onClick={this.handleChangePassword.bind(this, values)}
                                >
                                  Change Password
                                </Link> : null}
                              </div>
                            </fieldset>
                          </Col>

                          <Col className="" sm={6}>
                            <FormControl fullWidth={true}>
                              <Field
                                name={`phone`}
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <>
                                    <InputLabel htmlFor="formatted-text-mask-input" shrink
                                                variant="outlined">Phone</InputLabel>
                                    <OutlinedInput
                                      {...input}
                                      label="Phone"
                                      labelWidth={60}
                                      id="formatted-text-mask-input"
                                      inputComponent={TextMaskCustom}
                                      notched={true}
                                      onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                    />

                                    {meta.error && meta.touched
                                      ? <FormHelperText error={true}
                                                        className={`m-0 pb-2`}>{meta.error}</FormHelperText>
                                      : null}
                                  </>
                                )}
                              </Field>
                            </FormControl>
                          </Col>

                        </Row>
                        <Row>
                          <Col className="" sm={12} md={6}>
                            <FormGroup>
                              <Field
                                name={`firstName`}
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <TextField
                                    {...input}
                                    label={_.startCase(input.name)}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                    error={meta.error && meta.touched}
                                    helperText={meta.error && meta.touched ? meta.error : null}
                                  />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="" sm={12} md={6}>
                            <FormGroup>
                              <Field
                                name={`lastName`}
                              >
                                {({input, meta}) => (
                                  <TextField
                                    {...input}
                                    label={_.startCase(input.name)}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                    error={meta.error && meta.touched}
                                    helperText={meta.error && meta.touched ? meta.error : null}
                                  />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className={'pb-2'}>
                          <Col className="" sm={12} md={8}>
                            <FormGroup>
                              <Field
                                name={`mailingAddress`}
                                validate={ValidationService.instance().required}
                              >
                                {({input, meta}) => (
                                  <LocationSearchInput
                                    {...input}
                                    label={userType === 'owner' ? 'Mailing Address' : 'Office Address'}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                    error={meta.error && meta.touched}
                                    helperText={meta.error && meta.touched ? meta.error : null}
                                    onGeolocationEnd={({latLng, address}) => this.handleOnChange({
                                      location      : latLng,
                                      mailingAddress: address,
                                    })}
                                  />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col className="" sm={12} md={4}>
                            <FormGroup>
                              <Field
                                name={`mailingSuite`}
                              >
                                {({input, meta}) => (
                                  <TextField
                                    {...input}
                                    label={_.startCase(input.name)}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => (this.handleOnChange({[input.name]: e.target.value}))}
                                    error={meta.error && meta.touched}
                                    helperText={meta.error && meta.touched ? meta.error : null}
                                  />
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                        </Row>

                        {this.props.data.userType !== 'admin'
                        ? <Row className={'pb-2'}>
                          <Col className="" sm={12}>
                            <FieldsetRadioGroup
                              title={'I am:'}
                              field={'userType'}
                              options={this.getUserTypes()}
                              value={this.props.data.userType || ''}
                              onChange={this.handleOnChange.bind(this)}
                            />
                          </Col>
                        </Row>
                        : null}

                        {this.props.onSubmit ? <button type="submit" className="d-none"></button> : null}
                      </form>
                    )}
                  />
                </CardBody>
                {this.props.onSubmit
                  ? <CardFooter className={`border-top`}>
                  <div className="pull-left">
                    {this.props.nextPageLinks
                    && this.props.nextPageLinks[this.props.data.userType]
                    && this.props.nextPageLinks[this.props.data.userType].map((link, k) => (
                      <Button
                        key={k}
                        variant={'outlined'}
                        onClick={() => window.app.history.navigateByUrl(link.next)}
                        className={`mr-3`}
                      >{link.label}</Button>
                    ))}
                  </div>
                  <Button variant={'contained'} color={'secondary'} onClick={() => this.triggerFormSubmit()}
                          className={`pull-right`}>
                    {this.props.submitLabel}
                  </Button>
                </CardFooter>
                : null}
              </Card>
            </Col>
          </Row>
          {this.props.onSubmit && this.props.data.status === 'creating_account'
          && this.props.data.userType && !['owner', 'admin'].includes(this.peopleService.me().userType)
          && this.peopleService.me().id ?
            <div className="text-center">
              <InfoButton onClick={() => window.app.history.push('/review-account')}>
                Submit Account for review
              </InfoButton>
            </div>
          : null}
        </div>

      </>
    );
  }
}

UserProfile.defaultProps = {
  formTitle  : 'User Profile',
  submitLabel: 'Submit',
}

UserProfile.propTypes = {
  data         : PropTypes.object.isRequired,
  onSubmit     : PropTypes.func,
  onChange     : PropTypes.func,
  onChangePassword: PropTypes.func,
  nextPageLinks: PropTypes.object,
};

export default UserProfile;