import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import * as _ from 'lodash'

class NumberFormatCustom extends React.Component {

  constructor (props) {
    super(props)
    this.debouncedOnChange = _.debounce(this.props.onChange, 100, {leading: false, trailing: true})
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.value === this.props.value) {
      return false
    }

    return true
  }

  render(){
    const { inputRef, onChange, ...other } = this.props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(e) =>  {
          this.debouncedOnChange({target: e})
        }}
        thousandSeparator
        prefix="$"
        readOnly={this.props.readOnly}
      />
    );
  }
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default NumberFormatCustom