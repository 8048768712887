import React, { Component } from 'react';
import { NotificationService } from '../../services/NotificationService';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, NavLink, UncontrolledDropdown } from 'reactstrap';
import moment from 'moment'
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  padding: 5px 0 !important;
  
  &:not(:last-child) {
    border-bottom: 1px solid #ddd;  
  }
`

const StyledDropdownItem = styled(DropdownItem)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  
  .close {
    position: absolute;
    top: 5px;
    right: 15px;
  }
`

const StyledDropdownMenu = styled(DropdownMenu)`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 170px;
  
  .ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

class NotificationsListContainer extends Component {

  constructor (props) {
    super(props);
    this.notificationsService = new NotificationService()
    this.state = {
      data: [],
    }
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    let resp = await this.notificationsService.getNotifications()
    if (resp.status === 200) {
      this.setState({data: resp.parsed.data})
    } else {
      this.notificationsService.error('An error occurred')
    }
  }

  async handleDismiss (notification) {
    await this.notificationsService.dismiss(notification)
    await this.getData()
  }

  render () {
    return (
      <UncontrolledDropdown nav>
        <DropdownToggle
          caret
          color="default"
          data-toggle="dropdown"
          nav
          className="messages-toggle m-0 border"
        >
          <Button color="link" className="m-0">
            {this.state.data.length ? <div className="notification"/> : null}
            <span className="fa fa-bell"></span>
          </Button>
        </DropdownToggle>
        <StyledDropdownMenu className="dropdown-navbar" right tag="ul">
          {this.state.data.length
            ? this.state.data.map((notification, index) => (
              <StyledNavLink key={index} tag="li">
                <StyledDropdownItem className="nav-item">
                  <span className={`font-weight-600 ellipsis`}>{notification.subject}</span>
                  <span className={`close`} title={`Click to dismiss`}
                        onClick={this.handleDismiss.bind(this, notification)}>&times;</span>
                  <span className={`ellipsis`}>{notification.body}</span>
                  <span className="small">{moment(notification.created).fromNow()}</span>
                </StyledDropdownItem>
              </StyledNavLink>

            ))
            : <StyledNavLink tag="li">
              <StyledDropdownItem
                className="nav-item"
              >
                <div className={`font-weight-600`}>All caught up!</div>
                <div>You don't have any notifications</div>
              </StyledDropdownItem>
            </StyledNavLink>}
        </StyledDropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

NotificationsListContainer.propTypes = {};

export default NotificationsListContainer;