import { createStore } from 'redux'

export const ROOT_ENTRY = {parentPath: null, path: '/', label: 'Home'}

const defaultState = {
  entries: [ROOT_ENTRY],
  entry: ROOT_ENTRY,
  creatorName: 'anonymous'
}

let appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'set':
      // we need to overwrite the state (and not merge) to avoid weird bugs.
      // If we need to merge a value into the state, it has to be done by the component
      state = action.state
      break;
    case 'clear':
      state = defaultState
      break;
    default:
      return state
  }

  return state
}

let FileSystemStore = createStore(appReducer)

export default FileSystemStore
