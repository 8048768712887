// Removed "react-notifications" because it was overwriting the styles for public/templates/modal/_alert.html
import { ApiService } from './ApiService';
import { StorageService } from './StorageService';

// do not extend from ModelService because it will create a circular reference
export class NotificationService {

  constructor () {
    this.storageService = new StorageService()
  }

  getNotifications(){
    return ApiService.instance().go(`/People/${this.storageService.get('user.tokenData.userId')}/notifications`)
  }

  dismiss(notification) {
    return ApiService.instance().go(`/People/${this.storageService.get('user.tokenData.userId')}/notifications/${notification.id}/dismiss`, {
      method: 'post'
    })
  }

  info(message) {
    this.showMessage(message, 'info');
  }

  warning(message) {
    this.showMessage(message, 'warning');
  }

  success(message) {
    this.showMessage(message, 'success');
  }

  // An alias of 'error'
  danger(message) {
    this.error(message);
  }

  error(message) {
    this.showMessage(message, 'danger');
  }

  showMessage(message, type = 'danger') {
    let title = '';
    switch (type) {
      case 'info':
        title = 'Info'
        break;
      default:
      case 'success':
        title = 'Success'
        break;
      case 'warning':
        title = 'Warning'
        break;
      case 'danger':
        title = 'Error'
        break;
    }

    window.notificationDOMRef.addNotification({
      title,
      message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true }
    });
  }
}