import 'whatwg-fetch';
import * as _ from 'lodash';

import { NotificationService } from './NotificationService';
import environment from '../environments/environment';
import { StorageService } from './StorageService';
import appStore from '../AppStore';
const uuidv1 = require('uuid/v1');

export class ApiService {

  constructor () {
    this.notificationService = new NotificationService();
    this.storageService = new StorageService();
  }

  static instance() {
    return new ApiService()
  }

  async go (url, config = {}) {
    let requestUUID = uuidv1()
    url = _.trimEnd(environment.apiUrl, '/') + '/' + _.trimStart(url, '/');

    // Configure the options
    config = this.prepare(config);

    let state = {
      ...appStore.getState(),
      loadingQueue: [
        ...appStore.getState().loadingQueue,
        requestUUID
      ],
    }

    if (!config.silentLoading) {
      state.isLoading = true
    }

    appStore.dispatch({
      type: 'set',
      state
    })

    let resp = await fetch(url, _.omit(config, ['disableNotifications']))

    setTimeout(function(requestUUID){
      let loadingQueue = appStore.getState().loadingQueue.filter((r) => r !== requestUUID)
      let state = {
        ...appStore.getState(),
        loadingQueue
      }

      if (!config.silentLoading) {
        state.isLoading = !!loadingQueue.length
      }

      appStore.dispatch({
        type: 'set',
        state
      })
    }.bind(this, requestUUID), 100) // defer to allow others to finish subscribing to it

    if (resp.status >= 400 ) {
      if (config.disableNotifications !== true) {
        let genericError = 'An error occurred. Our tech team has been alerted and will get to work on this issue shortly.';
        if (resp.status === 500) {
          this.notificationService.error(genericError);
        } else if (resp.status === 401) {
          let jsonResp = await resp.json();
          this.notificationService.error(jsonResp.error.message);
          // window.app.history.push('/logout');
        } else if (resp.status === 422) { // unprocessable entity
          // let the component do whatever they need
        } else if (resp.status === 404) {
          // let the component do whatever they need when there's a 404
        } else {
          this.notificationService.error(genericError);
        }
      }

      throw resp
    }
    else if ([200, 201, 202, 203, 206].includes(resp.status)) {
      let jsonResp = await resp.json();

      resp.data = jsonResp // alias
      resp.parsed = {
        status: resp.status,
        data  : jsonResp,
      };
    }
    return resp
  }

  prepare (config) {
    let csrfToken = config.csrfToken
    if (!csrfToken && document.getElementsByName('csrf-token').length) {
      csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
    }

    let configDefaults = {
      method : 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      // credentials: 'same-origin',
      disableNotifications: false,
      silentLoading: false,
    };

    if (csrfToken) {
      configDefaults.headers['X-CSRF-Token'] = csrfToken
    }

    if (environment.oAuthToken) {
      configDefaults.headers.authorization = environment.oAuthToken
    }

    config = _.merge({}, configDefaults, config);

    if (config.headers['Content-Type'] === 'application/json' && _.isObject(config.body)) {
      config.body = JSON.stringify(config.body);
    }

    return config;
  }

  isJson (str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

}
