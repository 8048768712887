import React, { Component } from 'react';
import PropTypes from 'prop-types'
import ProjectForm from './ProjectForm';
import { ProjectsService } from '../../services/ProjectsService';
import { NotificationService } from '../../services/NotificationService';
import { Button } from '@material-ui/core';
import { StorageService } from '../../services/StorageService';
import { ContainersService } from '../../services/ContainerService';

class ViewProjectContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      project: {}
    }

    this.projectsService = new ProjectsService()
    this.notificationService = new NotificationService()
    this.storageService = new StorageService()
    this.containersService = new ContainersService()
  }

  componentDidMount () {
    this.getData()
  }

  async getData(){
    let resp = await this.projectsService.details(this.props.match.params.projectId)
    if (!resp.parsed.data.entries)
      resp.parsed.data.entries = []

    let containerName = `${resp.parsed.data.ownerId}-${this.props.match.params.projectId}`
    await this.containersService.getFilesFrom(containerName)
    this.setState({project: {...resp.parsed.data}})
  }

  render () {
    let footerLinks = [
      {
        url: `/${this.storageService.get('user.data.userType')}/bid/:projectId`,
        label: 'Submit Estimate'
      }
    ]

    // do not show the 'submit estimate' link if this is a
    let userBids = this.state.project && this.state.project.leadBids
      ? this.state.project.leadBids.filter((bid) => bid.id === this.storageService.get('user.data.id'))
      : []
    if (userBids && userBids.length && userBids[0].status === 'accepted')
      footerLinks = []

    return (
      <div className={`ViewProjectContainer`}>
        <ProjectForm
          data={this.state.project}
          formTitle={'Project Details'}
          submitLabel={`Submit`}
        />

        <div className="text-center">
          {!this.state.project.isLimitedInfoDisplayed
          ? footerLinks.map((link, k) => (
            <Button
              key={k}
              variant={'text'}
              onClick={() => window.app.history.push(link.url.replace(/:projectId/, this.state.project.id))}
            >
              {link.label}
            </Button>
          ))
          : null}
        </div>
      </div>
    );
  }
}

ViewProjectContainer.defaultProps = {
  footerLinks: []
}

ViewProjectContainer.propTypes = {
  footerLinks: PropTypes.array
};

export default ViewProjectContainer;